export default Object.freeze({
  WELCOME: {
    name: "Welcome",
    path: "/welcome",
  },
  PROJECT: {
    name: "Project database",
    path: "/project/*",
    subroutes: {
      RESEARCH: {
        name: "Research",
        path: "research",
      },
      EDIT: {
        name: "Edit",
        path: "edit/:id",
      },
      ADD: {
        name: "Add",
        path: "add",
      },
      INFOS: {
        name: "Infos",
        path: "infos/:id",
      },
    },
  },
  PREFORM: {
    name: "Preform database",
    path: "/preform/*",
    subroutes: {
      RESEARCH: {
        name: "Research",
        path: "research",
      },
      EDIT: {
        name: "Edit",
        path: "edit/:id",
      },
      ADD: {
        name: "Add",
        path: "add",
      },
      INFOS: {
        name: "Infos",
        path: "infos/:id",
      },
    },
  },
  BOTTLE: {
    name: "Bottle database",
    path: "/bottle/*",
    subroutes: {
      RESEARCH: {
        name: "Research",
        path: "research",
      },
      EDIT: {
        name: "Edit",
        path: "edit/:id",
      },
      ADD: {
        name: "Add",
        path: "add",
      },
      INFOS: {
        name: "Infos",
        path: "infos/*",
        subroutes: {
          MainInfos: {
            name: "MainInfos",
            path: ":id",
          },
          BESTPREFORM: {
            name: "Best",
            path: ":id/best",
          },
        },
      },
    },
  },
  PROCESS: {
    name: "Process database",
    path: "/process/*",
    subroutes: {
      RESEARCH: {
        name: "Research",
        path: "research",
      },
      EDIT: {
        name: "Edit",
        path: "edit/:id",
      },
      ADD: {
        name: "Add",
        path: "add",
      },
      INFOS: {
        name: "Infos",
        path: "infos/:id",
      },
    },
  },
});
