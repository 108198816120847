import { DataLayout } from "shared/components";
import { useNavigate, useParams } from "react-router-dom";
import { InfoTable } from "shared/components";
import { useEffect, useState } from "react";
import { dispatchFetch } from "shared/components/fetchers";
import { useNavigateSubRoute } from "shared/hooks";
import {
  getErrorMessage,
  infoDataTreatment,
  objectType,
} from "shared/Utils/utils";
import { useSnackbar } from "notistack";

const Informations = () => {
  const [infos, setInfos] = useState<objectType>();
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const param = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  //*Get init
  useEffect(() => {
    dispatchFetch("GET")(`/preforms/${param.id}`).then((data: any) => {
      setIsEditable(data.isEditable);
      delete data.isEditable;
      setInfos(
        infoDataTreatment(
          {
            ...data,
            neck: data.neck.name,
            bottomTypeShape: data.bottomTypeShape?.name,
            supplier: data.supplier.name,
            resin: data.resin.name,
            owner: data.owner?.username,
          },
          "preform"
        )
      );
    });
  }, [param.id]);
  //*Handler
  const navigateToSubRoute = useNavigateSubRoute();
  const handleEdit = () => {
    navigateToSubRoute(`edit/${param.id}`, 1);
  };

  const handleCopy = () => {
    navigateToSubRoute(`add#${param.id}`, 1);
  };

  const handleDelete = async () => {
    await dispatchFetch("DELETE")(`/preforms/${param.id}`)
      .then((data: any) => {
        if (data.status >= 200 && data.status < 300) {
          enqueueSnackbar(`The preform deleted with success`, {
            variant: "success",
          });
          navigate("/preform/research");
        }
      })
      .catch((error: any) => {
        let errorMessage = getErrorMessage(error);
        enqueueSnackbar(errorMessage, {
          variant: "error",
        });
      });
  };
  //*Render
  return (
    <DataLayout
      title={`Preform : ${infos ? infos.Name : ""}`}
      variant="delete"
      deleteMsg={
        <div>
          <span>{`⚠️ All processes associated to this preform will be deleted`}</span>
          <br />
          <span>Are you sure to continue ?</span>
        </div>
      }
      hasRights={isEditable}
      hideCancelBtn
      onEdit={handleEdit}
      onCopy={handleCopy}
      onDelete={handleDelete}
      disableValidation={!isEditable}
    >
      <InfoTable infos={infos} />
    </DataLayout>
  );
};

export default Informations;
