import { Box } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  Filter,
  SearchLayout,
  AddObjectButton,
  ResultTable,
} from "shared/components";
import ProjectForm from "screens/App/views/Project/components/ProjectForm";
import { projectFiltersConfig } from "shared/constants/filtersConfig";
import { FormikHelpers, useFormik } from "formik";
import {
  generateURL,
  getIsReset,
  isEmptyObject,
  SearchDataProps,
} from "shared/Utils/utils";
import { dispatchFetch } from "shared/components/fetchers";
import { CashContext } from "RootApp";

const Research = () => {
  const [results, setResult] = useState<SearchDataProps>();
  const [loading, setLoading] = useState<boolean>(false);
  const { updateCash, cashData } = useContext(CashContext);
  const [filterHistory] = useState(cashData.filterHistory.projects);

  //* GET Init

  useEffect(() => {
    !isEmptyObject(filterHistory) && formi.handleSubmit();
    dispatchFetch("GET")(`/projects/`)
      .then((data: SearchDataProps) => {
        setResult(data);
      })
      .catch((error: any) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //*Handler
  const handleChangePage = (pageURL: string) => {
    dispatchFetch("GET")(pageURL)
      .then((data: SearchDataProps) => {
        setResult(data);
      })
      .catch((error: any) => {});
  };

  const handleApplyFilter = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    const filterCash = {
      filterHistory: { ...cashData.filterHistory, projects: values },
    };
    updateCash(filterCash);
    let url = generateURL(values, "projects");
    setLoading(true);
    try {
      await dispatchFetch("GET")(url).then((data: any) => {
        setResult(data);
      });
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    formi.setValues({});
    formi.handleSubmit();
  };
  //*Formik
  const formi = useFormik({
    enableReinitialize: true,
    initialValues: filterHistory,
    onSubmit: handleApplyFilter,
  });

  //*Render
  return (
    <SearchLayout
      onEnter={formi.handleSubmit}
      filterArea={
        <Filter
          onApplyFilte={() => formi.handleSubmit()}
          onReset={handleReset}
          isLoading={loading}
          isFilterEmpty={getIsReset(formi.values)}
        >
          <Box minWidth="230px">
            <ProjectForm formConfig={projectFiltersConfig} formik={formi} />
          </Box>
        </Filter>
      }
      topResultArea={
        <AddObjectButton variant="contained" objectName="Project" />
      }
      resultArea={
        <ResultTable
          title="Projects"
          data={results}
          onChangePage={handleChangePage}
        />
      }
    />
  );
};

export default Research;
