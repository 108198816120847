import { Box, Button, ButtonProps } from "@mui/material";
import { Plus } from "mdi-material-ui";
import { useNavigateSubRoute } from "shared/hooks";

// &---------------------------------<< Component >>----*
interface AddButtonProps extends ButtonProps {
  objectName: string;
  path?: string;
  pathDeep?: number;
}
/**
 * JSX.Element element represents the add button for objects
 * @prop {string} objectName - The name of the object to add
 * @example <AddButton objectName="objectName"/>
 */
const AddButton = (props: AddButtonProps) => {
  const { objectName, path = "add", pathDeep, ...restProps } = props;
  //*Handlers
  const navigateSubRoute = useNavigateSubRoute();
  const handleAddButton = () => {
    //ToDo add the action to the backend
    navigateSubRoute(path, pathDeep);
  };
  //*Render
  return (
    <Box display="flex" justifyContent="flex-end">
      <Button
        disableElevation
        // variant="contained"
        size="large"
        endIcon={<Plus />}
        onClick={handleAddButton}
        {...restProps}
      >
        Add {objectName}
      </Button>
    </Box>
  );
};

export default AddButton;
