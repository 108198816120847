import {
  Box,
  Typography,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  Grid,
  Checkbox,
  styled,
  Switch,
  Tooltip,
  IconButton,
} from "@mui/material";

import {
  EyeOutline,
  EyeOffOutline,
  ShieldAlertOutline,
  VectorIntersection,
  AxisArrow,
  TargetVariant,
} from "mdi-material-ui";
import React, {
  forwardRef,
  MouseEvent,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import PreformChart from "./PreformChart";

import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { targetFBP } from "shared/constants/validationSchemas";
import { dispatchFetch } from "shared/components/fetchers";
import { BorderProps, isArray } from "shared/Utils/utils";
import { contentType } from "shared/constants/constants";

//*CONST
interface defaultParams_Type {
  label: string;
  name: BestPreformParams;
  isChecked: boolean;
  min: number;
  max: number;
  average: number;
}
const defaultParams: defaultParams_Type[] = [
  {
    label: "Longi SR",
    name: "longiSR",
    isChecked: true,
    min: 0,
    max: 0,
    average: 0,
  },
  {
    label: "Radial SR",
    name: "radialSR",
    isChecked: true,
    min: 0,
    max: 0,
    average: 0,
  },
  {
    label: "Mass (g)",
    name: "preformMass",
    isChecked: false,
    min: 0,
    max: 0,
    average: 0,
  },
  {
    label: "Wall thickness (µm)",
    name: "wallThickness",
    isChecked: false,
    min: 0,
    max: 0,
    average: 0,
  },
];

// &---------------------------------<< Styled component >>----*
const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 16,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

// &---------------------------------<< Component >>----*
type BestPreformParams =
  | "longiSR"
  | "radialSR"
  | "preformMass"
  | "wallThickness";
type Axis = "x" | "y";

interface BestPreformChartProps {
  ref?: React.Ref<unknown>;
  updateAvailable?: (state: boolean) => void;
  updateRankingData?: (data: any) => void;
  onTargetBorderChange?: (newValue: BorderProps) => void;
  focusedDotRank: number;
  updateFocusedDotRank: (rank: number) => void;
}

/**
 * JSX.Element element represents the left area of find best preform page
 * @prop {React.Ref<unknown>} [ref] - element ref.
 * @prop {Callback} [updateAvailable] - function when update available.
 * @prop {Callback} [updateRankingData] - function to update ranking of data.
 */

const BestPreformChart = forwardRef((props: BestPreformChartProps, ref) => {
  const {
    updateAvailable,
    updateRankingData,
    onTargetBorderChange,
    ...restParam
  } = props;

  const bottleID = useLocation().pathname.split("/")[3];

  const { palette } = useTheme();

  const [activeParamsIndex, setActiveParamsIndex] = useState([1, 0]);

  const [currentRowX, setCurrentRowX] = useState(1);
  const [currentRowY, setCurrentRowY] = useState(0);

  const [labelXaxis, setLabelXaxis] = useState("");
  const [labelYaxis, setLabelYaxis] = useState("");

  const [chartData, setChartData] = useState<
    Array<{ x: number; y: number; name: string; label: string }> | undefined
  >(undefined);

  const [targetBorder, setTargetBorder] = useState({
    x: { min: 0, max: 0 },
    y: { min: 0, max: 0 },
  });

  const [initParams, setInitParams] = useState<
    defaultParams_Type[] | undefined
  >(undefined);

  const [rankingParamData, setRankingParamData] = useState<
    { [key: string]: any }[] | undefined
  >(undefined);

  const { enqueueSnackbar } = useSnackbar();

  //* Mounted Init values linked to optirange
  useEffect(() => {
    async function fetchOptirange() {
      const { optirange } = JSON.parse(
        localStorage.getItem("FBPStep02Data") as string
      );

      let initParamsWithNewTarget = [...defaultParams];

      //~change the data.optirange structur to object with param name
      //*~as key and the target array values as value of the key
      let optiranges: { [key: string]: any } = {};
      optirange.forEach(
        (param: {}) =>
          (optiranges[Object.keys(param)[0]] = Object.values(param)[0])
      );

      //~ Update min max of initParam
      initParamsWithNewTarget.map((param, i) => {
        if (isArray(optiranges[param.name])) {
          param.min = parseFloat(optiranges[param.name][0]);
          param.max = parseFloat(optiranges[param.name][1]);
          param.average = parseFloat(optiranges[param.name][2]);
        } else {
          console.warn("target not a array");
        }
        return true;
      });

      setInitParams(initParamsWithNewTarget);
    }
    //*init DefaultParms
    fetchOptirange();
    //*initAxesLabel
    setLabelXaxis(defaultParams[currentRowX].label);
    setLabelYaxis(defaultParams[currentRowY].label);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //* Mounted + watcher
  useEffect(() => {
    if (initParams) {
      setTargetBorder({
        x: {
          min: initParams[currentRowX].min,
          max: initParams[currentRowX].max,
        },
        y: {
          min: initParams[currentRowY].min,
          max: initParams[currentRowY].max,
        },
      });

      setRankingParamData([
        {
          name: "longiSR",
          value: [initParams[0].min, initParams[0].max, initParams[0].average],
        },
        {
          name: "radialSR",
          value: [initParams[1].min, initParams[1].max, initParams[1].average],
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initParams]);
  //* Mounted + watcher
  useEffect(() => {
    const { preforms } = JSON.parse(
      localStorage.getItem("FBPStep02Data") as string
    );
    const rankingData = {
      bottleID: parseInt(bottleID),
      target: {
        data: rankingParamData,
      },
      preforms,
    };

    const url = `/preforms/ranking/?pageSize=10000`;

    updateRankingData &&
      rankingParamData &&
      updateRankingData(JSON.stringify(rankingData));

    if (initParams) {
      dispatchFetch("POST")(url, JSON.stringify(rankingData), contentType.json)
        .then((data: any) => {
          if (data.results) {
            setChartData(
              formatDataForChart(
                data.results,
                initParams[currentRowX].name,
                initParams[currentRowY].name
              )
            );
          }
        })
        .catch(function (error: any) {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bottleID, rankingParamData]);
  //* Mounted + watcher
  useEffect(() => {
    updateAvailable && updateAvailable(currentRowX < 0 || currentRowY < 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRowX, currentRowY]);
  //*Watcher
  useEffect(() => {
    const newTargetBorder = initParams
      ? {
          x: { ...targetBorder.x, name: initParams[currentRowX].name },
          y: { ...targetBorder.y, name: initParams[currentRowY].name },
        }
      : targetBorder;
    onTargetBorderChange && onTargetBorderChange(newTargetBorder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetBorder]);

  //* Handlers
  const handleUpdateTarget = (values: any) => {
    setTargetBorder({
      x: {
        min: values.param[currentRowX].min,
        max: values.param[currentRowX].max,
      },
      y: {
        min: values.param[currentRowY].min,
        max: values.param[currentRowY].max,
      },
    });
  };
  const updateSelectedParams = () => {
    let result: any[] = [];
    initParams &&
      activeParamsIndex.map((i) =>
        result.push({
          name: initParams[i].name,
          value: [initParams[i].min, initParams[i].max, initParams[i].average],
        })
      );
    return result;
  };
  useImperativeHandle(
    ref,
    () =>
      initParams && {
        async handleUpdateClick() {
          handleUpdateTarget(formi.values);
          setRankingParamData(updateSelectedParams());
          setLabelXaxis(initParams[currentRowX].label);
          setLabelYaxis(initParams[currentRowY].label);
          updateAvailable && updateAvailable(true);
        },
      }
  );
  const handleParamToggle = (check: boolean, i: number) => {
    updateAvailable && updateAvailable(false);

    switch (check) {
      case true:
        setActiveParamsIndex([...activeParamsIndex, i].sort());
        break;

      case false:
        if (activeParamsIndex.length > 2) {
          const restActiveParamsIndex = activeParamsIndex.filter(
            (elt) => elt !== i
          );
          setActiveParamsIndex([...restActiveParamsIndex].sort());
          if (currentRowX === i) setCurrentRowX(-1);
          if (currentRowY === i) setCurrentRowY(-1);
        } else {
          handleParamsWarningIconClick();
        }
        break;
    }
  };
  const handleParamsWarningIconClick = () => {
    enqueueSnackbar(
      "Minimum two Parameters are required to compute the best preform",
      {
        variant: "warning",
      }
    );
  };
  const handleAxisWarningIconClick = () => {
    enqueueSnackbar("Two axis are required to display preform chart", {
      variant: "warning",
    });
  };
  const handleAxisClick = (axis: Axis, i: number) => {
    switch (axis) {
      case "x":
        setCurrentRowX(i);
        if (i === currentRowY) setCurrentRowY(-1);
        break;
      case "y":
        setCurrentRowY(i);
        if (i === currentRowX) setCurrentRowX(-1);
        break;
    }
  };

  //*Formik
  const formi = useFormik({
    enableReinitialize: true,
    initialValues: { param: initParams ? initParams : defaultParams },
    validationSchema: targetFBP,
    onSubmit: handleUpdateTarget,
  });
  //* Render
  return (
    <Box display="grid" gridTemplateRows="1fr auto">
      <Box
        sx={{ userSelect: "none" }}
        width="100%"
        alignSelf="center"
        justifySelf="center"
      >
        <PreformChart
          axisX={labelXaxis}
          axisY={labelYaxis}
          data={chartData}
          minX={targetBorder.x.min}
          maxX={targetBorder.x.max}
          minY={targetBorder.y.min}
          maxY={targetBorder.y.max}
          {...restParam}
        />
      </Box>
      <Box>
        <TableContainer>
          <Table stickyHeader aria-label="axis parameters">
            <TableHead>
              <TableRow sx={{ height: "67px" }}>
                <TableCell align="center">
                  <Typography variant="h6" color="initial">
                    Ranking
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Grid container gap={1} justifyContent="center">
                    <VectorIntersection />
                    <Typography variant="h6" color="initial">
                      Param
                    </Typography>
                  </Grid>
                </TableCell>
                <TableCell align="center">
                  <Grid container gap={2} justifyContent="center">
                    <Typography
                      variant="h6"
                      color={currentRowX < 0 ? palette.warning.main : "initial"}
                    >
                      X
                    </Typography>
                    {currentRowX < 0 || currentRowY < 0 ? (
                      <Tooltip
                        title="Two axis are required"
                        placement="top"
                        arrow
                      >
                        <IconButton
                          onClick={handleAxisWarningIconClick}
                          size="small"
                        >
                          <ShieldAlertOutline color="warning" />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <AxisArrow sx={{ width: "34px", height: "34px" }} />
                    )}

                    <Typography
                      variant="h6"
                      color={currentRowY < 0 ? palette.warning.main : "initial"}
                    >
                      Y
                    </Typography>
                  </Grid>
                </TableCell>
                <TableCell align="center">
                  <Grid container gap={1} justifyContent="center">
                    <TargetVariant />
                    <Typography variant="h6" color="initial">
                      Min/Max
                    </Typography>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {initParams &&
                initParams.map((param, i) => (
                  <ParamItem
                    paramsActive={activeParamsIndex.length}
                    key={param.name}
                    defaultCheck={param.isChecked}
                    row={i}
                    rowX={currentRowX}
                    rowY={currentRowY}
                    formik={formi}
                    onToggle={handleParamToggle}
                    onClickAxis={handleAxisClick}
                    name={param.label}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
});

export default BestPreformChart;

// &---------------------------------<< SubComponent >>----*

interface ParamItemProps {
  paramsActive?: number;
  rowX?: number;
  rowY?: number;
  name: string;
  row: number;
  defaultCheck?: boolean;
  formik?: any;
  onToggle?: (isChecked: boolean, row: number) => void;
  onClickAxis?: (axis: Axis, row: number) => void;
}

const ParamItem = (props: ParamItemProps) => {
  const {
    row,
    rowX = -1,
    rowY = -1,
    name,
    defaultCheck = false,
    paramsActive = 3,
    formik,
    onToggle,
    onClickAxis,
  } = props;

  const MIN_ACTIVE_PARAMS = 2;

  const [active, setActive] = useState(defaultCheck);
  const [selectedAxis, setSelectedAxis] = useState<Axis>();
  const [locked, setLocked] = useState(false);

  const { palette } = useTheme();
  //* Watcher
  useEffect(() => {
    if (rowX === row) {
      setSelectedAxis("x");
    } else if (selectedAxis === "x") setSelectedAxis(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowX]);
  useEffect(() => {
    if (rowY === row) {
      setSelectedAxis("y");
    } else if (selectedAxis === "y") setSelectedAxis(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowY]);

  useEffect(() => {
    setLocked(paramsActive <= MIN_ACTIVE_PARAMS);
  }, [paramsActive]);

  //* Handlers
  const handleSwitchClick = (e: MouseEvent) => {
    const checked = (e.target as HTMLInputElement).checked;

    onToggle && onToggle(checked, row);

    switch (checked) {
      case true:
        setActive(checked);
        break;
      case false:
        if (!locked) {
          setActive(checked);
          setSelectedAxis(undefined);
        }
        break;
    }
  };
  const handleCheckAxis = (e: MouseEvent) => {
    if (active) {
      setSelectedAxis((e.target as HTMLInputElement).name as Axis);
      onClickAxis &&
        onClickAxis((e.target as HTMLInputElement).name as Axis, row);
    }
  };
  //* Rendrer
  return (
    <TableRow
      sx={
        active
          ? { backgroundColor: "default" }
          : { backgroundColor: palette.grey[300] }
      }
    >
      <TableCell align="center" sx={{ borderBottom: "none", padding: 1 }}>
        <Android12Switch
          sx={
            locked && active
              ? {
                  "& .MuiSwitch-track": {
                    outline: `4px solid ${palette.primary.dark}`,
                  },
                }
              : {}
          }
          checked={active}
          onClick={handleSwitchClick}
        />
      </TableCell>
      <TableCell
        align="center"
        sx={{ borderBottom: "none", padding: 1, minWidth: "100px" }}
      >
        <Typography variant="body1" color="initial">
          {name}
        </Typography>
      </TableCell>
      <TableCell align="center" sx={{ borderBottom: "none", padding: 1 }}>
        <Box display="flex" gap={4} justifyContent="center">
          <Checkbox
            checked={active ? selectedAxis === "x" : false}
            onClick={handleCheckAxis}
            name="x"
            icon={<EyeOffOutline />}
            checkedIcon={<EyeOutline />}
          />
          <Checkbox
            checked={active ? selectedAxis === "y" : false}
            onClick={handleCheckAxis}
            name="y"
            icon={<EyeOffOutline />}
            checkedIcon={<EyeOutline />}
          />
        </Box>
      </TableCell>
      <TableCell align="center" sx={{ borderBottom: "none", padding: 1 }}>
        <Box display="flex" gap={2} justifyContent="center">
          <TextField
            disabled
            variant="standard"
            sx={{ maxWidth: "70px" }}
            inputProps={{ style: { textAlign: "center" } }}
            type="number"
            size="small"
            name={`param[${row}].min`}
            value={formik.values.param[row].min.toFixed(2)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <TextField
            disabled
            variant="standard"
            sx={{ maxWidth: "70px" }}
            inputProps={{ style: { textAlign: "center" } }}
            size="small"
            name={`param[${row}].max`}
            value={formik.values.param[row].max.toFixed(2)}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.errors.param && formik.errors.param[row].max)}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};

//&----------------------<< Utils >>-------------------------&
const formatDataForChart = (
  results: any,
  paramXname: string,
  paramYname: string
) => {
  let formatedData: { label: string; name: string; x: number; y: number }[] =
    [];
  results.map((result: any) => {
    formatedData.push({
      label: "",
      name: result.preformName,
      x: result[paramXname],
      y: result[paramYname],
    });

    return true;
  });
  return formatedData;
};
