import { Box, Button, Card, Typography, useTheme } from "@mui/material";
import { ArrowCollapseAll, ImageFilterCenterFocus } from "mdi-material-ui";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  ReferenceArea,
  Label,
  ZAxis,
  ResponsiveContainer,
} from "recharts";
import LoadingSpinner from "shared/components/LoadingSpinner";

import { Square, Triangle } from "mdi-material-ui";
import { useCallback, useEffect, useState } from "react";
import { isDecimal } from "shared/Utils/utils";

interface InitSateProps {
  data: any;
  left: number | string | Function;
  right: number | string | Function;
  top: number | string;
  bottom: number | string;
  refAreaLeft: number | string;
  refAreaRight: number | string;
  refAreaTop: number | string;
  refAreaBottom: number | string;
  animation?: boolean;
}
interface PreformChartProps {
  axisX: string;
  axisY: string;
  data: { x: number; y: number; name: string; label: string }[] | undefined;
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
  focusedDotRank: number;
  updateFocusedDotRank: (rank: number) => void;
}

interface DataMinMaxProps {
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
}

/**
 * JSX.Element element represents the chart of find best preform page
 * @proY {string} axisX - Y axis name.
 * @prYp {string} axisY - Y axis name.
 * @prop {{ x: number; y: number; label: string; name: string }[] | undefined} data - Chart data.
 * @prop {number} minX - X min of rectangle.
 * @prop {number} minY - Y min of rectangle.
 * @prop {number} maxX - X max of rectangle.
 * @prop {number} maxY - Y max of rectangle.
 * @example <PreformChart axisX={labelXaxis} axisY={labelYaxis} data={chartData} minX={targetBorder.x.min} maxX={targetBorder.x.max} minY={targetBorder.y.min} maxY={targetBorder.y.max}/>
 */

const PreformChart = (props: PreformChartProps) => {
  const {
    axisX,
    axisY,
    data,
    minX,
    minY,
    maxX,
    maxY,
    updateFocusedDotRank,
    focusedDotRank,
  } = props;

  const { palette } = useTheme();
  const [displayToolTip, setDisplayToolTip] = useState<boolean>(true);
  const [dataMinMax, setDataMinMax] = useState<DataMinMaxProps>();

  const [borderMinMax, setBorderMinMax] = useState<DataMinMaxProps>();

  const [initState, setInitState] = useState<InitSateProps>({
    data: data,
    left: 0,
    right: "auto",
    top: "auto",
    bottom: 0,
    refAreaLeft: "",
    refAreaRight: "",
    refAreaTop: "",
    refAreaBottom: "",
    animation: true,
  });
  const theme = useTheme();
  const [focuedDotIndex, setFocuedDotIndex] = useState<number | null>(
    focusedDotRank
  );

  //*Function
  const getDotColor = useCallback(
    (entry: { x: number; y: number; name: string }, index: number) => {
      const dotColors = { fill: "red", stroke: "white" };
      if (index === focuedDotIndex) {
        dotColors.stroke = theme.palette.primary.main;
      }
      if (
        entry.x >= minX &&
        entry.x <= maxX &&
        entry.y >= minY &&
        entry.y <= maxY
      ) {
        dotColors.fill = "limeGreen";
      }

      return dotColors;
    },
    [focuedDotIndex, maxX, maxY, minX, minY, theme.palette.primary.main]
  );

  const getBorders = (data: DataMinMaxProps) => {
    const deltaX = data?.maxX - data?.minX;
    const deltaY = data?.maxY - data?.minY;

    const paddingX = deltaX !== 0 ? deltaX * 0.25 : data?.minX * 0.25;
    const paddingY = deltaY !== 0 ? deltaY * 0.25 : data?.minY * 0.25;

    const borderMinX = Math.floor(data.minX - paddingX);
    const borderMaxX = Math.floor(data.maxX + paddingX) + 1;
    const borderMinY = Math.floor(data.minY - paddingY);
    const borderMaxY = Math.floor(data.maxY + paddingY) + 1;

    return {
      minX: borderMinX,
      maxX: borderMaxX,
      minY: borderMinY,
      maxY: borderMaxY,
    };
  };

  const createTicks = (axis: "x" | "y") => {
    let interval = 1;
    let min:number=0
    if (initState){
      const {left, right, bottom, top} = initState;
      switch (axis) {
        case "x":
          interval = (Number(right) - Number(left))/4;
          min=Number(left);
          break;
          case "y":
          interval = (Number(top) - Number(bottom))/4;
          min=Number(bottom);
          break;
        default:
          break;
      }
    }
    return new Array(5).fill(0).map((x,i)=>min + i*interval);
  };


  //*Watcher
  useEffect(() => {
    if (data) {
      let dataX = data.map((datum: any) => datum.x);
      let dataY = data.map((datum: any) => datum.y);

      let valueMinMax: DataMinMaxProps = {
        minX: Math.min(...dataX),
        maxX: Math.max(...dataX),
        minY: Math.min(...dataY),
        maxY: Math.max(...dataY),
      };
      setDataMinMax(valueMinMax);
      const newBorders = getBorders(valueMinMax);
      setBorderMinMax(newBorders);
      setInitState({
        ...initState,
        data: data,
        left: newBorders.minX,
        right: newBorders.maxX,
        top: newBorders.maxY,
        bottom: newBorders.minY,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setFocuedDotIndex(focusedDotRank);
  }, [focusedDotRank]);
  //* handeler
  const handleDotClick = (rank: number) => {
    setFocuedDotIndex(rank);
    updateFocusedDotRank(rank);
  };

  const handleZoom = () => {
    let { refAreaLeft, refAreaRight, refAreaTop, refAreaBottom } = initState;
    const { data } = initState;

    if (
      refAreaLeft === refAreaRight ||
      refAreaRight === "" ||
      refAreaTop === refAreaBottom ||
      refAreaBottom === ""
    ) {
      setInitState({
        ...initState,
        refAreaLeft: "",
        refAreaRight: "",
        refAreaTop: "",
        refAreaBottom: "",
      });
      return;
    }

    //^ xAxis domain
    if (refAreaLeft > refAreaRight)
      [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

    // //^ yAxis domain
    if (refAreaBottom > refAreaTop)
      [refAreaBottom, refAreaTop] = [refAreaTop, refAreaBottom];

    setInitState({
      refAreaLeft: "",
      refAreaRight: "",
      refAreaTop: "",
      refAreaBottom: "",
      data: data.slice(),
      left: refAreaLeft,
      right: refAreaRight,
      top: refAreaTop,
      bottom: refAreaBottom,
    });
  };

  const handleOverview = () => {
    const { data } = initState;

    if (borderMinMax) {
      const { minX, maxX, minY, maxY } = borderMinMax;

      setInitState(() => ({
        data: data.slice(),
        refAreaLeft: "",
        refAreaRight: "",
        refAreaTop: "",
        refAreaBottom: "",
        left: minX,
        right: maxX,
        top: maxY,
        bottom: minY,
      }));
    }
  };
  const handleCenter = () => {
    const { data } = initState;
    setInitState(() => ({
      data: data.slice(),
      refAreaLeft: "",
      refAreaRight: "",
      refAreaTop: "",
      refAreaBottom: "",
      left: minX - minX * 0.05,
      right: maxX + maxX * 0.05,
      top: maxY + maxY * 0.05,
      bottom: minY - minY * 0.05,
    }));
  };

  const generateCustomDot = (dotProps: any) => {
    const { cx, cy, node, key } = dotProps;
    const { x, y, z: name } = node;

    const numberPattern = /\d+/; //~ Regular expression to match one or more digits

    const rank = Number(key.match(numberPattern)[0]) + 1;

    const dotSize = 4;
    const lineSize = "100%";

    const { fill: fillColor, stroke: strokeColor } = getDotColor(
      { x, y, name },
      rank
    );
    const raisUp = strokeColor === palette.primary.main ? true : false;
    const getDotId = (id: any) => {
      if (typeof rank === "number") return JSON.stringify(rank);
      return JSON.stringify(-1);
    };
    //*Rendrer
    return (
      <g id={JSON.stringify(rank)} transform={`translate(${cx},${cy})`}>
        {raisUp && (
          <>
            <line
              x1={`-${lineSize}`}
              y1={0}
              x2={lineSize}
              y2={0}
              stroke={strokeColor}
              strokeWidth={1}
              strokeDasharray="2 0 2"
            />
            <line
              x1={0}
              y1={`-${lineSize}`}
              x2={0}
              y2={lineSize}
              stroke={strokeColor}
              strokeWidth={1}
              strokeDasharray="2 0 2"
            />
          </>
        )}
        {fillColor === "red" ? (
          <rect
            id={getDotId(rank)}
            x={-(dotSize / 2)}
            y={-(dotSize / 2)}
            width={dotSize}
            height={dotSize}
            fill={fillColor}
            stroke={strokeColor}
            strokeWidth={0.25}
            transform="scale(3.5)"
          />
        ) : (
          <polygon
            id={getDotId(rank)}
            points={`0,${-dotSize} ${dotSize},${dotSize} ${-dotSize},${dotSize}`}
            fill={fillColor}
            stroke={strokeColor}
            strokeWidth={0.25}
            transform="scale(2.5)"
          />
        )}
      </g>
    );
  };

  //* Rendrer
  return data ? (
    <Box
      display="Grid"
      gridTemplateRows="auto 1fr"
      gridTemplateColumns="6fr 1fr"
      gridTemplateAreas={`"keys ." "chart buttons"`}
      height="38vh"
    >
      <Box
        justifySelf="center"
        display="flex"
        alignItems="center"
        gridArea="keys"
      >
        <Triangle sx={{ color: "limeGreen", transform: "scale(0.8)" }} />
        <Typography align="center" variant="caption" fontSize={'1rem'}>
          Acceptable |
        </Typography>
        <Square sx={{ color: "red", transform: "scale(0.7)" }} />
        <Typography align="center" variant="caption" fontSize={'1rem'}>
          Unsecured
        </Typography>
      </Box>
      <Box gridArea="chart">
        <ResponsiveContainer width="97%" height="99%">
          <ScatterChart
            syncMethod="index"
            margin={{
              top: 20,
              right: 0,
              bottom: 20,
              left: 20,
            }}
            onMouseDown={(state: any, e: any) => {
              const { id: rank } = e.target;
              setDisplayToolTip(false);
              rank !== "" && handleDotClick(Number(rank));
              state &&
                setInitState({
                  ...initState,
                  refAreaLeft: state.xValue,
                  refAreaTop: state.yValue,
                });
            }}
            onMouseMove={(state: any) => {
              state &&
                initState.refAreaLeft &&
                setInitState({
                  ...initState,
                  refAreaRight: state.xValue,
                  refAreaBottom: state.yValue,
                });
            }}
            onMouseUp={(state: any, e: any) => {
              handleZoom();
              setDisplayToolTip(true);
            }}
          >
            {initState.refAreaLeft && initState.refAreaRight ? (
              <ReferenceArea
                yAxisId="1"
                x1={initState.refAreaLeft}
                x2={initState.refAreaRight}
                y1={initState.refAreaTop}
                y2={initState.refAreaBottom}
                fill="#fcfbbd"
                fillOpacity={1}
                stroke="#ceca00"
                strokeWidth="0.5px"
              />
            ) : null}
            <ReferenceArea
              yAxisId="1"
              x1={minX}
              x2={maxX}
              y1={minY}
              y2={maxY}
              stroke="green"
              fill="#b5f7ba"
              strokeOpacity={0.3}
              ifOverflow="extendDomain"
            />
            <CartesianGrid />
            <XAxis
              allowDataOverflow
              domain={[initState.left, initState.right]}
              type="number"
              dataKey="x"
              name={axisX}
              // tickCount={getTicksNbr("x")}
              ticks={createTicks('x')}
              tickFormatter={(tick) =>
                isDecimal(tick) ? tick.toFixed(2) : tick
              }
            >
              <Label value={axisX} offset={-10} position="insideBottom" />
            </XAxis>
            <YAxis
              allowDataOverflow={true}
              domain={[initState.bottom, initState.top]}
              type="number"
              dataKey="y"
              name={axisY}
              yAxisId="1"
              // tickCount={getTicksNbr("y")}
              ticks={createTicks('y')}
              tickFormatter={(tick) =>
                isDecimal(tick) ? tick.toFixed(2) : tick
              }
            >
              <Label
                angle={-90}
                offset={15}
                value={axisY}
                position="left"
                style={{ textAnchor: "middle" }}
              />
            </YAxis>
            <ZAxis dataKey="name" />

            {displayToolTip && (
              <Tooltip
                content={<CustomTooltip />}
                cursor={{ strokeDasharray: "3 3", stroke: "#1565C0" }}
              />
            )}
            <Scatter
              yAxisId="1"
              name="find best preform chart"
              data={data}
              stroke="white"
              shape={generateCustomDot}
              animationDuration={300}
            >
              {data.map((entry, index) => (
                <Cell
                  onClick={(e) => {
                    handleDotClick(index + 1);
                  }}
                  key={`cell-${index}`}
                />
              ))}
            </Scatter>
          </ScatterChart>
        </ResponsiveContainer>
      </Box>
      <Box
        gridArea="buttons"
        alignSelf="center"
        sx={{ transform: "translateY(-20px)" }}
      >
        <Button
          sx={{ position: "absolut" }}
          onClick={handleOverview}
          startIcon={<ArrowCollapseAll />}
        >
          overview
        </Button>
        <Button
          sx={{ position: "absolut" }}
          onClick={handleCenter}
          startIcon={<ImageFilterCenterFocus />}
        >
          center
        </Button>
      </Box>
    </Box>
  ) : (
    <LoadingSpinner />
  );
};

//&---------<< Custom tooltip >>----------------------

const CustomTooltip = (props: any) => {
  const { active, payload } = props;
  if (active && payload && payload.length) {
    return (
      <Card
        variant="outlined"
        sx={{ bgcolor: "#fcfbbd", padding: "0 10px 0 10px" }}
      >
        <h4>{`${payload[2].value}`}</h4>
        <p>{`${payload[0].name} = ${payload[0].value}`}</p>
        <p>{`${payload[1].name} = ${payload[1].value}`}</p>
      </Card>
    );
  }

  return null;
};

export default PreformChart;
