import { DatabaseExport } from "mdi-material-ui";
import { DataLayout } from "shared/components";
import { useNavigate, useParams } from "react-router-dom";
import { InfoTable } from "shared/components";
import { useEffect, useState } from "react";
import { dispatchFetch } from "shared/components/fetchers";
import { useNavigateSubRoute } from "shared/hooks";
import {
  getErrorMessage,
  infoDataTreatment,
  objectType,
} from "shared/Utils/utils";
import { useSnackbar } from "notistack";
import FileSaver from "file-saver";

const Informations = () => {
  const [infos, setInfos] = useState<objectType>();
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const param = useParams();
  const navigate = useNavigate();

  //*Get init
  useEffect(() => {
    dispatchFetch("GET")(`/processes/${param.id}`)
      .then((data: any) => {
        setIsEditable(data.isEditable);
        delete data.isEditable;
        let factData = {
          massInfos: {
            areaDistribution: { ...data.bottle.areaDistribution },
            massRepartitionTarget: { ...data.bottle.massRepartitionTarget },
            massRepartitionFinal: { ...data.massRepartitionFinal },
          },
          ...data,
          blowingMark: data.blowingMark.name,
          machineName: data.machineName.name,
          operator: data.operator?.name,
          owner: data.owner?.username,
        };
        let { massRepartitionFinal, ...rest } = factData;
        setInfos(infoDataTreatment(rest, "process"));
      })
      .catch(function (error: any) {});
  }, [param.id]);
  //*Handler
  const navigateToSubRoute = useNavigateSubRoute();
  const handleEdit = () => {
    navigateToSubRoute(`edit/${param.id}`, 1);
  };
  const handleExport = async () => {
    dispatchFetch("GET")(`/processes/${param.id}/report`)
      .then((data: any) => {
        if (data) {
          const csvData = new Blob([data], { type: "text/csv;charset=utf-8;" });
          let fileName = infos ? infos.Name : "Process";
          FileSaver.saveAs(csvData, `Process_${fileName}.csv`);

          enqueueSnackbar(`The process information downloading`, {
            variant: "success",
          });
        }
      })
      .catch(function (error: any) {
        let errorMessage = getErrorMessage(error);
        enqueueSnackbar(errorMessage, {
          variant: "error",
        });
      });
  };
  const handleDelete = async () => {
    await dispatchFetch("DELETE")(`/processes/${param.id}`)
      .then((data: any) => {
        if (data.status >= 200 && data.status < 300) {
          enqueueSnackbar(`The process deleted with success`, {
            variant: "success",
          });
          navigate("/process/research");
        }
      })
      .catch((error: any) => {
        let errorMessage = getErrorMessage(error);
        enqueueSnackbar(errorMessage, {
          variant: "error",
        });
      });
  };
  const handleCopy = () => {
    navigateToSubRoute(`add#${param.id}`, 1);
  };
  //*Render
  return (
    <DataLayout
      hideCancelBtn
      title={`Process : ${infos ? infos.Name : ""}`}
      variant="delete"
      deleteMsg="⚠️ Are you sure you want to delete thi process ?"
      hasRights={isEditable}
      disableValidation={!isEditable}
      btnTxt="Export"
      btnIcon={<DatabaseExport />}
      onEdit={handleEdit}
      onCopy={handleCopy}
      onDelete={handleDelete}
      onBtnClick={handleExport}
    >
      <InfoTable infos={infos} />
    </DataLayout>
  );
};

export default Informations;
