import { DataLayout } from "shared/components";
import ProjectForm from "screens/App/views/Project/components/ProjectForm";
import { getFormConfig } from "shared/constants/formConfigs";
import { FormikHelpers, useFormik } from "formik";
import {
  createFormData,
  getErrorMessage,
  updateObjectValue,
} from "shared/Utils/utils";
import { dispatchFetch } from "shared/components/fetchers";
import { projectValidationSchema } from "shared/constants/validationSchemas";
import { contentType } from "shared/constants/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useNavigateSubRoute } from "shared/hooks";
import { useEffect, useState } from "react";
import LoadingSpinner from "shared/components/LoadingSpinner";
const Add = () => {
  const navigate = useNavigate();
  const navigateSubRoute = useNavigateSubRoute();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [copyId, setCopyId] = useState<string>("");
  const [data, setData] = useState<any>();
  const location = useLocation();

  const [projectFormConfig, setProjectFormConfig] = useState();

  //*Mounted
  useEffect(() => {
    setCopyId(location.hash.replace(/#/g, ""));

    getFormConfig().then((config: any) => {
      config.projectForm.brief.parameters.initialValue = undefined;
      setProjectFormConfig(config.projectForm);
    });
  }, [location.hash]);

  //*Watcher
  useEffect(() => {
    if (copyId) {
      dispatchFetch("GET")(`/projects/${copyId}`).then((data: any) => {
        updateObjectValue(data, null, "");
        setData(data);
      });
    }
  }, [copyId]);

  //*Handler
  const handleAddObject = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    let formData = createFormData(values, projectFormConfig);
    let url = "/projects/";

    setLoading(true);
    try {
      await dispatchFetch("POST")(url, formData, contentType.formData)
        .then((data: any) => {
          if (data) {
            enqueueSnackbar(`The Project added with success`, {
              variant: "success",
            });
            navigateSubRoute(``);
          }
        })
        .catch((error: any) => {
          let errorMessage = getErrorMessage(error);
          enqueueSnackbar(errorMessage, {
            variant: "error",
          });
        });
    } finally {
      setLoading(false);
    }
  };
  //*Formik
  const formi = useFormik({
    enableReinitialize: true,
    initialValues: data
      ? { ...data, brand: data.brand.id, leader: data.leader.id }
      : {},
    validationSchema: projectValidationSchema,
    onSubmit: handleAddObject,
  });
  //*Render
  return (
    <DataLayout
      onValidation={() => {
        formi.handleSubmit();
      }}
      onEnter={formi.handleSubmit}
      disableValidation={!formi.isValid || !formi.dirty}
      onCancel={() => navigate(-1)}
      variant="add"
      title={data ? `Copy project ${data.name}` : "Add project"}
      isLoading={loading}
    >
      {projectFormConfig ? (
        <ProjectForm
          formConfig={projectFormConfig}
          formik={formi}
          validatioSchema={projectValidationSchema}
        />
      ) : (
        <LoadingSpinner />
      )}
    </DataLayout>
  );
};

export default Add;
