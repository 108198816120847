import { DataLayout, MassTable, TwoColumnsLayout } from "shared/components";
import { getFormConfig } from "shared/constants/formConfigs";
import BottleForm from "screens/App/views/Bottle/components/BottleForm";
import { FormikHelpers, useFormik } from "formik";
import { contentType, massTableInitValues } from "shared/constants/constants";
import { bottleValidationSchema } from "shared/constants/validationSchemas";
import { dispatchFetch } from "shared/components/fetchers";
import { useLocation, useNavigate } from "react-router-dom";
import { useNavigateSubRoute } from "shared/hooks";
import { useSnackbar } from "notistack";
import {
  appendObjectToFormData,
  createFormData,
  getErrorMessage,
  updateObjectValue,
} from "shared/Utils/utils";
import { useEffect, useState } from "react";
import LoadingSpinner from "shared/components/LoadingSpinner";

const Add = () => {
  const navigate = useNavigate();
  const navigateSubRoute = useNavigateSubRoute();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [copyId, setCopyId] = useState<string>("");
  const [data, setData] = useState<any>();
  const location = useLocation();

  const [bottleFormConfig, setBottleFormConfig] = useState();
  //*Mounted
  useEffect(() => {
    setCopyId(location.hash.replace(/#/g, ""));

    getFormConfig().then((config: any) => {
      config.bottleForm.technicalDrawing.parameters.initialValue = undefined;
      setBottleFormConfig(config.bottleForm);
    });
  }, [location.hash]);

  //*Watcher
  useEffect(() => {
    if (copyId) {
      dispatchFetch("GET")(`/bottles/${copyId}`).then((data: any) => {
        updateObjectValue(data, null, "");
        setData(data);
      });
    }
  }, [copyId]);
  //*Handler
  const handleAddObject = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    let url = "/bottles/";
    let formData = createFormData(values, bottleFormConfig);

    appendObjectToFormData(
      values.areaDistribution,
      formData,
      "areaDistribution"
    );
    appendObjectToFormData(
      values.massRepartitionTarget,
      formData,
      "massRepartitionTarget"
    );

    setLoading(true);
    try {
      await dispatchFetch("POST")(url, formData, contentType.formData)
        .then((data: any) => {
          if (data) {
            enqueueSnackbar(`The Bottle added with success`, {
              variant: "success",
            });
            navigateSubRoute(``);
          }
        })
        .catch((error: any) => {
          let errorMessage = getErrorMessage(error);
          enqueueSnackbar(errorMessage, {
            variant: "error",
          });
        });
    } finally {
      setLoading(false);
    }
  };
  //*Formik
  const { massRepartitionFinal } = massTableInitValues;
  const formi = useFormik({
    enableReinitialize: true,
    initialValues: data
      ? {
          massRepartitionFinal,
          ...data,
          project: data.project.id,
          bottomTypeShape: data.bottomTypeShape.id,
          designAgency: data.designAgency.id,
          sectionType: data.sectionType.id,
        }
      : massTableInitValues,
    validationSchema: bottleValidationSchema,
    onSubmit: handleAddObject,
  });

  //*Watcher
  useEffect(() => {
    formi.setFieldValue("areaDistribution.ring", formi.values.height);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formi.values.height]);
  //*Render
  return (
    <TwoColumnsLayout
      title={data ? `Copy bottle ${data.name}` : "Add bottle"}
      variant="add"
      btnCollapseTxt="Mass distribution"
      onValidation={() => {
        formi.handleSubmit();
      }}
      onEnter={formi.handleSubmit}
      disableValidation={!formi.isValid || !formi.dirty}
      onCancel={() => navigate(-1)}
      isLoading={loading}
      leftArea={
        bottleFormConfig ? (
          <BottleForm
            formConfig={bottleFormConfig}
            formik={formi}
            validatioSchema={bottleValidationSchema}
          />
        ) : (
          <LoadingSpinner />
        )
      }
      rightArea={
        <DataLayout variant="display" title="Mass distribution">
          <MassTable formik={formi} />
        </DataLayout>
      }
    />
  );
};

export default Add;
