import useSWRImmutable from "swr/immutable";
import { GenericForm } from "shared/components";
import {
  FieldConfigTemplate,
  FieldConfig,
  AutoOption,
} from "shared/Utils/utils";
import LoadingSpinner from "shared/components/LoadingSpinner";
import useSWR from "swr";

export type ProjectFormConfigType = {
  leader: FieldConfigTemplate<"select">;
  brand: FieldConfigTemplate<"select">;
  brief: FieldConfigTemplate<"fileField">;
  [key: string]: FieldConfig;
};
type ProjectFilterConfigType = {
  leader: FieldConfigTemplate<"select">;
  brand: FieldConfigTemplate<"select">;
  [key: string]: FieldConfig;
};

type ProjectFormType = {
  formConfig: ProjectFormConfigType | ProjectFilterConfigType;
  formik: any;
  validatioSchema?: any;
};

const ProjectForm = ({
  formConfig,
  formik,
  validatioSchema,
}: ProjectFormType) => {
  const { data: leaders } = useSWRImmutable("/projects/leaders?pageSize=10000");
  const { data: brands } = useSWRImmutable("/projects/brands?pageSize=10000");
  const { data: projects } = useSWR(`/projects/?pageSize=10000&order_by=name`);

  let projectsName: Array<AutoOption> = [];
  projects?.results.map(
    (result: { name: string }) =>
      (projectsName = [...projectsName, { label: result.name }])
  );

  if (!leaders || !brands) return <LoadingSpinner />;

  const { leader, brand, name__icontains } = formConfig;

  leader.parameters.options = leaders?.results;
  brand.parameters.options = brands?.results;

  if (name__icontains)
    (
      name__icontains as FieldConfigTemplate<"textField">
    ).parameters.autoOptions = projectsName;
  return (
    <GenericForm
      formConfig={formConfig}
      formik={formik}
      validatioSchema={validatioSchema}
    />
  );
};

export default ProjectForm;
