import { Box } from "@mui/material";
import { useContext, useEffect } from "react";
import { AccessContext } from "RootApp";

// &---------------------------------<< Component >>----*
interface SearchLayoutProps {
  filterArea?: React.ReactNode;
  resultArea?: React.ReactNode;
  topResultArea?: React.ReactNode;
  onEnter?: () => void;
}
/**
 * JSX.Element element represents the layout fo the search screens based on MUI Box
 * @see layout of 2 columns and the right column splited on two lines
 * @prop {React.ReactNode} [filterArea] - Represent the slot for the filter (left column)
 * @prop {React.ReactNode} [resultArea] - Represent the slot for the result list (bottom of right column)
 * @prop {React.ReactNode} [topResultArea] - Represent the slot on the top of the result list (top of right column)
 * @prop {()=> void} [onEnter] - the action when user tap enter keyboard btn
 * @example <SearchLayout filterArea={<MyFilter/>} resultArea={<MyResults/>} topResultArea={<button/>}/>
 */
const SearchLayout = (props: SearchLayoutProps) => {
  const { filterArea, resultArea, topResultArea, onEnter } = props;
  const { toggleAccess } = useContext(AccessContext);

  //*Mounted
  useEffect(() => {
    toggleAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //*Handler
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      onEnter && onEnter();
    }
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns=" repeat(3, minmax(102px, 1fr)) repeat(9, 1fr)"
      gridTemplateRows="auto 1fr"
      columnGap={2}
      rowGap={topResultArea ? 2 : 0}
    >
      <Box minWidth="340px" gridColumn="1/4" gridRow="1/3">
        {filterArea}
      </Box>
      <Box gridColumn="4/13" gridRow="1/2">
        {topResultArea}
      </Box>
      <Box gridColumn="4/13" gridRow="2/3">
        {resultArea}
      </Box>
    </Box>
  );
};

export default SearchLayout;
