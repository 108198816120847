import React, { useRef, useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import { Box, IconButton, Typography } from "@mui/material";
import { FileUpload, DeleteForever, Close } from "mdi-material-ui";

type FileFieldProps = {
  id: string;
  name: string;
  label: string;
  initialValue?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement> | undefined) => void;
  onDelete: React.MouseEventHandler<HTMLButtonElement>;
  onBlur?: any;
  error?: boolean;
  helperText?: any;
  accept?: string;
  required?: boolean;
  deletable?: boolean;
};

const getFileName = (file?: any) => {
  let result = "No selected file";
  if (file) result = file.name;
  return result;
};

/**
 * JSX.Element element represents a form field to upload documents
 * @prop {string} id - field ID
 * @prop {string} name - field name
 * @prop {string} label - field label
 * @prop {string} [initialValue] - intial value of the field
 * @prop {any} [onBlur] - onBlur action
 * @prop {boolean} [error] - error is true if the input not valid
 * @prop {any} [helperText] - helper text for the field
 * @prop {string} [label] - field label
 * @prop {Callback} accept - accept
 * @prop {boolean} required - if the field is required or not
 * @prop {boolean} deletable - if delete action is allowed on the field for the content
 * @example <FileField id={key} name={key} label={label} required={true} onChange={(e) => {}} onDelete={() => {}} onBlur={formik.handleBlur} error={formik.touched[key]} helperText={"explain"} {...parameters} />
 */

export const FileField = ({
  id,
  name,
  label,
  initialValue,
  onChange,
  onDelete,
  onBlur,
  error,
  helperText,
  accept,
  required,
  deletable = true,
}: FileFieldProps) => {
  const [fileName, setFileName] = useState<string | null>(null);
  const [hasFile, setHasFile] = useState(Boolean(initialValue));

  //* Handler
  const handleCleanFileField = () => {
    onChange(undefined);
    setFileName(null);
  };

  return (
    <FormControl id={id} required={required} fullWidth error={error}>
      <InputLabel htmlFor="fileInput" shrink disableAnimation>
        {label}
      </InputLabel>
      {initialValue && hasFile ? (
        <Box display="grid" gridTemplateColumns="auto 24px" alignItems="center">
          <Typography variant="body2">
            Current: {initialValue?.split("/").reverse()[0]}
          </Typography>
          {deletable && (
            <IconButton
              aria-label="delete"
              color="error"
              onClick={(e) => {
                onDelete(e);
                setHasFile(false);
              }}
            >
              <DeleteForever />
            </IconButton>
          )}
        </Box>
      ) : (
        <OutlinedInput
          fullWidth
          id="fileInput"
          name={name}
          onBlur={onBlur}
          label={label}
          notched
          aria-hidden
          startAdornment={
            <InputAdornment position="start">
              <IconButton color="primary" component="label" size="small">
                <FileUpload />
                <input
                  hidden
                  name={name}
                  type="file"
                  accept={accept}
                  onChange={(e) => {
                    onChange(e);
                    if (e.currentTarget.files)
                      setFileName(getFileName(e.currentTarget.files[0]));
                  }}
                />
              </IconButton>
              <Typography
                variant="body2"
                color={fileName ? "initial" : "default"}
              >
                {fileName ? fileName : "No selected file"}
              </Typography>
              {fileName && (
                <IconButton
                  aria-label="delete"
                  color="default"
                  onClick={handleCleanFileField}
                >
                  <Close />
                </IconButton>
              )}
            </InputAdornment>
          }
        />
      )}
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default FileField;
