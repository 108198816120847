import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Link,
} from "@mui/material";
import { isLiteralObject, isArray, updateUrlPrefix } from "shared/Utils/utils";
import LoadingSpinner from "./LoadingSpinner";

import { FileEye } from "mdi-material-ui";
import { useState } from "react";
import MassInfos from "./MassInfos";
import FilesListInfos from "./FilesListInfos";
import { InfosNamesPath } from "shared/constants/constants";

// &---------------------------------<< Component >>----*

interface InfoTableProps {
  infos: { [key: string]: any } | undefined;
}
/**
 * JSX.Element element used to display the information page
 * @prop {object | undefined} infos - data service
 * @example <InfoTable infos={data}/>
 */

const InfoTable = (props: InfoTableProps) => {
  const { infos } = props;
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  //*Rendrer
  return (
    <>
      <TableContainer component={Box}>
        <Table sx={{ minWidth: 450 }} aria-label="object informations">
          <TableBody>
            {infos ? (
              Object.entries(infos).map((info, i) => (
                <InfoItem
                  key={i}
                  title={info[0]}
                  info={info[1]}
                  itemClick={() => {
                    setDialogTitle(info[0]);
                    setOpenDialog(true);
                  }}
                />
              ))
            ) : (
              <TableRow>
                <TableCell>
                  <LoadingSpinner />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={isOpenDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
        aria-labelledby="massTable-dialog"
      >
        <DialogTitle id="massTable-dialog">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            {dialogTitle === "Mass distribution" && (
              <MassInfos data={infos && infos["Mass distribution"]} />
            )}
            {dialogTitle === "Additional files" && (
              <FilesListInfos filesList={infos && infos["Additional files"]} />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
            }}
            color="primary"
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InfoTable;

// &---------------------------------<< SubComponent >>----*
interface InfoItemProps {
  title: string;
  info: string;
  itemClick: React.MouseEventHandler<HTMLButtonElement>;
}

const InfoItem = (props: InfoItemProps) => {
  const { title, info, itemClick } = props;
  return (
    <TableRow>
      <TableCell>
        <Typography variant="subtitle1">{title}</Typography>
      </TableCell>
      <TableCell align="right">
        <DataCellContent name={title} data={info} cellClick={itemClick} />
      </TableCell>
    </TableRow>
  );
};

// &---------------------------------<< Sub-SubComponent >>----*
interface DataCellContentProps {
  name: string;
  data: string | { id: number; Name: string };
  cellClick: React.MouseEventHandler<HTMLButtonElement>;
}
const DataCellContent = (props: DataCellContentProps): JSX.Element => {
  const { name, data, cellClick } = props;
  if (isArray(data)) {
    return (data as string).length <= 0 ? (
      <Typography variant="subtitle2" color="error">
        No data available
      </Typography>
    ) : (
      <Button size="small" onClick={cellClick}>
        See more
      </Button>
    );
  }
  if (isLiteralObject(data)) {
    if ((data as { id: number }).id) {
      return (
        <Button
          size="small"
          sx={{ textTransform: "capitalize", textAlign: "left" }}
        >
          <Link
            href={`${InfosNamesPath[name]}/${(data as { id: number }).id}`}
            target="_blank"
            rel="noreferrer"
            underline="none"
          >
            {(data as { Name: string }).Name}
          </Link>
        </Button>
      );
    } else
      return (
        <Button size="small" onClick={cellClick}>
          MASS REPARTITION
        </Button>
      );
  }
  if (typeof data === "string" && data.split(":")[0].includes("http")) {
    let filePath = data;
    filePath = updateUrlPrefix(
      filePath,
      process.env.REACT_APP_MEDIA_BASE_URL as string
    );

    return (
      <a href={filePath} rel="noopener noreferrer" target="_blank">
        <Tooltip title="open" placement="top" arrow>
          <IconButton
            size="small"
            color="primary"
            aria-label="upload picture"
            component="span"
          >
            <FileEye />
          </IconButton>
        </Tooltip>
      </a>
    );
  }
  if (typeof data === "boolean") {
    return <Typography variant="subtitle2">{JSON.stringify(data)}</Typography>;
  } else {
    return (
      <Typography variant="subtitle2" color={!data ? "error" : "initial"}>
        {!data ? "No data available" : data}
      </Typography>
    );
  }
};
