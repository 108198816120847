import createTheme from "@mui/material/styles/createTheme";
import { PaletteMode } from "@mui/material";

let mode: PaletteMode = "light";

function zIndexTheme() {
  //~ switch default value between zIndex of drawer and appBar
  return {
    components: {
      MuiStepIcon: {
        styleOverrides: {
          root: {
            color: "#e0e0e0", // palette.grey[300]
            "&.Mui-active": {
              color: "#4caf50", // palette.success.light
            },
            "&.Mui-completed": {
              color: "#bdbdbd", //palette.grey[400]
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& input[type=number]:: -webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
              {
                display: "none",
              },
            "& input[type=number]": {
              MozAppearance: "textfield",
            },
            "& .Mui-required":{
              fontWeight:"bold",
              fontSize:"1.1rem",
              paddingRight:"5px",
              backgroundColor:"white",
              "& span":{
                fontWeight:"bolder",
              }
            },
            // "& input[required]":{
            //   border:"2px solid red"
            // }
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          outlined: {
            backgroundColor: "rgba(255,255,255, 0.8)",
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&.MuiTableRow-hover:hover": {},
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: "#39b1e3",
            },
          },
        },
      },
    },
    zIndex: {
      appBar: 1200,
      drawer: 1100,
    },
  };
}

function colorTheme() {
  return {
    palette: {
      mode,
      primary: {
        main: "#1e3b80"
      },
      action: {
        hover: "rgba(25,118,210, 0.4)",
      },
    },
  };
}

function currentTheme() {
  return { ...colorTheme(), ...zIndexTheme() };
}

export const globalTheme = createTheme(currentTheme());
