import { GridSize } from "@mui/material";
import { SchemaDescription } from "yup/lib/schema";

import { objectInfosNames } from "../constants/constants";

export type NamedObject = { id: number; name: string };
export type AutoOption = { label: string };

export interface SearchDataResultsProps {
  id: string | number;
  name?: string | number;
}

export interface BorderProps {
  x: { min: number; max: number; name?: string };
  y: { min: number; max: number; name?: string };
}
export interface SearchDataProps {
  results: SearchDataResultsProps[];
  [key: string]: any;
}

export interface CashProps {
  filterHistory?: Object;
}

type Parameters = {
  textField: {
    type?: "number" | "text";
    rows?: number;
    autoOptions?: AutoOption[];
    helperText?: string;
  };
  select: {
    options?: NamedObject[];
  };
  multiSelect: {
    options?: Array<NamedObject>;
  };
  fileField: {
    accept?: string;
    initialValue?: string;
  };
  checkbox: {};
};

export type FieldConfigTemplate<T> = {
  type: T;
  label: string;
  gridxs?: GridSize;
  parameters: T extends keyof Parameters ? Parameters[T] : never;
};

type FieldConfigMapped = {
  [key in keyof Parameters]: FieldConfigTemplate<key>;
};

type valueOf<T> = T[keyof T];
export type FieldConfig = valueOf<FieldConfigMapped>;

export type FormConfig = {
  [key: string]: FieldConfig;
};

export type Action = "ADD" | "EDIT" | "FILTER";

export const createTextFieldConfig = (
  label: string,
  gridxs?: GridSize,
  type?: "number" | "text",
  rows?: number,
  autoOptions?: AutoOption[],
  helperText?: string
): FieldConfigTemplate<"textField"> => {
  return {
    type: "textField",
    label: label,
    ...(gridxs && { gridxs: gridxs }),
    parameters: {
      ...(autoOptions && { autoOptions: autoOptions }),
      ...(type && { type: type }),
      ...(rows && { rows: rows }),
      ...(helperText && { helperText: helperText }),
    },
  };
};

export const createSelectFieldConfig = (
  label: string,
  gridxs?: GridSize,
  options?: NamedObject[]
): FieldConfigTemplate<"select"> => {
  return {
    type: "select",
    label: label,
    ...(gridxs && { gridxs: gridxs }),
    parameters: {
      ...(options && { options: options }),
    },
  };
};

export const createMultiSelectFieldConfig = (
  label: string,
  gridxs?: GridSize,
  options?: Array<NamedObject>
): FieldConfigTemplate<"multiSelect"> => {
  return {
    type: "multiSelect",
    label: label,
    ...(gridxs && { gridxs: gridxs }),
    parameters: {
      ...(options && { options: options }),
    },
  };
};

export const createFileFieldConfig = (
  label: string,
  gridxs?: GridSize,
  accept?: string,
  initialValue?: string
): FieldConfigTemplate<"fileField"> => {
  return {
    type: "fileField",
    label: label,
    ...(gridxs && { gridxs: gridxs }),
    parameters: {
      ...(accept && { accept: accept }),
      ...(initialValue && { initialValue: initialValue }),
    },
  };
};

export const createCheckbox = (
  label: string,
  gridxs?: GridSize
): FieldConfigTemplate<"checkbox"> => {
  return {
    type: "checkbox",
    label: label,
    ...(gridxs && { gridxs: gridxs }),
    parameters: {},
  };
};

export const generateURL = (values: any, objects: string) => {
  return (
    `/${objects}` +
    "/?" +
    Object.keys(values)
      .filter((key) => values[key] !== "")
      .map(
        (key: string) =>
          encodeURIComponent(key) + "=" + encodeURIComponent(values[key])
      )
      .join("&")
  );
};

export const createFormData = (values: any, formConfig?: FormConfig) => {
  const formData = new FormData();
  if (formConfig) {
    Object.keys(formConfig).forEach((key) => {
      if (formConfig[key].type === "fileField") {
        if (values[key] !== null && typeof values[key] === "object") {
          formData.append(key, values[key], values[key].name);
        } else if (values[key] === null) {
          formData.append(key, ""); //~Delete File
        }
      } else if (values[key] !== undefined && values[key] !== null) {
        formData.append(key, values[key]);
      }
    });
  }
  return formData;
};

export const appendObjectToFormData = (
  object: { [key: string]: string },
  formData: FormData,
  parentName: string
) => {
  Object.keys(object).forEach((key) =>
    formData.append(`${parentName}.${key}`, object[key])
  );
};

export const isLiteralObject = (a: any) => !!a && a.constructor === Object;

export const isArray = (a: any) => !!a && a.constructor === Array;

export const isFieldRequired = (field: string, validationSchema: any) => {
  if (validationSchema) {
    const temp = validationSchema.describe().fields[field] as SchemaDescription;
    //^ if you have error that we don't find .tests
    //^ check that the key of "validationSchema" === key of "formConfig"
    return temp.tests.findIndex(({ name }) => name === "required") >= 0;
  } else return false;
};

export type objectType = {
  [key: string]: any;
};

type objectNameType = "project" | "preform" | "bottle" | "process";

export const infoDataTreatment = (
  data: objectType,
  objectName: objectNameType
): object => {
  delete data.id;
  if (typeof data.createdOn === "string") {
    data.createdOn = data.createdOn.split("T")[0];
  }
  if (typeof data.modifiedOn === "string") {
    data.modifiedOn = data.modifiedOn.split("T")[0];
  }

  return updateKeys(data, objectInfosNames[objectName]);
};

const updateKeys = (data: object, object: objectType): object => {
  let dataStr = JSON.stringify(data);
  Object.keys(data).forEach((key) => {
    dataStr = dataStr.replaceAll(`"${key}"`, `"${object[key]}"`);
  });

  let newData = JSON.parse(dataStr);

  return newData;
};

export const getErrorMessage = (error: any) => {
  let message: string = "";

  if (error.response.data) {
    message = objectToString(message, error.response.data);
  } else {
    message = error.message;
  }
  return message;
};

const objectToString = (text: string, obj: any) => {
  for (let key in obj) {
    if (isLiteralObject(obj[key])) {
      text = `${key} : ${objectToString(text, obj[key])}\n${text}`;
    } else {
      text = `${key} : ${obj[key]}\n${text}`;
    }
  }
  return text;
};

export const updateUrlPrefix = (
  currentPath: string,
  newPrefix: string,
  currentPrefixSize: number = 4
) => {
  let currentPathArray = currentPath.split("/");
  for (let i = 0; i < currentPrefixSize; i++) {
    currentPathArray.shift();
  }
  currentPathArray.unshift(newPrefix);

  currentPath = currentPathArray.join("/");

  return currentPath;
};

type primitives = string | number | null | undefined;
export const updateObjectValue = (
  object: { [key: string]: primitives },
  valueToReplace: primitives,
  newValue: primitives
) => {
  object &&
    Object.keys(object).forEach((key: string) => {
      if (object[key] === valueToReplace) {
        object[key] = newValue;
      }
    });
};

export const convertToBoolean = (value: string): boolean => {
  const booleanMap: { [key: string]: boolean } = {
    true: true,
    True: true,
    false: false,
    False: false,
  };

  return booleanMap.hasOwnProperty(value) ? booleanMap[value] : Boolean(value);
};

export const replaceIdByName = (
  ids: number[],
  choices: NamedObject[] | undefined
) => {
  let names: string[] = [];
  if (choices) {
    names = ids.map((id: number) => {
      const item: NamedObject | undefined = choices.find(
        (obj: NamedObject) => obj.id === id
      );
      return item ? item.name : "";
    });
  }

  return names;
};

export const isEmptyObject = (obj: Object) => {
  return Object.keys(obj).length === 0;
};

export const getIsReset = (obj: any) => {
  //~clear all element with empty string
  const filteredObj = Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => value !== "")
  );

  return isEmptyObject(filteredObj);
};

export const isDecimal = (tick: string) => {
  const decimalRegex = /^\d+\.\d+$/;
  return decimalRegex.test(tick);
};
