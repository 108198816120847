import LoadingSpinner from "./LoadingSpinner";

interface DataLoadingProps {
  children: JSX.Element;
  data: Object | undefined | null;
  error?: any;
}
/**
 * JSX.Element element used to manage loading state of children component
 * @prop {React.ReactNode} [children] - The component can take elements as children
 * @prop {any} data - data service
 * @prop {any} [error = undefined] - error service
 * @example <DataLoading data={data} error={error}> <div></div> </DataLoading>
 */
const DataLoading = (props: DataLoadingProps): JSX.Element => {
  const { children, data, error = undefined } = props;
  if (error) return <div>failed to load</div>;
  else if (!data || Object.keys(data).length <= 0) return <LoadingSpinner />;
  else return children;
};

export default DataLoading;
