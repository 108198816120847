import { DataLayout, DataLoading } from "shared/components";
import { useNavigate, useParams } from "react-router-dom";
import { getFormConfig } from "shared/constants/formConfigs";
import ProjectForm from "../../../components/ProjectForm";
import { FormikHelpers, useFormik } from "formik";
import { projectValidationSchema } from "shared/constants/validationSchemas";
import { contentType } from "shared/constants/constants";
import {
  createFormData,
  getErrorMessage,
  updateObjectValue,
} from "shared/Utils/utils";
import { dispatchFetch } from "shared/components/fetchers";
import { useSnackbar } from "notistack";
import { useNavigateSubRoute } from "shared/hooks";
import { useEffect, useState } from "react";
import { ContentSaveSettingsOutline } from "mdi-material-ui";
import LoadingSpinner from "shared/components/LoadingSpinner";

const Edit = () => {
  const param = useParams();
  const navigate = useNavigate();
  const navigateSubRoute = useNavigateSubRoute();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [projectFormConfig, setProjectFormConfig] = useState();
  //*Watcher
  useEffect(() => {
    dispatchFetch("GET")(`/projects/${param.id}`).then((data: any) => {
      updateObjectValue(data, null, "");
      setData(data);
    });
  }, [param.id]);

  useEffect(() => {
    if (data) {
      getFormConfig().then((config: any) => {
        config.projectForm.brief.parameters.initialValue = data?.brief;
        setProjectFormConfig(config.projectForm);
      });
    }
  }, [data]);

  //*Handlers
  const handleEditObject = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    let formData = createFormData(values, projectFormConfig);
    let url = `/projects/${param.id}`;

    setLoading(true);
    try {
      await dispatchFetch("PUT")(url, formData, contentType.formData)
        .then((data: any) => {
          if (data) {
            enqueueSnackbar(`The Project was edited with success`, {
              variant: "success",
            });
            navigateSubRoute(`infos/${data.id}`, 1);
          }
        })
        .catch((error: any) => {
          let errorMessage = getErrorMessage(error);
          enqueueSnackbar(errorMessage, {
            variant: "error",
          });
        });
    } finally {
      setLoading(false);
    }
  };
  //*Formik
  const formi = useFormik({
    enableReinitialize: true,
    initialValues: data
      ? { ...data, brand: data.brand.id, leader: data.leader.id }
      : {},
    validationSchema: projectValidationSchema,
    onSubmit: handleEditObject,
  });
  //*Render
  return (
    <DataLayout
      onValidation={() => {
        formi.handleSubmit();
      }}
      onEnter={formi.handleSubmit}
      validationBtnIcon={<ContentSaveSettingsOutline />}
      disableValidation={!formi.isValid || !formi.dirty}
      onCancel={() => navigate(-1)}
      title={`Edit project`}
      variant="edit"
      isLoading={loading}
    >
      <DataLoading data={data}>
        {projectFormConfig ? (
          <ProjectForm
            formConfig={projectFormConfig}
            formik={formi}
            validatioSchema={projectValidationSchema}
          />
        ) : (
          <LoadingSpinner />
        )}
      </DataLoading>
    </DataLayout>
  );
};

export default Edit;
