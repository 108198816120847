import { DataLayout, TwoColumnsLayout } from "shared/components";
import BestPreformChart from "./BestPreformChart";
import useSWRImmutable from "swr/immutable";
import { AxisArrow } from "mdi-material-ui";
import BestPreformTable from "./BestPreformTable";
import { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { BorderProps } from "shared/Utils/utils";
import { Link } from "@mui/material";

const BestPreform = () => {
  const chartAreaRef = useRef<any>(null);
  const [isDisabledUpdate, setIsDisabledUpdate] = useState(false);
  const [rankingData, setRankingData] = useState();
  const [targetBorder, setTargetBorder] = useState<BorderProps>();
  const [focuedPrefRank, setFocuedPrefRank] = useState(0);

  //* Handler
  const handleValidationClick = () => {
    chartAreaRef.current && chartAreaRef.current.handleUpdateClick();
  };
  const handleFocusedPrefRank = (rank: number) => {
    setFocuedPrefRank(rank);
  };
  //* Local Utils
  const Location = useLocation();
  const bottleID = Location.pathname.split("/")[3];
  const { data: bottle } = useSWRImmutable(`/bottles/${bottleID}`);
  const getTitle = () => {
    return (
      <>
        <span>Preform assessment</span>
        <br />
        <Typography variant="body1" color="initial">
          <span>Bottle of interest : </span>
          <Link
            href={`/bottle/infos/${bottleID}`}
            target="_blank"
            rel="noreferrer"
            underline="none"
          >
            {bottle.name}
          </Link>
        </Typography>
      </>
    );
  };
  //* Rendrer
  return (
    <TwoColumnsLayout
      title={bottle ? getTitle() : "loading"}
      btnCollapseTxt="table"
      variant="edit"
      hideCancelBtn
      validationBtnTxt="update"
      validationBtnIcon={<AxisArrow />}
      onValidation={handleValidationClick}
      disableValidation={isDisabledUpdate}
      leftArea={
        <BestPreformChart
          ref={chartAreaRef}
          updateAvailable={(status) => setIsDisabledUpdate(status)}
          updateRankingData={(data) => setRankingData(data)}
          onTargetBorderChange={(borders) => setTargetBorder(borders)}
          focusedDotRank={focuedPrefRank}
          updateFocusedDotRank={handleFocusedPrefRank}
        />
      }
      rightArea={
        <DataLayout variant="display">
          <BestPreformTable
            rankingData={rankingData}
            targetArea={targetBorder}
            focusedItemRank={focuedPrefRank}
            updateFocusedPrefRank={handleFocusedPrefRank}
          />
        </DataLayout>
      }
    />
  );
};

export default BestPreform;
