import objectRoutes from "screens/App/route";
import { Routes, Route, Navigate } from "react-router-dom";

const Bottle = () => {
  const bottleRoutes = Object.values(objectRoutes.BottleRoute.routes);
  return (
    <Routes>
      {bottleRoutes.map((bottleRoute, i) => (
        <Route
          key={i}
          path={bottleRoute.path}
          element={<bottleRoute.component />}
        ></Route>
      ))}
      <Route
        path="*"
        element={
          <Navigate to={objectRoutes.BottleRoute.routes.researchRoute.path} />
        }
      />
    </Routes>
  );
};

export default Bottle;
