import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slide,
  Tooltip,
  Typography,
} from "@mui/material";
import { PencilOffOutline, PencilOutline, ContentCopy } from "mdi-material-ui";

import { DeleteForeverOutline } from "mdi-material-ui";
import { useContext, useEffect, useState } from "react";
import { AccessContext } from "RootApp";
// &---------------------------------<< Component >>----*

export interface DataLayoutProps {
  title?: string | React.ReactNode;
  variant?: "edit" | "add" | "display" | "delete";
  hideCancelBtn?: boolean;
  hasRights?: boolean;
  children?: React.ReactNode;
  btnTxt?: string;
  deleteMsg?: JSX.Element | string;
  btnIcon?: React.ReactNode;
  validationBtnTxt?: string;
  secondBtnTxt?: string;
  validationBtnIcon?: React.ReactNode;
  onBtnClick?: React.MouseEventHandler<HTMLButtonElement>;
  onCancel?: React.MouseEventHandler<HTMLButtonElement>;
  onDelete?: React.MouseEventHandler<HTMLButtonElement>;
  onValidation?: React.MouseEventHandler<HTMLButtonElement>;
  onSecondBtn?: React.MouseEventHandler<HTMLButtonElement>;
  onEnter?: () => void;
  onCopy?: () => void;
  disableValidation?: boolean;
  onEdit?: React.MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
}
/**
 * JSX.Element element represents the data layout to display form for Edit and Add pages and object information
 * @prop {"edit" | "add" | "display"} variant - define the variant of the left column
 * @prop {string | React.ReactNode} title - Title on the area
 * @prop {boolean} [hideCancelBtn = false] - hide or display cancel button
 * @prop {boolean} [hasRights = false] - to display th edit icon next to the title
 * @prop {React.ReactNode} [children] - The component can take elements as children
 * @prop {string} [btnTxt] - Text for the top right button
 * @prop {string} [deleteMsg] - Text for delete alert dialog
 * @prop {string} [validationBtnTxt] - You can edit the text of validation button
 * @prop {string} [secondBtnTxt] - You can edit the text of cancel button
 * @prop {React.ReactNode} [validationBtnIcon] - You can edit the icon of validation button
 * @prop {React.ReactNode} [btnIcon] - Icon for the top right button
 * @prop {React.MouseEventHandler<HTMLButtonElement>} [onBtnClick] - onClick for the top right button
 * @prop {React.MouseEventHandler<HTMLButtonElement>} [onCancel] - onClick for the cancel button
 * @prop {React.MouseEventHandler<HTMLButtonElement>} [onDelete] - onDelete for the delete button
 * @prop {React.MouseEventHandler<HTMLButtonElement>} [onValidation] - onClick for the save and create button
 * @prop {React.MouseEventHandler<HTMLButtonElement>} [onSecondBtn] - onClick for the second button
 * @prop {()=> void} [onEnter] - the action when user tap enter keyboard btn
 * @prop {boolean} [disableValidation = false] - disable validation button
 * @prop {React.MouseEventHandler<HTMLButtonElement>} [onEdit] - to edit object informations
 * @prop {React.MouseEventHandler<HTMLButtonElement>} [onCopy] - to copy object informations
 * @prop {boolean>} [isLoading] - change the state of validation button to loading if true
 * @example <DataLayout variant="edit" title="My title" btnTxt={btnColldleSplitArea} > <div>my children</div> </DataLayout>
 */
const DataLayout = (props: DataLayoutProps) => {
  const {
    variant = "display",
    title,
    hideCancelBtn = false,
    hasRights = false,
    children,
    btnTxt,
    deleteMsg,
    btnIcon,
    validationBtnTxt,
    secondBtnTxt,
    validationBtnIcon,
    onBtnClick,
    onCancel,
    onDelete,
    onValidation,
    onSecondBtn,
    onEnter,
    disableValidation = false,
    onEdit,
    onCopy,
    isLoading = false,
  } = props;

  const [cancelOpen, setCancelOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { toggleAccess } = useContext(AccessContext);

  //*Mounted
  useEffect(() => {
    toggleAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onEnter]);

  //*Handler
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      onEnter && onEnter();
    }
  };
  const handleDeleteDialogOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteOpen(false);
  };
  const handleCancelDialogOpen = () => {
    setCancelOpen(true);
  };

  const handleCancelDialogClose = () => {
    setCancelOpen(false);
  };
  const handleOnCancel = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onCancel && onCancel(e);
  };
  const handleOnDelete = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onDelete && onDelete(e);
  };
  //* Rendrer
  return (
    <>
      <Slide direction="left" in={true}>
        <Card>
          <Box
            display="grid"
            height="85vh"
            gridTemplateRows="auto 1fr auto"
            gap={2}
            m={2}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {title && (
                <Typography variant="h6" color="initial">
                  {title}
                </Typography>
              )}
              {btnTxt && (
                <Button
                  disableElevation
                  variant={variant === "delete" ? "contained" : "outlined"}
                  onClick={onBtnClick}
                  size="small"
                  endIcon={btnIcon}
                  color="secondary"
                >
                  {btnTxt}
                </Button>
              )}
            </Box>
            <Box sx={{ overflowY: "auto" }}>{children}</Box>

            {variant !== "display" && (
              <Box display="flex" justifyContent="flex-end" gap={2}>
                {!hideCancelBtn && (
                  <Button
                    disableElevation
                    variant="outlined"
                    onClick={handleCancelDialogOpen}
                  >
                    Cancel
                  </Button>
                )}
                {secondBtnTxt && (
                  <Button
                    disableElevation
                    variant="outlined"
                    onClick={onSecondBtn}
                  >
                    {secondBtnTxt}
                  </Button>
                )}

                {variant === "delete" ? (
                  <>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={onCopy}
                      startIcon={<ContentCopy />}
                    >
                      Copy
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={onEdit}
                      disabled={!hasRights}
                      startIcon={<PencilOutline />}
                    >
                      Edit
                    </Button>
                    <Button
                      sx={{ minWidth: "100px" }}
                      disableElevation
                      variant="contained"
                      color="error"
                      onClick={handleDeleteDialogOpen}
                      disabled={disableValidation}
                      startIcon={<DeleteForeverOutline />}
                    >
                      Delete
                    </Button>
                  </>
                ) : (
                  <LoadingButton
                    sx={{ minWidth: "100px" }}
                    disableElevation
                    variant="contained"
                    onClick={onValidation}
                    disabled={disableValidation}
                    startIcon={validationBtnIcon ? validationBtnIcon : null}
                    loading={isLoading}
                  >
                    {getValidationTxt(variant, validationBtnTxt)}
                  </LoadingButton>
                )}
              </Box>
            )}
          </Box>
        </Card>
      </Slide>
      <Dialog
        open={deleteOpen}
        onClose={handleDeleteDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle color="error">{" Delete?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {deleteMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleOnDelete}>
            Yes
          </Button>
          <Button onClick={handleDeleteDialogClose}>No</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={cancelOpen}
        onClose={handleCancelDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Cancel?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to cancel the action ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOnCancel}>Yes</Button>
          <Button onClick={handleCancelDialogClose}>No</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DataLayout;

// &---------------------------------<< Functions >>----*

const getValidationTxt = (
  type: DataLayoutProps["variant"],
  text: string | undefined
) => {
  if (text) return text;
  return type === "add" ? "Create" : "Save";
};
