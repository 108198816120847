import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigateSubRoute } from "shared/hooks";
import { SearchDataProps, updateUrlPrefix } from "shared/Utils/utils";
import LoadingSpinner from "../LoadingSpinner";

import ProjectImg from "shared/images/global/NoResults.png";

// &---------------------------------<< Component >>----*
interface ResultTableProps {
  title: string;
  data: SearchDataProps | undefined;
  onChangePage?: (pageNumber: string) => void;
}
/**
 * JSX.Element element represents the array of result in search page
 * @prop {Array<ResultTableProps>} data - The result list
 * @prop {Callback} onChangePage - onChange action
 * @example <ResultTable data={RESULTS_LIST} />
 */

const ResultTable = (props: ResultTableProps) => {
  const { title, data, onChangePage } = props;

  //*states
  const [page, setPage] = useState(0);

  //* Handlers
  const handleChangePage = (e: unknown, newPage: number) => {
    let newPageURL: string;
    newPage > page ? (newPageURL = data?.next) : (newPageURL = data?.previous);

    newPageURL = updateUrlPrefix(
      newPageURL,
      process.env.REACT_APP_API_BASE_URL as string
    );
    onChangePage && onChangePage(newPageURL);

    setPage(newPage);
  };

  const navigateSubRoute = useNavigateSubRoute();
  const handleListItem = (id: string | number) => {
    navigateSubRoute(`infos/${id}`);
  };
  //*render
  return (
    <Card>
      <TableContainer component={Box} height="calc(100vh - 209px)">
        <Table
          stickyHeader
          sx={{ minWidth: 450 }}
          aria-label="result search results"
        >
          <TableHead>
            <TableRow>
              <TableCell variant="head" align="left">
                <Typography variant="h6">{title}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.results ? (
              data.results.length > 0 ? (
                data.results.map((datum) => (
                  <TableRow key={datum.id} hover sx={{ cursor: "pointer" }}>
                    <TableCell
                      align="left"
                      onClick={() => handleListItem(datum.id)}
                    >
                      <Typography variant="subtitle1">{datum.name}</Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <th
                    style={{
                      backgroundImage: `url(${ProjectImg})`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      width: "100%",
                      height: "400px",
                    }}
                  ></th>
                </TableRow>
              )
            ) : (
              <tr>
                <th>
                  <LoadingSpinner />
                </th>
              </tr>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={data ? data.count : 0}
        rowsPerPage={10}
        page={page}
        onPageChange={handleChangePage}
      />
    </Card>
  );
};

export default ResultTable;
