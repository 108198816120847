import {
  Card,
  CardActionArea,
  ImageListItemBar,
  ImageListItemBarProps,
  useTheme,
} from "@mui/material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

interface MenuCardProps extends ImageListItemBarProps {
  path: string;
  imagePath: string;
  hexOverlay?: string;
  startBar?: string;
}

/**
 * JSX.Element element represent a card menu with ovely effect (used in the welcome page)
 * @prop {string} path - the path when the user tap on
 * @prop {string} imagePath - image in the background of the card
 * @prop {string} hexOverlay -the overlay color in hex code
 * @prop {string} startBar - the start point of the overly in percentage %
 * @example  <MenuCard path={appRouteConstants.PROJECT.path} imagePath={ProjectImg} title={appRouteConstants.PROJECT.name} hexOverlay={palette.primary.main} />
 */

const MenuCard = (props: MenuCardProps) => {
  const {
    path,
    imagePath,
    hexOverlay = "0,0,0",
    startBar = "40%",
    ...restProps
  } = props;

  const { transitions, typography } = useTheme();
  //*Handler
  const navigate = useNavigate();
  const handleCardClick = useCallback(() => {
    navigate(path);
  }, [navigate, path]);
  //* Rendrer
  return (
    <CardActionArea onClick={handleCardClick}>
      <Card
        sx={{
          borderRadius: "12px",
          height: "100%",
          width: "100%",
          minWidth: "206px",
          display: "grid",
          placeContent: "center",
          backgroundImage: `url(${imagePath})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          "& .MuiImageListItemBar-root": {
            transition: transitions.create([
              "height",
              "background",
              "border-radius",
            ]),
          },
          "& .MuiImageListItemBar-subtitle": {
            transition: transitions.create([
              "font-size",
              "opacity",
              "border-radius",
            ]),
          },
          "&:hover": {
            "& .MuiImageListItemBar-root": {
              transition: transitions.create([
                "height",
                "background",
                "border-radius",
              ]),
              height: "100%",
              background: `${hexOverlay}F2`,
              borderRadius: "12px",
            },
            "& .MuiImageListItemBar-subtitle": {
              transition: transitions.create([
                "font-size",
                "opacity",
                "border-radius",
              ]),
              opacity: 1,
              fontSize: typography.body1,
            },
          },
        }}
      >
        <ImageListItemBar
          {...restProps}
          sx={{
            textAlign: "center",
            borderRadius: "0px 0px 12px 12px",
            height: startBar,
            background: `${hexOverlay}99`,
            "&	.MuiImageListItemBar-title": { fontSize: typography.h5 },
            "& .MuiImageListItemBar-subtitle": { fontSize: 0, opacity: 0 },
          }}
        />
      </Card>
    </CardActionArea>
  );
};

export default MenuCard;
