import { Box, Slide } from "@mui/material";
import { CSSObject, styled, Theme, useTheme } from "@mui/material/styles";
import { useRef, useState } from "react";
import { ArrowCollapseRight, ArrowCollapseLeft } from "mdi-material-ui";
import { DataLayout } from "shared/components";
import { DataLayoutProps } from "./DataLayout";
import { convertToBoolean } from "shared/Utils/utils";

// &----------------------<< Layout CSS transition >>----*

const leftAreaReducedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create(["width", "opacity"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
});
const leftAreaExpandedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create(["width", "opacity"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

// &----------------------<< Styled component >>----*

interface AreaProps {
  splited: string;
}
const SplitedBoxs = styled(Box)((props: AreaProps) => {
  const { splited } = props;
  const theme = useTheme();

  return {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: theme.spacing(2),
    overflowX: "hidden",
    "& .Left-area": {
      zIndex: theme.zIndex.drawer,
      ...(convertToBoolean(splited) && {
        ...leftAreaExpandedMixin(theme),
        width: "100%",
      }),
      ...(!convertToBoolean(splited) && {
        ...leftAreaReducedMixin(theme),
        width: `calc(200% + ${theme.spacing(2)})`,
      }),
    },
  };
});

// &---------------------------------<< Component >>----*
interface TwoColumnsProps extends DataLayoutProps {
  initSplit?: boolean;
  hasRights?: boolean;
  leftArea?: React.ReactNode;
  rightArea?: React.ReactNode;
  btnCollapseTxt?: string;
  btnCollapseIcon?: {
    iconCollapseLeft: React.ReactNode;
    iconCollapseRight: React.ReactNode;
  };
  onCancel?: React.MouseEventHandler<HTMLButtonElement>;
  onValidation?: React.MouseEventHandler<HTMLButtonElement>;
  disableValidation?: boolean;
  onEdit?: React.MouseEventHandler<HTMLButtonElement>;
}
/**
 * JSX.Element element represents the dynamic layout to switch between twoColumns <-> oneColumn
 * @prop {boolean} [initSplit = true] - Initial state of layout (2Columns or 1Column)
 * @prop {React.ReactNode} [leftArea] - Tag's children for left column that must be wrapped with MUI Box
 * @prop {React.ReactNode} [rightArea] - Tag's children  for right column that must be wrapped with MUI Box
 * @prop {string} [btnCollapseTxt = "collapse"] - Text for the top right button
 * @prop {string} [btnCollapseIcon ] - icons for the top right button
 * @prop {React.MouseEventHandler<HTMLButtonElement>} [onCancel] - onClick for the cancel button
 * @prop {React.MouseEventHandler<HTMLButtonElement>} [onValidation] - onClick for the save and create button
 * @prop {boolean} [disableValidation = false] - disable validation button
 * @prop {React.MouseEventHandler<HTMLButtonElement>} [onEdit] - onEdit to the picel icon next to the title
 * @see this component use {@link DataLayout} for the left column
 * @example <TwoColumnsLayout title="Add bottle" btnCollapseTxt="Mass distribution" leftArea={<div>Left</div>} rightArea={<div>right</div>} />
 */
const TwoColumnsLayout = (props: TwoColumnsProps) => {
  const {
    initSplit = true,
    leftArea,
    rightArea,
    btnCollapseTxt = "collapse",
    btnCollapseIcon,
    ...restProps
  } = props;
  //* Hooks
  const containerRef = useRef(null);
  const [isSplited, setSplited] = useState<boolean>(initSplit);
  //* Handler
  const handleSplitArea = () => {
    setSplited(!isSplited);
  };
  //* Render
  return (
    <SplitedBoxs splited={isSplited.toString()}>
      <Box className="Left-area">
        <DataLayout
          {...restProps}
          btnTxt={btnCollapseTxt}
          btnIcon={getCollapseIcon(isSplited, btnCollapseIcon)}
          onBtnClick={handleSplitArea}
        >
          {leftArea}
        </DataLayout>
      </Box>
      <Box className="Right-area" ref={containerRef}>
        <Slide
          direction="left"
          appear={false}
          in={isSplited}
          container={containerRef.current}
        >
          <Box height="calc(100vh - 98px)">{rightArea}</Box>
        </Slide>
      </Box>
    </SplitedBoxs>
  );
};

export default TwoColumnsLayout;

//* Functions
const getCollapseIcon = (
  state: boolean,
  icons:
    | {
        iconCollapseLeft: React.ReactNode;
        iconCollapseRight: React.ReactNode;
      }
    | undefined
) => {
  if (icons) return state ? icons.iconCollapseRight : icons.iconCollapseLeft;
  return state ? <ArrowCollapseRight /> : <ArrowCollapseLeft />;
};
