import ProjectImg from "shared/images/welcomeMenu/project_img.png";
import PreformImg from "shared/images/welcomeMenu/preform_img.png";
import bottleImg from "shared/images/welcomeMenu/bottle_img.png";
import ProcessImg from "shared/images/welcomeMenu/process_img.png";
import FBPImg from "shared/images/welcomeMenu/fbp_img.png";
//&<-------- import ----------->
import appRouteConstants from "shared/constants/appRoutes";
import { Box, useTheme } from "@mui/material";
import MenuCard from "shared/components/MenuCard";

const Welcome = () => {
  const { palette } = useTheme();
  return (
    <Box
      display="grid"
      height="80vh"
      gridTemplateRows="1fr"
      justifyContent="center"
    >
      <Box
        width="70vw"
        height="90%"
        display="grid"
        gridTemplateColumns="repeat(3, 1fr)"
        gridTemplateRows="repeat(2, 1fr)"
        gap={2}
        padding={2}
        justifyContent="center"
        alignSelf="center"
      >
        <MenuCard
          path={appRouteConstants.PROJECT.path}
          imagePath={ProjectImg}
          title={appRouteConstants.PROJECT.name}
          hexOverlay={palette.primary.main}
        />
        <MenuCard
          path={appRouteConstants.PREFORM.path}
          imagePath={PreformImg}
          title={appRouteConstants.PREFORM.name}
          hexOverlay={palette.primary.main}
        />
        <MenuCard
          path={appRouteConstants.BOTTLE.path}
          imagePath={bottleImg}
          title={appRouteConstants.BOTTLE.name}
          hexOverlay={palette.primary.main}
        />
        <MenuCard
          path={appRouteConstants.PROCESS.path}
          imagePath={ProcessImg}
          title={appRouteConstants.PROCESS.name}
          hexOverlay={palette.primary.main}
        />
        <Box display="grid" gridColumn="3/4" gridRow="1/3">
          <MenuCard
            path={`/bottle/research#FindBestPreform`}
            imagePath={FBPImg}
            title="Find the best preform"
            hexOverlay={palette.secondary.main}
            startBar="19%"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Welcome;
