import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { massArea } from "shared/constants/constants";

interface MassInfosProps {
  data: { [key: string]: { id: number; [key: string]: number } };
}

/**
 * JSX.Element to display the information about mass distribution
 * @prop {{ [key: string]: object }} data - information about mass distribution for diffirent area
 * @see mainly used for mass distribution dialog into information pages for the objects
 * @example <MassInfos data={infos && infos["Mass distribution"]} />
 */

const MassInfos = (props: MassInfosProps) => {
  let areas = massArea.slice();

  const { data } = props;

  const [withMass, setWithMass] = useState(false);
  const { palette } = useTheme();
  //* Mounted
  useEffect(() => {
    data.massRepartitionFinal ? setWithMass(true) : setWithMass(false);
  }, [data.massRepartitionFinal]);

  //*Comput mass sum
  let massSum = 0;
  if (withMass) {
    let { id, ...res } = data.massRepartitionFinal;
    let massValues = Object?.values(res).map(Number);
    massSum = massValues.reduce(
      (preVal: number, currentVal: number) => preVal + currentVal,
      0
    );
    massSum = parseFloat(massSum.toFixed(2));
  }
  //*Comput target mass sum
  let targetSum = 0;
  let { id, ...res } = data.massRepartitionTarget;
  let targetValues = Object?.values(res).map(Number);

  targetSum = targetValues.reduce(
    (preVal: number, currentVal: number) => preVal + currentVal,
    0
  );
  targetSum = parseFloat(targetSum.toFixed(2));
  //*Rendrer
  return (
    <TableContainer>
      <Table stickyHeader aria-label="mass distribution infos">
        <TableHead>
          <TableRow>
            <TableCell variant="head">
              <Typography variant="subtitle2" align="center">
                Zone
              </Typography>
            </TableCell>
            <TableCell
              variant="head"
              sx={{
                display: " flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="subtitle2" align="center">
                Height (mm)
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                value={data.areaDistribution["ring"]}
                inputProps={{
                  style: { textAlign: "center" },
                }}
                sx={{
                  width: "80px",
                  backgroundColor: palette.grey[100],
                  borderRadius: "100%",
                  transform: "translateY(90%)",
                  "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                }}
              />
            </TableCell>
            {withMass && (
              <TableCell variant="head">
                <Typography variant="subtitle2" align="center">
                  Mass (g)
                </Typography>
              </TableCell>
            )}
            <TableCell variant="head">
              <Typography variant="subtitle2" align="center">
                Target mass (g)
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {areas.map((area, i, array) => (
            <MassInfosItem
              key={i}
              index={i}
              keyName={area.id}
              prevKeyName={array[i + 1] ? array[i + 1].id : undefined}
              data={data}
              area={area.name}
              areaBgImg={area.img}
              withMass={withMass}
            />
          ))}
        </TableBody>
        <TableFooter sx={{ bgcolor: "#d1e4f6" }}>
          <TableRow>
            <TableCell variant="footer"></TableCell>
            <TableCell variant="footer">
              <Typography variant="h6" align="right">
                Total mass
              </Typography>
            </TableCell>
            {withMass && (
              <TableCell align="center" variant="footer">
                <Typography variant="body1">{massSum}</Typography>
              </TableCell>
            )}
            <TableCell align="center" variant="footer">
              <Typography variant="body1">{targetSum}</Typography>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default MassInfos;

//&<--------- SubComponent -------------->*

interface MassInfosItemProps {
  index: number;
  area: string;
  areaBgImg: any;
  data?: any;
  keyName?: any;
  prevKeyName?: any;
  withMass?: boolean;
}
const getBgColor = (i: number) => (i % 2 ? "initial" : "#d1e4f6");
const areaStyle = (areaImg: any) => {
  return {
    backgroundImage: `url(${areaImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
};

const MassInfosItem = (props: MassInfosItemProps) => {
  const { index, area, areaBgImg, data, keyName, prevKeyName, withMass } =
    props;
  const { palette } = useTheme();

  return (
    <TableRow sx={{ backgroundColor: getBgColor(index) }}>
      <TableCell
        align="center"
        width="144px"
        padding="none"
        height="40px"
        sx={{ borderBottom: "none", ...areaStyle(areaBgImg) }}
      ></TableCell>
      <TableCell
        sx={{
          display: "grid",
          alignSelf: "center",
          justifySelf: "center",
          gridTemplateRows: "40px",
        }}
        align="center"
      >
        <Box display="grid" justifyItems="center">
          <Typography
            variant="body2"
            sx={{
              transform: "translateY(40%)",
              fontWeight: "bold",
            }}
          >
            {area}
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            value={
              data.areaDistribution[prevKeyName]
                ? data.areaDistribution[prevKeyName]
                : "0"
            }
            inputProps={{
              style: { textAlign: "center" },
            }}
            sx={{
              width: "80px",
              backgroundColor: palette.grey[100],
              borderRadius: "100%",
              transform: "translateY(40%)",
              "& .MuiOutlinedInput-notchedOutline": { border: "none" },
            }}
          />
        </Box>
      </TableCell>
      {withMass && (
        <TableCell align="center">
          <Typography variant="h6">
            {data.massRepartitionFinal[keyName]}
          </Typography>
        </TableCell>
      )}
      <TableCell align="center">
        <Typography variant="h6">
          {data.massRepartitionTarget[keyName]}
        </Typography>
      </TableCell>
    </TableRow>
  );
};
