import { Routes, Route, Navigate } from "react-router-dom";
import objectRoutes from "screens/App/route";

const Project = () => {
  const projectRoutes = Object.values(objectRoutes.ProjectRoute.routes);
  return (
    <Routes>
      {projectRoutes.map((projectRoute, i) => (
        <Route
          key={i}
          path={projectRoute.path}
          element={<projectRoute.component />}
        ></Route>
      ))}
      <Route
        path="*"
        element={
          <Navigate to={objectRoutes.ProjectRoute.routes.researchRoute.path} />
        }
      />
    </Routes>
  );
};

export default Project;
