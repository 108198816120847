import useSWRImmutable from "swr/immutable";
import { FieldConfigTemplate, NamedObject } from "shared/Utils/utils";
import { GenericForm } from "shared/components";

type FindBestPrefomFormStep1Config = {
  resin: FieldConfigTemplate<"multiSelect">;
  neck: FieldConfigTemplate<"multiSelect">;
};

type FindBestPrefomFormStep1Type = {
  formConfig: FindBestPrefomFormStep1Config;
  formik: any;
  validatioSchema?: any;
};

//&-------Form Step 01 -----------
const FindBestPrefomFormStep1 = ({
  formConfig,
  formik,
  validatioSchema,
}: FindBestPrefomFormStep1Type) => {
  const { data: resin } = useSWRImmutable("/preforms/resins?pageSize=10000");
  const { data: necks } = useSWRImmutable("/necks/?pageSize=10000");

  if (!resin || !necks) {
    <div>currently loading...</div>;
  } else {
    formConfig.resin.parameters.options = resin.results;
    formConfig.neck.parameters.options = necks.results;
  }

  //*Rendrer
  return (
    <GenericForm
      formConfig={formConfig}
      formik={formik}
      validatioSchema={validatioSchema}
    />
  );
};

//&-------Form Step 1.5 -----------
type FindBestPrefomFormSubStepConfig = {
  preforms: FieldConfigTemplate<"multiSelect">;
};

type FindBestPrefomFormSubStepType = {
  formConfig: FindBestPrefomFormSubStepConfig;
  formik: any;
  validatioSchema?: any;
  data: { [key: string]: NamedObject[] };
};

const FindBestPrefomFormSubStep = ({
  formConfig,
  formik,
  validatioSchema,
  data,
}: FindBestPrefomFormSubStepType) => {
  formConfig.preforms.parameters.options = data.preforms;

  //*Rendrer
  return (
    <GenericForm
      formConfig={formConfig}
      formik={formik}
      validatioSchema={validatioSchema}
    />
  );
};

//&-------Form Step 02 -----------
type FindBestPrefomFormStep2Config = {
  projects: FieldConfigTemplate<"multiSelect">;
};

type FindBestPrefomFormStep2Type = {
  formConfig: FindBestPrefomFormStep2Config;
  formik: any;
  validatioSchema?: any;
  data: { [key: string]: NamedObject[] };
};

const FindBestPrefomFormStep2 = ({
  formConfig,
  formik,
  validatioSchema,
  data,
}: FindBestPrefomFormStep2Type) => {
  formConfig.projects.parameters.options = data.projects;
  return (
    <GenericForm
      formConfig={formConfig}
      formik={formik}
      validatioSchema={validatioSchema}
    />
  );
};

export {
  FindBestPrefomFormStep1,
  FindBestPrefomFormSubStep,
  FindBestPrefomFormStep2,
};
