import BottleRoute from "screens/App/views/Bottle/route";
import PreformRoute from "screens/App/views/Preform/route";
import ProjectRoute from "screens/App/views/Project/route";
import ProcessRoute from "screens/App/views/Process/route";
import WelcomeRoute from "screens/App/views/Welcome/route";

const routes = {
  WelcomeRoute,
  ProjectRoute,
  PreformRoute,
  BottleRoute,
  ProcessRoute,
};

export default routes;
