import { BottleFormConfigType } from "screens/App/views/Bottle/components/BottleForm";
import { PreformFormConfigType } from "screens/App/views/Preform/components/PreformForm";
import { ProjectFormConfigType } from "screens/App/views/Project/components/ProjectForm";
import {
  createTextFieldConfig,
  createSelectFieldConfig,
  createFileFieldConfig,
  createCheckbox,
} from "shared/Utils/utils";
import { dispatchFetch } from "shared/components/fetchers";

let bottleForm: BottleFormConfigType;
let preformForm: PreformFormConfigType;
let projectForm: ProjectFormConfigType;
export const getFormConfig = async () => {
  await dispatchFetch("GET")("/fileExtensions").then((data: any) => {
    const stringArray = data.map((ext: string) => `.${ext}`);
    const SUPPORTED_FORMATS = stringArray.join(",");

    projectForm = {
      name: createTextFieldConfig("Name"),
      leader: createSelectFieldConfig("Project leader", 6),
      objective: createTextFieldConfig("Objective", 6, "text", 4),
      iProview: createTextFieldConfig("iProview", 6),
      brief: createFileFieldConfig("Brief", 6, SUPPORTED_FORMATS),
      brand: createSelectFieldConfig("Brand", 6),
      startYear: createTextFieldConfig(
        "Starting year",
        6,
        "number",
        undefined,
        undefined,
        "Project starting year, example: 2022"
      ),
      endYear: createTextFieldConfig(
        "Ending year",
        6,
        "number",
        undefined,
        undefined,
        "Project ending year, example 2023"
      ),
    };

    preformForm = {
      supplier: createSelectFieldConfig("Supplier"),
      refNumber: createTextFieldConfig("Reference number"),
      resin: createSelectFieldConfig("Resin"),
      neck: createSelectFieldConfig("Neck"),
      thickness: createTextFieldConfig(
        "Thickness (mm)",
        6,
        "number",
        undefined,
        undefined,
        "If this field is unknown, a default value will be computed."
      ),
      halfLength: createTextFieldConfig(
        "Half length (mm)",
        6,
        "number",
        undefined,
        undefined,
        "If this field is unknown, a default value will be computed."
      ),
      height: createTextFieldConfig("Height (mm)", 6, "number"),
      bodyDiameter: createTextFieldConfig("Body diameter (mm)", 6, "number"),
      mass: createTextFieldConfig("Mass (g)", 6, "number"),
      bottomTypeShape: createSelectFieldConfig("Injection Machine", 4),
      bottomTypeB: createTextFieldConfig("Cycle duration (ms)", 2, "number"),
      technicalDrawing: createFileFieldConfig(
        "Technical Drawing",
        6,
        SUPPORTED_FORMATS
      ),
    };

    bottleForm = {
      shapeNumber: createTextFieldConfig("Shape number"),
      project: createSelectFieldConfig("Project", 6),
      designAgency: createSelectFieldConfig("Design agency", 6),
      halfLength: createTextFieldConfig(
        "Half length (mm)",
        12,
        "number",
        undefined,
        undefined,
        "If field is unknown, a default value will be computed in Find Best Preform"
      ),
      // isTheoretical: createCheckbox("Tick the box if unknown ", 4),
      format: createTextFieldConfig("Format (cL)", 6, "number"),
      height: createTextFieldConfig("Height (mm)", 6, "number"),
      equivalentDiameter: createTextFieldConfig(
        "Equivalent diameter (mm)",
        6,
        "number"
      ),
      sectionType: createSelectFieldConfig("Section type", 6),
      bottomTypeShape: createSelectFieldConfig("Bottom type", 6),
      ovenTempTarget: createTextFieldConfig(
        "Oven temperature target(°C)",
        6,
        "number"
      ),
      bottomArrow: createTextFieldConfig("Bottom Arrow (mm)", 4, "number"),
      p1Target: createTextFieldConfig("P1 Target", 4, "number"),
      p2Target: createTextFieldConfig("P2 Target", 4, "number"),
      brimfullVolume: createTextFieldConfig(
        "Brimfull volume (cl)",
        6,
        "number"
      ),
      wettedSurface: createTextFieldConfig("Wetted surface (cm2)", 6, "number"),
      technicalDrawing: createFileFieldConfig(
        "Technical Drawing",
        12,
        SUPPORTED_FORMATS
      ),
    };
  });
  return { projectForm, preformForm, bottleForm };
};

export const processFormConfig = {
  operator: createSelectFieldConfig("Operator", 6),
  machineName: createSelectFieldConfig("Machine name", 6),
  ovenTempFinal: createTextFieldConfig(
    "Oven temperature final(°C)",
    12,
    "number"
  ),
  bottle: createSelectFieldConfig("Bottle", 6),
  preform: createSelectFieldConfig("Preform", 6),
  blowingMark: createSelectFieldConfig("Blowing Mark", 5),
  bambouMarguerite: createTextFieldConfig("Bamboo / Marguerite", 5),
  isThreeD: createCheckbox("3D ", 2),
  p1Final: createTextFieldConfig("P1 Final (bar)", 6, "number"),
  p2Final: createTextFieldConfig("P2 Final (bar)", 6, "number"),
  cycleDuration: createTextFieldConfig("Cycle duration (ms)", 6, "number"),
  bottleThickness: createTextFieldConfig(
    "Bottle thickness (µm)",
    6,
    "number",
    undefined,
    undefined,
    "If this field is unknown, a default value will be computed."
  ),
  comments: createTextFieldConfig("Comments", 12, "text", 4),
};
