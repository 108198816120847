import appRouteConstants from "shared/constants/appRoutes";
import Process from ".";

import researchRoute from "./views/Research/route";
import addRoute from "./views/Add/route";
import editRoute from "./views/Edit/route";
import infosRoute from "./views/Informations/route";

const route = {
  path: appRouteConstants.PROCESS.path,
  component: Process,
  routes: {
    researchRoute,
    addRoute,
    editRoute,
    infosRoute,
  },
};

export default route;
