import appRouteConstants from "shared/constants/appRoutes";
import Infos from ".";

import bestPreformRoute from "./views/BestPreform/route";
import mainInfosRoute from "./views/main/route";

const route = {
  path: appRouteConstants.BOTTLE.subroutes.INFOS.path,
  component: Infos,
  routes: {
    mainInfosRoute,
    bestPreformRoute,
  },
};

export default route;
