import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import RootApp from "RootApp";
import { SWRConfig } from "swr";
import { dispatchFetch } from "./shared/components/fetchers";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { globalTheme } from "./shared/theme/globalTheme";
import { SnackbarProvider } from "notistack";
import { Slide, SlideProps } from "@mui/material";

const SlideRight = (props: SlideProps) => <Slide {...props} direction="left" />;

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={globalTheme}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          TransitionComponent={SlideRight}
        >
          <SWRConfig
            value={{
              fetcher: dispatchFetch("GET"),
            }}
          >
            <RootApp />
          </SWRConfig>
        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
