import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// &---------------------------------<< Navigation >>----*
export const useNavigateSubRoute = () => {
  const currentPath = useLocation();
  const navigate = useNavigate();
  const navigateToSubRoute = useCallback(
    (newSubRoute: string, deep: number = 0) => {
      const currentPathArray = currentPath.pathname.split("/");
      for (let i = 0; i < deep; i++) {
        currentPathArray.pop();
      }
      currentPathArray[currentPathArray.length - 1] = newSubRoute;
      const newPath = currentPathArray.join("/");
      navigate(newPath);
    },
    [currentPath.pathname, navigate]
  );
  return navigateToSubRoute;
};
