import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import { GenericForm } from "shared/components";
import {
  FieldConfigTemplate,
  FieldConfig,
  AutoOption,
} from "shared/Utils/utils";
import LoadingSpinner from "shared/components/LoadingSpinner";

export type BottleFormConfigType = {
  bottomTypeShape: FieldConfigTemplate<"select">;
  sectionType: FieldConfigTemplate<"select">;
  project: FieldConfigTemplate<"select">;
  designAgency: FieldConfigTemplate<"select">;
  technicalDrawing: FieldConfigTemplate<"fileField">;
  [key: string]: FieldConfig;
};

type BottleFilterConfigType = {
  bottomTypeShape: FieldConfigTemplate<"select">;
  sectionType: FieldConfigTemplate<"select">;
  project: FieldConfigTemplate<"select">;
  designAgency: FieldConfigTemplate<"select">;
  [key: string]: FieldConfig;
};

type BottleFormType = {
  formConfig: BottleFormConfigType | BottleFilterConfigType;
  formik: any;
  validatioSchema?: any;
};

const BottleForm = ({
  formConfig,
  formik,
  validatioSchema,
}: BottleFormType) => {
  const { data: bottomTypeShapes } = useSWRImmutable(
    "/bottles/bottomTypeShapes"
  );
  const { data: sectionTypes } = useSWRImmutable(
    "/bottles/sectionTypes?pageSize=10000"
  );
  const { data: projects } = useSWR("/projects/?pageSize=10000&order_by=name");
  const { data: designAgencies } = useSWRImmutable(
    "/bottles/designAgencies?pageSize=10000"
  );
  const { data: bottles } = useSWR(`/bottles/?pageSize=10000&order_by=name`);
  let bottlesName: Array<AutoOption> = [];
  bottles?.results.map(
    (result: { name: string }) =>
      (bottlesName = [...bottlesName, { label: result.name }])
  );

  if (!bottomTypeShapes || !sectionTypes || !projects || !designAgencies)
    return <LoadingSpinner />;

  const {
    bottomTypeShape,
    sectionType,
    project,
    designAgency,
    name__icontains,
  } = formConfig;

  project.parameters.options = projects?.results;
  designAgency.parameters.options = designAgencies?.results;
  sectionType.parameters.options = sectionTypes?.results;
  bottomTypeShape.parameters.options = bottomTypeShapes?.results;
  if (name__icontains)
    (
      name__icontains as FieldConfigTemplate<"textField">
    ).parameters.autoOptions = bottlesName;

  return (
    <GenericForm
      formConfig={formConfig}
      formik={formik}
      validatioSchema={validatioSchema}
    />
  );
};

export default BottleForm;
