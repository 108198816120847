import { AppBar, Toolbar, Typography, IconButton, Box, useTheme } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import OptibottleIcon from "shared/theme/OptibottleIcon";
import { useNavigate } from "react-router-dom";
import DRCLogo from "shared/images/global/DRCLogo.png";

interface TopBarProps {
  onMenuToggle: React.MouseEventHandler<HTMLButtonElement>;
  isOpenMenu: boolean;
}

/**
 * JSX.Element element represents the main top bar of the system
 * @prop {React.MouseEventHandler<HTMLButtonElement>} onMenuToggle - event occurs when the user clicks on the menu icon.
 * @prop {boolean} isOpenMenu - menu state (update the left icon)
 * @example <TopBar isOpen={false} onMenuToggle={() => console.log("Toggle")}/>
 */
const TopBar = (props: TopBarProps) => {
  const { onMenuToggle, isOpenMenu } = props;

  const navigate = useNavigate();
  const { palette } = useTheme();

  return (
    <div>
      <AppBar position="fixed" sx={{ backgroundColor: "#fff" }}>
        <Toolbar
          sx={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            gridColumnGap: "20px",
          }}
        >
          <IconButton
            aria-label="objectMenu"
            onClick={onMenuToggle}
            color="primary"
            edge="start"
          >
            {isOpenMenu ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
          <Box
            display="grid"
            gridTemplateColumns="auto auto 1fr"
            gap={1}
            alignItems="center"
          >
            <OptibottleIcon
              sx={{ width: "15px", height: "40px", cursor: "pointer" }}
              inheritViewBox={true}
              onClick={() => {
                navigate("");
              }}
            />
            <Typography
              variant="h5"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate("");
              }}
              color={palette.primary.main}
            >
              Optibottle
            </Typography>
            <Box
              justifySelf="end"
              bgcolor="red"
              width="140px"
              height="50px"
              sx={{
                backgroundImage: `url(${DRCLogo})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
};

export default TopBar;
