import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import { GenericForm } from "shared/components";
import {
  FieldConfigTemplate,
  FieldConfig,
  AutoOption,
} from "shared/Utils/utils";
import LoadingSpinner from "shared/components/LoadingSpinner";

type ProcessFormConfig = {
  operator: FieldConfigTemplate<"select">;
  blowingMark: FieldConfigTemplate<"select">;
  machineName: FieldConfigTemplate<"select">;
  bottle: FieldConfigTemplate<"select">;
  preform: FieldConfigTemplate<"select">;
  [key: string]: FieldConfig;
};

type ProcessFormType = {
  formConfig: ProcessFormConfig;
  formik: any;
  validatioSchema?: any;
};

const ProcessForm = ({
  formConfig,
  formik,
  validatioSchema,
}: ProcessFormType) => {
  const { data: operators } = useSWRImmutable(
    "/processes/operators?pageSize=10000"
  );
  const { data: blowingMarks } = useSWRImmutable(
    "/processes/blowingMarks?pageSize=10000"
  );
  const { data: machineNames } = useSWRImmutable(
    "/processes/machineNames?pageSize=10000"
  );
  const { data: bottles } = useSWR("/bottles/?pageSize=10000&order_by=name");
  const { data: preforms } = useSWR("/preforms/?pageSize=10000&order_by=name");
  const { data: process } = useSWR(`/processes/?pageSize=10000&order_by=name`);
  let processName: Array<AutoOption> = [];
  process?.results.map(
    (result: { name: string }) =>
      (processName = [...processName, { label: result.name }])
  );

  if (!operators || !blowingMarks || !machineNames || !bottles || !preforms)
    return <LoadingSpinner />;

  const {
    operator,
    blowingMark,
    machineName,
    bottle,
    preform,
    name__icontains,
  } = formConfig;

  operator.parameters.options = operators?.results;
  bottle.parameters.options = bottles?.results;
  preform.parameters.options = preforms?.results;
  blowingMark.parameters.options = blowingMarks?.results;
  machineName.parameters.options = machineNames?.results;
  if (name__icontains)
    (
      name__icontains as FieldConfigTemplate<"textField">
    ).parameters.autoOptions = processName;

  return (
    <GenericForm
      formConfig={formConfig}
      formik={formik}
      validatioSchema={validatioSchema}
    />
  );
};

export default ProcessForm;
