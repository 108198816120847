import {
  createTextFieldConfig,
  createSelectFieldConfig,
  createCheckbox,
  createMultiSelectFieldConfig,
} from "shared/Utils/utils";
export const projectFiltersConfig = {
  name__icontains: createTextFieldConfig("Name"),
  leader: createSelectFieldConfig("Project leader"),
  iProview__icontains: createTextFieldConfig("iProview"),
  brand: createSelectFieldConfig("Brand"),
  startYear__gte: createTextFieldConfig("Start year from", 8, "number"),
  startYear__lte: createTextFieldConfig("To", 4, "number"),
  endYear__gte: createTextFieldConfig("End year from", 8, "number"),
  endYear__lte: createTextFieldConfig("To", 4, "number"),
  hasBrief: createCheckbox("Has brief"),
};
export const bottleFiltersConfig = {
  name__icontains: createTextFieldConfig("Name"),
  shapeNumber__icontains: createTextFieldConfig("Shape number"),
  project: createSelectFieldConfig("Project"),
  designAgency: createSelectFieldConfig("Design agency"),
  sectionType: createSelectFieldConfig("Section type"),
  bottomTypeShape: createSelectFieldConfig("Bottom type"),
  format__gte: createTextFieldConfig("Format (cL) from", 8, "number"),
  format__lte: createTextFieldConfig("To", 4, "number"),
  halfLength__gte: createTextFieldConfig("Half length (mm) from", 8, "number"),
  halfLength__lte: createTextFieldConfig("To", 4, "number"),
  height__gte: createTextFieldConfig("Height (mm) from", 8, "number"),
  height__lte: createTextFieldConfig("To", 4, "number"),
  bottomArrow__gte: createTextFieldConfig(
    "Bottom Arrow (mm) from",
    8,
    "number"
  ),
  bottomArrow__lte: createTextFieldConfig("To", 4, "number"),
  equivalentDiameter__gte: createTextFieldConfig(
    "Equivalent diameter (mm) from",
    8,
    "number"
  ),
  equivalentDiameter__lte: createTextFieldConfig("To", 4, "number"),
  brimfullVolume__gte: createTextFieldConfig("Brimfull volume (cl) from", 8),
  brimfullVolume__lte: createTextFieldConfig("To", 4),
  wettedSurface__gte: createTextFieldConfig("Wetted surface (cm2) from", 8),
  wettedSurface__lte: createTextFieldConfig("To", 4),
};
export const preformFiltersConfig = {
  name__icontains: createTextFieldConfig("Name"),
  supplier: createSelectFieldConfig("Supplier"),
  refNumber__icontains: createTextFieldConfig("Reference number"),
  resin: createSelectFieldConfig("Resin"),
  neck: createSelectFieldConfig("Neck"),
  thickness__gte: createTextFieldConfig("Thickness (mm) from", 8, "number"),
  thickness__lte: createTextFieldConfig("To", 4, "number"),
  halfLength__gte: createTextFieldConfig("Half length (mm) from", 8, "number"),
  halfLength__lte: createTextFieldConfig("To", 4, "number"),
  height__gte: createTextFieldConfig("Height (mm) from", 8, "number"),
  height__lte: createTextFieldConfig("To", 4, "number"),
  bodyDiameter__gte: createTextFieldConfig(
    "Body diameter (mm) from",
    8,
    "number"
  ),
  equivalentDiameter__lte: createTextFieldConfig("To", 4, "number"),
  mass__gte: createTextFieldConfig("Mass (g) from", 8, "number"),
  mass__lte: createTextFieldConfig("To", 4, "number"),
  bottomTypeShape: createSelectFieldConfig("Injection Machine", 12),
};

export const findBestPreformFilterStep1Config = {
  mass__gte: createTextFieldConfig("Mass (g) from", 8, "number"),
  mass__lte: createTextFieldConfig("To", 4, "number"),
  neck: createMultiSelectFieldConfig("Neck finish"),
  resin: createMultiSelectFieldConfig("Material recipe"),
};

export const findBestPreformFilterSubStepConfig = {
  preforms: createMultiSelectFieldConfig("List of preforms to rank"),
};

export const findBestPreformFilterStep2Config = {
  bottleFormatMin: createTextFieldConfig("Bottle size (cl) from", 8, "number"),
  bottleFormatMax: createTextFieldConfig("To", 4, "number"),
  projects: createMultiSelectFieldConfig("Projects"),
};

export const processFiltersConfig = {
  name__icontains: createTextFieldConfig("Name"),
  operator: createSelectFieldConfig("Operator"),
  machineName: createSelectFieldConfig("Machine name"),
  extPreformTempFinal__gte: createTextFieldConfig(
    "Ext preform temperature final(°C) from",
    8,
    "number"
  ),
  extPreformTempFinal__lte: createTextFieldConfig("To", 4, "number"),
  bottle: createSelectFieldConfig("Bottle"),
  preform: createSelectFieldConfig("Preform"),
  blowingMark: createSelectFieldConfig("blowingMark"),
  p1Final__gte: createTextFieldConfig("P1 Final (bar) from", 8, "number"),
  p1Final__lte: createTextFieldConfig("To", 4, "number"),
  p2Final__gte: createTextFieldConfig("P2 Final (bar) from", 8, "number"),
  p2Final__lte: createTextFieldConfig("To", 4, "number"),
  bottleThickness__gte: createTextFieldConfig(
    "Bottle real thickness (µm) from",
    8,
    "number"
  ),
  bottleThickness__lte: createTextFieldConfig("To", 4, "number"),
  cycleDuration__gte: createTextFieldConfig(
    "Cycle duration (ms) from",
    8,
    "number"
  ),
  cycleDuration__lte: createTextFieldConfig("To", 4, "number"),
  bambouMarguerite__icontains: createTextFieldConfig("Bamboo / Marguerite"),
};
