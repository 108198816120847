import { DataLayout, MassTable, TwoColumnsLayout } from "shared/components";
import { useNavigate, useParams } from "react-router-dom";
import { contentType } from "shared/constants/constants";
import { DataLoading } from "shared/components";
import { FormikHelpers, useFormik } from "formik";
import { processValidationSchema } from "shared/constants/validationSchemas";
import { processFormConfig } from "shared/constants/formConfigs";
import ProcessForm from "screens/App/views/Process/components/ProcessForm";
import { useEffect, useState } from "react";
import { dispatchFetch, dispatchProcessFile } from "shared/components/fetchers";
import { useNavigateSubRoute } from "shared/hooks";
import { useSnackbar } from "notistack";
import { ContentSaveSettingsOutline } from "mdi-material-ui";
import {
  appendObjectToFormData,
  createFormData,
  getErrorMessage,
  updateObjectValue,
} from "shared/Utils/utils";
import AddFiles from "shared/components/AddFiles";

const Edit = () => {
  const [files, setFiles] = useState<any[]>([]);
  const [deletedFilesIDs, setDeletedFilesIDs] = useState<string[]>([]);
  const [isFilesUpdated, setFilesUpdated] = useState<boolean>(false);
  const [processInfo, setProcessInfo] = useState<{ [key: string]: any }>({});
  const [processData, setProcessData] = useState<{ [key: string]: any }>({});
  const [error, setError] = useState();
  const [loading, setLoading] = useState<boolean>(false);

  const param = useParams();
  const navigate = useNavigate();
  const navigateSubRoute = useNavigateSubRoute();

  const { enqueueSnackbar } = useSnackbar();

  //*Mounted
  useEffect(() => {
    dispatchFetch("GET")(`/processes/${param.id}`)
      .then((data: any) => {
        setProcessData(data);
      })
      .catch(function (error: any) {
        setError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //*Handler
  const handleEditObject = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    let url = `/processes/${param.id}`;

    let formData = createFormData(values, processFormConfig);

    appendObjectToFormData(
      values.massRepartitionFinal,
      formData,
      "massRepartitionFinal"
    );

    setLoading(true);
    try {
      await dispatchFetch("PUT")(url, formData, contentType.json)
        .then((data: any) => {
          if (data) {
            enqueueSnackbar(`The process was edited with success`, {
              variant: "success",
            });

            //* Add new files
            if (files.length) {
              let cpt = 0;
              files.forEach((file: any, id, files) => {
                let formData = new FormData();
                formData.append("file", file, file.name);
                formData.append("name", file.name);
                formData.append("process", data.id);

                dispatchProcessFile(
                  "POST",
                  "/processes/additionalFiles/",
                  formData
                )
                  .then((data: any) => {
                    cpt = cpt + 1;
                    if (cpt === files.length) {
                      navigateSubRoute(`infos/${data.process}`, 1);
                    }
                  })
                  .catch((error) => {
                    let errorMessage = getErrorMessage(error);
                    enqueueSnackbar(`Adding file error: ${errorMessage}`, {
                      variant: "error",
                    });
                  });
              });
            }
            //* Delete old files
            if (deletedFilesIDs.length) {
              deletedFilesIDs.forEach((id: any) => {
                dispatchFetch("DELETE")(`/processes/additionalFiles/${id}`)
                  .then(() => {
                    navigateSubRoute(`infos/${data.id}`, 1);
                  })
                  .catch((error: any) => {
                    let errorMessage = getErrorMessage(error);
                    enqueueSnackbar(`Deleting file error: ${errorMessage}`, {
                      variant: "error",
                    });
                  });
              });
            }
            //* redirect
            if (!files.length && !deletedFilesIDs.length) {
              navigateSubRoute(`infos/${data.id}`, 1);
            }
          }
        })
        .catch((error: any) => {
          let errorMessage = getErrorMessage(error);
          enqueueSnackbar(errorMessage, {
            variant: "error",
          });
        });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = (filesList: any, deleteFiles: string[]) => {
    setFilesUpdated(deleteFiles.length || filesList.length);
    setFiles(filesList);
    setDeletedFilesIDs(deleteFiles);
  };

  //*Formik
  updateObjectValue(processInfo, null, "");

  const formi = useFormik({
    enableReinitialize: true,
    initialValues: processInfo,
    validationSchema: processValidationSchema,
    onSubmit: handleEditObject,
  });

  //*Watchers
  useEffect(() => {
    if (processData && Object.keys(processData).length > 0) {
      dispatchFetch("GET")(`/bottles/${processData.bottle.id}`).then(
        (data: any) => {
          setProcessInfo({
            areaDistribution: { ...data.areaDistribution },
            massRepartitionTarget: { ...data.massRepartitionTarget },
            ...processData,
            bottle: processData.bottle.id,
            preform: processData.preform.id,
            blowingMark: processData.blowingMark.id,
            machineName: processData.machineName.id,
            operator: processData.operator?.id,
          });
        }
      );
    }
  }, [processData]);

  useEffect(() => {
    if (formi.values.bottle) {
      let bottleID = formi.values.bottle;
      dispatchFetch("GET")(`/bottles/${bottleID}`)
        .then((data: any) => {
          formi.setFieldValue(
            "massRepartitionTarget",
            data.massRepartitionTarget
          );
          formi.setFieldValue("areaDistribution", data.areaDistribution);
        })
        .catch(function (error: any) {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formi.values.bottle]);
  //*Render
  return (
    <>
      <TwoColumnsLayout
        title={`Edit process`}
        variant="edit"
        btnCollapseTxt="Mass distribution"
        validationBtnIcon={<ContentSaveSettingsOutline />}
        onValidation={() => {
          formi.handleSubmit();
        }}
        onEnter={formi.handleSubmit}
        disableValidation={!formi.isValid || !(formi.dirty || isFilesUpdated)}
        onCancel={() => navigate(-1)}
        isLoading={loading}
        leftArea={
          <>
            <DataLoading data={processInfo} error={error}>
              <ProcessForm
                formConfig={processFormConfig}
                formik={formi}
                validatioSchema={processValidationSchema}
              />
            </DataLoading>
            <AddFiles
              initAddFiles={processInfo.additionalFiles}
              onUpdate={handleUpdate}
            />
          </>
        }
        rightArea={
          <DataLayout variant="display" title="Mass distribution">
            <DataLoading
              data={processInfo && formi.values.areaDistribution}
              error={error}
            >
              <MassTable formik={formi} withMass />
            </DataLoading>
          </DataLayout>
        }
      />
    </>
  );
};

export default Edit;
