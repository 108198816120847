import {
  DataLayout,
  DataLoading,
  MassTable,
  TwoColumnsLayout,
} from "shared/components";
import ProcessForm from "screens/App/views/Process/components/ProcessForm";
import { processFormConfig } from "shared/constants/formConfigs";
import { FormikHelpers, useFormik } from "formik";
import { contentType, massTableInitValues } from "shared/constants/constants";
import { dispatchFetch, dispatchProcessFile } from "shared/components/fetchers";
import { processValidationSchema } from "shared/constants/validationSchemas";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigateSubRoute } from "shared/hooks";
import { useSnackbar } from "notistack";
import {
  createFormData,
  appendObjectToFormData,
  getErrorMessage,
  updateObjectValue,
  isEmptyObject,
} from "shared/Utils/utils";
import AddFiles from "shared/components/AddFiles";

const Add = () => {
  const navigate = useNavigate();
  const navigateSubRoute = useNavigateSubRoute();
  const { enqueueSnackbar } = useSnackbar();
  const [additionalFiles, setAdditionalFiles] = useState<any[]>([]);
  const [copyId, setCopyId] = useState<string>("");
  const [processInfo, setProcessInfo] = useState<{ [key: string]: any }>({});
  const [processData, setProcessData] = useState<{ [key: string]: any }>({});
  const [error, setError] = useState();
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();

  //*Mounted
  useEffect(() => {
    setCopyId(location.hash.replace(/#/g, ""));
  }, [location.hash]);

  //*Watcher
  useEffect(() => {
    if (copyId) {
      dispatchFetch("GET")(`/processes/${copyId}`)
        .then((data: any) => {
          setProcessData(data);
        })
        .catch(function (error: any) {
          setError(error);
        });
    }
  }, [copyId]);

  useEffect(() => {
    if (processData && Object.keys(processData).length > 0) {
      dispatchFetch("GET")(`/bottles/${processData.bottle.id}`).then(
        (data: any) => {
          setProcessInfo({
            areaDistribution: { ...data.areaDistribution },
            massRepartitionTarget: { ...data.massRepartitionTarget },
            ...processData,
            bottle: processData.bottle.id,
            preform: processData.preform.id,
            blowingMark: processData.blowingMark.id,
            machineName: processData.machineName.id,
            operator: processData.operator?.id,
          });
        }
      );
    }
  }, [processData]);

  //*Handler
  const handleAddObject = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    let url = "/processes/";
    let formData = createFormData(values, processFormConfig);
    appendObjectToFormData(
      values.massRepartitionFinal,
      formData,
      "massRepartitionFinal"
    );

    setLoading(true);
    try {
      await dispatchFetch("POST")(url, formData, contentType.json)
        .then((data: any) => {
          if (data) {
            enqueueSnackbar(`The process ${data.name} added with success`, {
              variant: "success",
            });

            if (additionalFiles.length) {
              additionalFiles.forEach((file: any) => {
                let formData = new FormData();
                formData.append("file", file, file.name);
                formData.append("name", file.name);
                formData.append("process", data.id);

                dispatchProcessFile(
                  "POST",
                  "/processes/additionalFiles/",
                  formData
                )
                  .then((data: any) => {
                    navigateSubRoute(``);
                  })
                  .catch((error) => {
                    let errorMessage = getErrorMessage(error);
                    enqueueSnackbar(`Adding file error: ${errorMessage}`, {
                      variant: "error",
                    });
                  });
              });
            } else {
              navigateSubRoute(``);
            }
          }
        })
        .catch((error: any) => {
          let errorMessage = getErrorMessage(error);
          enqueueSnackbar(errorMessage, {
            variant: "error",
          });
        });
    } finally {
      setLoading(false);
    }
  };

  //*Formik
  updateObjectValue(processInfo, null, "");

  const formi = useFormik({
    enableReinitialize: true,
    initialValues: !isEmptyObject(processInfo)
      ? processInfo
      : { bottle: null, ...massTableInitValues },
    validationSchema: processValidationSchema,
    onSubmit: handleAddObject,
  });
  //*Watcher
  useEffect(() => {
    if (formi.values.bottle) {
      dispatchFetch("GET")(`/bottles/${formi.values.bottle}`)
        .then((data: any) => {
          formi.setFieldValue(
            "massRepartitionTarget",
            data.massRepartitionTarget
          );
          formi.setFieldValue("areaDistribution", data.areaDistribution);
        })
        .catch(function (error: any) {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formi.values.bottle]);

  //*Handler
  const handleUpdate = (filesList: any) => {
    setAdditionalFiles(filesList);
  };
  //*Render
  return (
    <>
      <TwoColumnsLayout
        title={
          !isEmptyObject(processInfo)
            ? `Copy process ${processInfo.name}`
            : "Add process"
        }
        variant="add"
        btnCollapseTxt="Mass distribution"
        onValidation={() => {
          formi.handleSubmit();
        }}
        onEnter={formi.handleSubmit}
        disableValidation={!formi.isValid || !formi.dirty}
        onCancel={() => navigate(-1)}
        isLoading={loading}
        leftArea={
          <>
            <ProcessForm
              formConfig={processFormConfig}
              formik={formi}
              validatioSchema={processValidationSchema}
            />
            <AddFiles onUpdate={handleUpdate} />
          </>
        }
        rightArea={
          <DataLayout variant="display" title="Mass distribution">
            <DataLoading
              data={processInfo && formi.values.areaDistribution}
              error={error}
            >
              <MassTable formik={formi} withMass />
            </DataLoading>
          </DataLayout>
        }
      />
    </>
  );
};

export default Add;
