import axios from "axios";
import { contentType } from "shared/constants/constants";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const refreshToken = () => {
  //ToDo => gets new access token
  // axios.defaults.headers.post['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
  // axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
};

//* Request interceptors for API calls
axios.interceptors.request.use(
  function (config) {
    // config.headers!.Authorization = '4'
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

//* Response interceptor for API calls
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 403) {
      refreshToken();
    } else if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log("Error", error.message);
    }
    return Promise.reject(error);
  }
);

axios.defaults.baseURL = baseURL;
export const dispatchFetch = (
  method: "GETRes" |"GET" | "POST" | "PUT" | "DELETE"
): ((url: string, values?: {}, options?: {}) => any) => {
  switch (method) {
    case "POST":
      return (url: string, values: any, options) =>
        axios.post(url, values, options).then((res) => res.data);
    case "PUT":
      return (url: string, values: any, options) =>
        axios.put(url, values, options).then((res) => res.data);
    case "DELETE":
      return (url: string) => axios.delete(url).then((res) => res);
    case "GET":
      return (url: string) => axios.get(url).then((res) => res.data);
    case "GETRes":
      return (url: string) => axios.get(url).then((res) => res);
  }
};

export const dispatchProcessFile = async (
  method: "GET" | "POST" | "PUT" | "DELETE",
  url: string,
  formData: any
) => {
  let isSuccess: boolean = false;
  let result: any = undefined;
  await dispatchFetch(method)(url, formData, contentType.formData)
    .then((data: any) => {
      isSuccess = true;
      result = data;
    })
    .catch((error: any) => {
      isSuccess = false;
      result = error;
    });

  return isSuccess ? Promise.resolve(result) : Promise.reject(result);
};
