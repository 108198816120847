import { Navigate, Route, Routes } from "react-router-dom";
import objectRoutes from "screens/App/route";

const Preform = () => {
  const preformRoutes = Object.values(objectRoutes.PreformRoute.routes);
  return (
    <Routes>
      {preformRoutes.map((preformRoute, i) => (
        <Route
          key={i}
          path={preformRoute.path}
          element={<preformRoute.component />}
        ></Route>
      ))}
      <Route
        path="*"
        element={
          <Navigate to={objectRoutes.PreformRoute.routes.researchRoute.path} />
        }
      />
    </Routes>
  );
};

export default Preform;
