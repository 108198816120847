import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import App from "screens/App";
import Login from "screens/Login";
import { CashProps } from "shared/Utils/utils";
import { dispatchFetch } from "shared/components/fetchers";
import { cashDataInit } from "shared/constants/constants";

export const AccessContext = createContext<any>({});
export const CashContext = createContext<any>({});

const RootApp = () => {
  const [access, setAccess] = useState(true);
  const [userId, setUserId] = useState(undefined);
  const [cashData, setCashData] = useState<CashProps>(cashDataInit);

  //*Handler
  const toggleAccess = useCallback(() => {
    dispatchFetch("GET")("/hasaccess")
      .then((data: any) => {
        setAccess(data.isAuthenticated);
        setUserId(data.userId);
      })
      .catch((error: any) => {
        setAccess(false);
      });
  }, []);

  const updateCash = useCallback((newData: CashProps) => {
    setCashData((data) => ({ ...data, ...newData }));
  }, []);

  //*Mounted
  useEffect(() => {
    toggleAccess();
  }, [toggleAccess]);

  //*ContextValue
  const value = useMemo(() => {
    return {
      user: userId,
      hasAccess: access,
      toggleAccess,
    };
  }, [userId, access, toggleAccess]);

  const cashValue = useMemo(() => {
    return {
      cashData: cashData,
      updateCash,
    };
  }, [cashData, updateCash]);
  //*Rendrer
  return (
    <CashContext.Provider value={cashValue}>
      <AccessContext.Provider value={value}>
        {value.hasAccess ? <App /> : <Login />}
      </AccessContext.Provider>
    </CashContext.Provider>
  );
};

export default RootApp;
