import { DataLayout, MassTable, TwoColumnsLayout } from "shared/components";
import { getFormConfig } from "shared/constants/formConfigs";
import BottleForm from "screens/App/views/Bottle/components/BottleForm";
import useSWR from "swr";
import { DataLoading } from "shared/components";
import { useNavigate, useParams } from "react-router-dom";
import { FormikHelpers, useFormik } from "formik";
import { contentType, massTableInitValues } from "shared/constants/constants";
import { dispatchFetch } from "shared/components/fetchers";
import { bottleValidationSchema } from "shared/constants/validationSchemas";
import { useNavigateSubRoute } from "shared/hooks";
import { useSnackbar } from "notistack";
import {
  appendObjectToFormData,
  createFormData,
  getErrorMessage,
  updateObjectValue,
} from "shared/Utils/utils";
import { useEffect, useState } from "react";
import { ContentSaveSettingsOutline } from "mdi-material-ui";
import LoadingSpinner from "shared/components/LoadingSpinner";

const Edit = () => {
  const param = useParams();
  const navigate = useNavigate();
  const navigateSubRoute = useNavigateSubRoute();
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [bottleFormConfig, setBottleFormConfig] = useState();

  //*Get Init
  const { data, error } = useSWR(`/bottles/${param.id}`);

  updateObjectValue(data, null, "");

  useEffect(() => {
    if (data) {
      getFormConfig().then((config: any) => {
        config.bottleForm.technicalDrawing.parameters.initialValue =
          data?.technicalDrawing;
        setBottleFormConfig(config.bottleForm);
      });
    }
  }, [data]);
  //*Handler
  const handleEditObject = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    let url = `/bottles/${param.id}`;
    let formData = createFormData(values, bottleFormConfig);

    appendObjectToFormData(
      values.areaDistribution,
      formData,
      "areaDistribution"
    );
    appendObjectToFormData(
      values.massRepartitionTarget,
      formData,
      "massRepartitionTarget"
    );

    setLoading(true);
    try {
      await dispatchFetch("PUT")(url, formData, contentType.formData)
        .then((data: any) => {
          if (data) {
            enqueueSnackbar(`The bottle was edited with success`, {
              variant: "success",
            });
            navigateSubRoute(`infos/${data.id}`, 1);
          }
        })
        .catch((error: any) => {
          let errorMessage = getErrorMessage(error);
          enqueueSnackbar(errorMessage, {
            variant: "error",
          });
        });
    } finally {
      setLoading(false);
    }
  };
  //*Formik
  const { massRepartitionFinal } = massTableInitValues;
  const formi = useFormik({
    enableReinitialize: true,
    initialValues: data
      ? {
          massRepartitionFinal,
          ...data,
          project: data.project.id,
          bottomTypeShape: data.bottomTypeShape.id,
          designAgency: data.designAgency.id,
          sectionType: data.sectionType.id,
        }
      : massTableInitValues,
    validationSchema: bottleValidationSchema,
    onSubmit: handleEditObject,
  });
  //*Watcher
  useEffect(() => {
    formi.setFieldValue("areaDistribution.ring", formi.values.height);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formi.values.height]);

  //*Render
  return (
    <TwoColumnsLayout
      title={`Edit bottle`}
      variant="edit"
      btnCollapseTxt="Mass distribution"
      validationBtnIcon={<ContentSaveSettingsOutline />}
      onValidation={() => {
        formi.handleSubmit();
      }}
      onEnter={formi.handleSubmit}
      disableValidation={!formi.isValid || !formi.dirty}
      onCancel={() => navigate(-1)}
      isLoading={loading}
      leftArea={
        <DataLoading data={data} error={error}>
          {bottleFormConfig ? (
            <BottleForm
              formConfig={bottleFormConfig}
              formik={formi}
              validatioSchema={bottleValidationSchema}
            />
          ) : (
            <LoadingSpinner />
          )}
        </DataLoading>
      }
      rightArea={
        <DataLayout variant="display" title="Mass distribution">
          <DataLoading data={data} error={error}>
            <MassTable formik={formi} />
          </DataLoading>
        </DataLayout>
      }
    />
  );
};

export default Edit;
