import * as yup from "yup";
import { dispatchFetch } from "shared/components/fetchers";

const FILE_SIZE = 104857600; //1Mo = 1048576 octets;

const massTableValidationSchema = {
  areaDistribution: yup.object().shape({
    bottom: yup
      .number()
      .required()
      .moreThan(0, "Number must be greater than 0"),
    body: yup
      .number()
      .required()
      .moreThan(0, "Number must be greater than 0")
      .test(
        "greater then previous",
        "Height should be greater then previous area",
        (value, context) => (value ? value >= context.parent.bottom : true)
      ),
    labelArea: yup
      .number()
      .required()
      .moreThan(0, "Number must be greater than 0")
      .test(
        "greater then previous",
        "Height should be greater then previous area",
        (value, context) => (value ? value >= context.parent.body : true)
      ),
    shoulder: yup
      .number()
      .required()
      .moreThan(0, "Number must be greater than 0")
      .test(
        "greater then previous",
        "Height should be greater then previous area",
        (value, context) => (value ? value >= context.parent.labelArea : true)
      ),
    subring: yup
      .number()
      .required()
      .moreThan(0, "Number must be greater than 0")
      .test(
        "greater then previous",
        "Height should be greater then previous area",
        (value, context) => (value ? value >= context.parent.shoulder : true)
      ),
    ring: yup.number().required().moreThan(0, "Number must be greater than 0"),
  }),
  //Todo we need to manage dynamic required for bottolle vs process
  massRepartitionFinal: yup.object().shape({
    bottom: yup.number().required().min(0),
    body: yup.number().required().min(0),
    labelArea: yup.number().required().min(0),
    shoulder: yup.number().required().min(0),
    subring: yup.number().required().min(0),
    ring: yup.number().required().min(0),
  }),

  massRepartitionTarget: yup.object({
    bottom: yup.number().required().min(0),
    body: yup.number().required().min(0),
    labelArea: yup.number().required().min(0),
    shoulder: yup.number().required().min(0),
    subring: yup.number().required().min(0),
    ring: yup.number().required().min(0),
  }),
};

export const targetFBP = yup.object({
  param: yup.object().shape({
    min: yup.number().min(0),
    max: yup.number().min(0),
  }),
});

let projectValidationSchema: yup.SchemaOf<any>;
let bottleValidationSchema: yup.SchemaOf<any>;
let preformValidationSchema: yup.SchemaOf<any>;

dispatchFetch("GET")("/fileExtensions").then((data: any) => {
  const SUPPORTED_FORMATS = data;

  projectValidationSchema = yup.object({
    name: yup.string().max(700).required("A name is required"),
    leader: yup.string().max(700).required(),
    objective: yup.string().max(700),
    iProview: yup.string(),
    brief: yup
      .mixed()
      .test(
        "fileSize",
        "File too large : maximum size is 100Mo",
        (value: any) => (value && value.size ? value.size <= FILE_SIZE : true)
      )
      .test(
        "fileFormat",
        `Supported format ${SUPPORTED_FORMATS}`,
        (value: any) =>
          value && value.name
            ? SUPPORTED_FORMATS.includes(
                value.name.split(".").pop().toLowerCase()
              )
            : true
      ),
    brand: yup.string(),
    startYear: yup
      .number()
      .nullable(true)
      .min(1999)
      .max(new Date().getFullYear() + 1),
    endYear: yup
      .number()
      .nullable(true)
      .min(1999)
      .max(new Date().getFullYear() + 50),
  });

  preformValidationSchema = yup.object({
    supplier: yup.string().max(700).required(),
    refNumber: yup.string().max(700).required(),
    resin: yup.string().max(700).required(),
    neck: yup.string().max(700).required(),
    thickness: yup.number().min(0.0).nullable(true),
    halfLength: yup.number().min(0.0).nullable(true),
    height: yup.number().min(0.0).required(),
    bodyDiameter: yup.number().min(0.0).required(),
    mass: yup.number().min(0.0).required(),
    bottomTypeShape: yup.string().max(700),
    bottomTypeB: yup.number().min(0.0).nullable(true),
    technicalDrawing: yup
      .mixed()
      .test(
        "fileSize",
        "File too large : maximum size is 100Mo",
        (value: any) => (value && value.size ? value.size <= FILE_SIZE : true)
      )
      .test(
        "fileFormat",
        `Supported format ${SUPPORTED_FORMATS}`,
        (value: any) =>
          value && value.name
            ? SUPPORTED_FORMATS.includes(
                value.name.split(".").pop().toLowerCase()
              )
            : true
      ),
  });

  bottleValidationSchema = yup.object({
    shapeNumber: yup.string().max(700).required(),
    project: yup.string().max(700).required(),
    format: yup.number().integer().min(0.0).required(),
    designAgency: yup.string().max(700),
    halfLength: yup.number().min(0.0).nullable(true),
    isTheoretical: yup.boolean(),
    height: yup
      .number()
      .moreThan(0, "Height must be greater than 0 and top subring")
      .required()
      .test(
        "greater then previous",
        "Height should be greater then top subring",
        (value, context) =>
          value ? value >= context.parent.areaDistribution.subring : true
      ),
    equivalentDiameter: yup.number().min(0.0).required(),
    bottomTypeShape: yup.string().max(700),
    bottomArrow: yup.number().min(0.0),
    sectionType: yup.string().max(700).required(),
    ovenTempTarget: yup.number().min(0.0),
    p1Target: yup.number().min(0.0),
    p2Target: yup.number().min(0.0),
    technicalDrawing: yup
      .mixed()
      .test(
        "fileSize",
        "File too large : maximum size is 100Mo",
        (value: any) => (value && value.size ? value.size <= FILE_SIZE : true)
      )
      .test(
        "fileFormat",
        `Supported format ${SUPPORTED_FORMATS}`,
        (value: any) =>
          value && value.name
            ? SUPPORTED_FORMATS.includes(
                value.name.split(".").pop().toLowerCase()
              )
            : true
      ),
    brimfullVolume: yup.number().min(0.0),
    wettedSurface: yup.number().min(0.0),
    //*
    ...massTableValidationSchema,
  });
});

export {
  projectValidationSchema,
  bottleValidationSchema,
  preformValidationSchema,
};

export const processValidationSchema = yup.object({
  operator: yup.string().max(700),
  machineName: yup.string().required().max(700),
  ovenTempFinal: yup.number().min(0.0),
  bottle: yup.string().max(700).required(),
  preform: yup.string().max(700).required(),
  p1Final: yup.number().min(0.0),
  p2Final: yup.number().min(0.0),
  bottleThickness: yup.number().min(0.0),
  cycleDuration: yup.number().min(0.0),
  bambouMarguerite: yup.string().max(700),
  isThreeD: yup.boolean(),
  blowingMark: yup.string().required(),
  comments: yup.string().max(700),
});
