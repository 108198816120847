import { createSvgIcon } from "@mui/material";

const PreformIcon = createSvgIcon(
  <>
    <path d="M9.50005 17.4999C9.50005 18.3931 9.97664 19.2185 10.7498 19.6651C11.5234 20.1116 12.4766 20.1116 13.2502 19.6651C14.0238 19.2185 14.4999 18.3931 14.4999 17.4999V7.50013H15.5003C15.7763 7.50013 16 7.27641 16 6.99999C16 6.72356 15.7763 6.50027 15.5003 6.50027H14.0851L14.4999 4H9.50005L9.91493 6.49985H8.49973C8.22372 6.49985 8 6.72399 8 6.99999C8 7.27599 8.22372 7.50013 8.49973 7.50013H9.49962" />
  </>,
  "Preform"
);

export default PreformIcon;
