import { Routes, Route, Navigate } from "react-router-dom";
import ObjectsDrawer from "./ObjectsDrawer";
import objectRoutes from "../route";
import { Box } from "@mui/material";
import TopBar from "./TopBar";
import { useCallback, useContext, useState } from "react";
import { AccessContext } from "RootApp";

const routes = Object.values(objectRoutes);

/**
 * Represents the main layout after the sign-in with the {@link TopBar}, {@link ObjectsDrawer} and the main area.
 * @author mohamed-salah.meguehout@dps-fr.com
 */

function App() {
  //* States
  const [isOpenDrawer, setOpenDrawer] = useState(false);

  //* Handler
  const handleDrawerToggle = useCallback(() => {
    setOpenDrawer(!isOpenDrawer);
  }, [isOpenDrawer]);

  const { user } = useContext(AccessContext);

  return (
    <Box display="grid" gridTemplateColumns="auto 1fr" overflow="hidden">
      <Box gridColumn="span 12">
        <TopBar onMenuToggle={handleDrawerToggle} isOpenMenu={isOpenDrawer} />
      </Box>
      <Box gridColumn="auto">
        <ObjectsDrawer userId={user} isOpen={isOpenDrawer} />
      </Box>
      <Box gridColumn="1fr" p={2}>
        <Routes>
          {routes.map((route, i) => (
            <Route key={i} path={route.path} element={<route.component />} />
          ))}
          <Route
            path="*"
            element={<Navigate to={objectRoutes.WelcomeRoute.path} />}
          />
        </Routes>
      </Box>
    </Box>
  );
}

export default App;
