import { useTheme } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { massArea } from "shared/constants/constants";

// &---------------------------------<< Component >>----*
interface MassTableProps {
  formik?: any;
  withMass?: boolean;
}
/**
 * JSX.Element element used to display mass distribution table
 * @prop {object} [mass] - the mass for each area
 * @prop {boolean} [withMass] - to display or not the mass column
 * @see this component use {@link MassItem} for items
 * @example <MassTable withMass />
 */

const MassTable = (props: MassTableProps) => {
  const { formik, withMass = false } = props;

  let targetSum = 0;
  let massSum = 0;
  let areas = massArea.slice();

  //*Comput target mass sum
  let { id, ...res } = formik.values.massRepartitionTarget;
  let targetValues = Object?.values(res as object).map(Number);

  targetSum = targetValues.reduce(
    (preVal: number, currentVal: number) => preVal + currentVal,
    0
  );
  targetSum = parseFloat(targetSum.toFixed(2));
  //*Comput mass sum
  if (withMass) {
    let { id, ...res } = formik.values.massRepartitionFinal;
    let massValues = Object?.values(res as object).map(Number);
    massSum = massValues.reduce(
      (preVal: number, currentVal: number) => preVal + currentVal,
      0
    );
    massSum = parseFloat(massSum.toFixed(2));
  }
  return (
    <TableContainer>
      <Table
        stickyHeader
        sx={{ minWidth: 200 }}
        aria-label="mass distribution table"
      >
        <TableHead>
          <TableRow>
            <TableCell variant="head">
              <Typography variant="h6" align="center">
                Zone
              </Typography>
            </TableCell>
            <TableCell
              variant="head"
              sx={{
                display: " flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h6" align="center">
                Height (mm)
              </Typography>
              <AreaMaxItem
                formik={formik}
                keyName={"ring"}
                withMass={withMass}
              />
            </TableCell>
            {withMass && (
              <TableCell variant="head">
                <Typography variant="h6" align="center">
                  Mass (g)
                </Typography>
              </TableCell>
            )}
            <TableCell variant="head">
              <Typography variant="h6" align="center">
                Target mass (g)
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {areas.map((area, i, array) => (
            <MassItem
              key={i}
              index={i}
              keyName={area.id}
              prevKeyName={array[i + 1] ? array[i + 1].id : undefined}
              formik={formik}
              area={area.name}
              areaBgImg={area.img}
              withMass={withMass}
            />
          ))}
        </TableBody>
        <TableFooter sx={{ bgcolor: "#d1e4f6" }}>
          <TableRow>
            <TableCell variant="footer"></TableCell>
            <TableCell align="left" variant="footer">
              <Typography variant="h6" align="center">
                Total mass
              </Typography>
            </TableCell>
            {withMass && (
              <TableCell variant="footer" align="center">
                <TextField
                  value={massSum}
                  variant="outlined"
                  size="small"
                  inputProps={{ style: { textAlign: "center" } }}
                />
              </TableCell>
            )}
            <TableCell align="center" variant="footer">
              <TextField
                value={targetSum}
                disabled
                variant="outlined"
                size="small"
                inputProps={{ style: { textAlign: "center" } }}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                }}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default MassTable;

// &---------------------------------<< SubComponent >>----*
interface MassItemProps {
  index: number;
  area: string;
  areaBgImg: any;
  formik?: any;
  keyName?: any;
  prevKeyName?: any;
  withMass?: boolean;
}
const areaStyle = (areaImg: any) => {
  return {
    backgroundImage: `url(${areaImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
};

interface AreaMaxItemProps {
  keyName: string;
  formik: any;
  withMass: boolean;
}

const AreaMaxItem = (props: AreaMaxItemProps) => {
  const { keyName, withMass, formik } = props;
  const { palette } = useTheme();
  const disabledStyle = withMass
    ? {
        background: palette.grey[100],
        width: "80px",
        borderRadius: "100%",
        "& .MuiOutlinedInput-notchedOutline": { border: "none" },
      }
    : {};
  return (
    <TextField
      type="number"
      id={`areaDistribution.${keyName}`}
      disabled
      value={
        withMass
          ? formik.values.areaDistribution[keyName]
          : formik.values.areaDistribution.ring || 0
      } //^^Start
      variant="outlined"
      size="small"
      inputProps={{ style: { textAlign: "center" } }}
      sx={{
        zIndex: 1000,
        transform: "translateY(90%)",
        background: "white",
        ...disabledStyle,
      }}
      onChange={formik.handleChange}
      error={Boolean(
        formik.errors.areaDistribution &&
          formik.errors.areaDistribution[keyName]
      )}
    />
  );
};

/**
 * JSX.Element element used as item in MassTable component
 * @prop {number} index - item index
 * @prop {string} area - area name
 * @prop {any} areaBgImg - the bottle area image
 * @prop {any} [formik] - formik data
 * @prop {any} [keyName] -area id
 * @prop {any} [prevKeyName] - previus area id
 * @prop {boolean} [withMass] - defined if we diplay or not the mass cell
 * @see this component used in {@link MassTable} for items
 * @example <MassItem key={i} index={i} keyName={area.id} prevKeyName={array[i + 1] ? array[i + 1].id : undefined} formik={formik} area={area.name} areaBgImg={area.img} withMass={withMass} />
 */

const MassItem = (props: MassItemProps) => {
  const { index, area, areaBgImg, formik, keyName, prevKeyName, withMass } =
    props;
  const { palette } = useTheme();
  const disabledStyle = withMass
    ? {
        background: palette.grey[100],
        width: "80px",
        borderRadius: "100%",
        "& .MuiOutlinedInput-notchedOutline": { border: "none" },
      }
    : {};
  return (
    <TableRow sx={{ backgroundColor: getBgColor(index) }}>
      <TableCell
        align="center"
        width="144px"
        padding="none"
        sx={{ borderBottom: "none", ...areaStyle(areaBgImg) }}
      ></TableCell>
      <TableCell
        sx={{
          display: "grid",
          alignSelf: "center",
          justifySelf: "center",
          gridTemplateRows: "40px",
        }}
        align="center"
      >
        <div style={{ zIndex: 1000 }}>
          <Typography
            variant="body2"
            sx={{
              transform: "translateY(40%)",
            }}
          >
            {area}
          </Typography>
          <TextField
            type="number"
            id={`areaDistribution.${prevKeyName}`}
            disabled={withMass || !prevKeyName}
            value={
              prevKeyName ? formik.values.areaDistribution[prevKeyName] : 0
            } //^^end
            variant="outlined"
            size="small"
            inputProps={{
              style: { textAlign: "center" },
            }}
            sx={{
              backgroundColor: "white",
              transform: "translateY(40%)",
              ...disabledStyle,
            }}
            SelectProps={{}}
            onChange={prevKeyName && formik.handleChange}
            error={Boolean(
              formik.errors.areaDistribution &&
                formik.errors.areaDistribution[prevKeyName]
            )}
          />
        </div>
      </TableCell>
      {withMass && (
        <TableCell align="center">
          <TextField
            type="number"
            id={`massRepartitionFinal.${keyName}`}
            value={formik.values.massRepartitionFinal[keyName]}
            variant="outlined"
            size="small"
            inputProps={{ style: { textAlign: "center" } }}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={Boolean(
              formik.errors.massRepartitionFinal &&
                formik.errors.massRepartitionFinal[keyName]
            )}
          />
        </TableCell>
      )}
      <TableCell align="center">
        <TextField
          type="number"
          id={`massRepartitionTarget.${keyName}`}
          disabled={withMass}
          value={formik.values.massRepartitionTarget[keyName]} //^^Target
          variant="outlined"
          size="small"
          inputProps={{ style: { textAlign: "center" } }}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(
            formik.errors.massRepartitionTarget &&
              formik.errors.massRepartitionTarget[keyName]
          )}
          sx={
            withMass
              ? {
                  "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                }
              : {}
          }
        />
      </TableCell>
    </TableRow>
  );
};

const getBgColor = (i: number) => (i % 2 ? "initial" : "#d1e4f6");
