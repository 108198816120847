import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { dispatchFetch } from "shared/components/fetchers";
import LoadingSpinner from "shared/components/LoadingSpinner";
import { contentType } from "shared/constants/constants";
import ProjectImg from "shared/images/global/NoResults.png";
import {
  BorderProps,
  getErrorMessage,
  updateUrlPrefix,
} from "shared/Utils/utils";

// &---------------------------------<< Component >>----*
type RankingResultsType = Array<{ [key: string]: string | number }>;
interface BestPreformTableProps {
  rankingData: any;
  targetArea: BorderProps | undefined;
  focusedItemRank?: number;
  updateFocusedPrefRank: (rank: number) => void;
}

interface RankingDataInterface {
  count: number;
  next: string;
  previous: string;
  results: RankingResultsType;
  pageCount: number;
}

/**
 * JSX.Element element represents the right area of find best preform page
 * @prop {any} rankingData - ranking data.
 */

const BestPreformTable = (props: BestPreformTableProps) => {
  const {
    rankingData,
    targetArea,
    updateFocusedPrefRank,
    focusedItemRank = 0,
  } = props;

  //*states
  const [tableData, setTableData] = useState<RankingDataInterface>();
  const [tableResults, setTableResults] = useState<RankingResultsType>();
  const [focusedRank, setFocusedRank] = useState<number>(focusedItemRank);
  const [page, setPage] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  //* Const
  const ROWPERPAGE = 10;
  //* Handlers
  const handleChangePage = async (e: unknown, newPage: number) => {
    let newPageURL: string;

    if (e === "goTo") {
      newPageURL = `${
        process.env.REACT_APP_API_BASE_URL
      }/preforms/ranking/?page=${newPage + 1}`;
    } else {
      newPage > page
        ? (newPageURL = tableData?.next as string)
        : (newPageURL = tableData?.previous as string);

      newPageURL = updateUrlPrefix(
        newPageURL,
        process.env.REACT_APP_API_BASE_URL as string
      );
    }

    rankingData &&
      (await dispatchFetch("POST")(newPageURL, rankingData, contentType.json)
        .then((data: any) => {
          setTableResults(data.results);
          setTableData(data);
          setPage(newPage);
        })
        .catch((error: any) => {
          let errorMessage = getErrorMessage(error);
          enqueueSnackbar(errorMessage, {
            variant: "error",
          });
        }));
  };

  const handleFocusedRank = (e: any, rank: number) => {
    const newRank = rank !== focusedRank ? rank : 0;
    setFocusedRank(newRank);
    updateFocusedPrefRank(newRank);
  };

  //*Watcher
  useEffect(() => {
    const url = `/preforms/ranking/?page=1`;

    rankingData &&
      dispatchFetch("POST")(url, rankingData, contentType.json)
        .then((data: any) => {
          setPage(0);
          setTableResults(data.results);
          setTableData(data);
        })
        .catch((error: any) => {
          let errorMessage = getErrorMessage(error);
          enqueueSnackbar(errorMessage, {
            variant: "error",
          });
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rankingData]);

  useEffect(() => {
    const pageNbr = Math.floor((focusedItemRank - 1) / ROWPERPAGE);
    pageNbr >= 0 && handleChangePage("goTo", pageNbr);
    setFocusedRank(focusedItemRank);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedItemRank]);

  return (
    <TableContainer component={Box} height="calc(100vh - 180px)">
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <Typography variant="h6" color="initial">
                Rank
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography variant="h6" color="initial">
                Preform name
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="h6" color="initial">
                Mass
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="h6" color="initial">
                Process
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableResults ? (
            tableResults.length > 0 ? (
              tableResults.map((result, i) => (
                <ResultItem
                  key={i}
                  data={result}
                  rank={i + 1 + page * ROWPERPAGE}
                  target={targetArea}
                  selected={i + 1 + page * ROWPERPAGE === focusedRank}
                  updateFocusedPrefRank={handleFocusedRank}
                />
              ))
            ) : (
              <TableRow>
                <th
                  style={{
                    backgroundImage: `url(${ProjectImg})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    width: "400px",
                    height: "400px",
                  }}
                ></th>
              </TableRow>
            )
          ) : (
            <tr>
              <th>
                <LoadingSpinner />
              </th>
            </tr>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[ROWPERPAGE]}
        component="div"
        count={tableData ? tableData.count : 0}
        rowsPerPage={10}
        page={page}
        onPageChange={handleChangePage}
      />
    </TableContainer>
  );
};

export default BestPreformTable;

// &---------------------------------<< SubComponent >>----*

const isInTarget = (
  dot: { x: number; y: number },
  x: { min: number; max: number },
  y: { min: number; max: number }
) => {
  const isXin: boolean = x.min <= dot.x && dot.x <= x.max;
  const isYin: boolean = y.min <= dot.y && dot.y <= y.max;

  return isXin && isYin;
};
interface ResultItemProps {
  data: any;
  rank: number;
  target: BorderProps | undefined;
  selected?: boolean;
  updateFocusedPrefRank: (event: any, rank: number) => void;
}

const ResultItem = (props: ResultItemProps) => {
  const { data, rank, target, selected = false, updateFocusedPrefRank } = props;
  const { palette } = useTheme();
  const [openProcessDialog, setOpenProcessDialog] = useState(false);
  const [preformProcessName, setPreformProcessName] = useState();
  const [processes, setProcesses] = useState();
  const [dotColor, setDotColor] = useState<string>("none");
  const theme = useTheme();

  const dotStyle = useMemo(
    () => ({
      jsutifySelf: "center",
      width: 22,
      margin: "auto",
      height: 22,
      borderRadius: "50%",
      display: "grid",
      placeItems: "center",
      backgroundColor: dotColor,
      color: theme.palette.primary.contrastText,
      fontWeight: "bold",
      fontSize: 12,
      userSelect: "none",
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dotColor]
  );

  //*Function
  const getTooltipContent = useCallback((infos) => {
    const { longiSR, radialSR, wallThickness } = infos;
    return (
      <>
        {longiSR && <h3>{`Longi SR= ${longiSR}`}</h3>}
        {radialSR && <h3>{`Radial SR = ${radialSR}`}</h3>}
        {wallThickness && <h3>{`Wall thickness = ${wallThickness}`}</h3>}
      </>
    );
  }, []);

  //*Watcher
  useEffect(() => {
    if (target && target.x.name && target.y.name) {
      const preformPos = { x: data[target.x.name], y: data[target.y.name] };
      const color = isInTarget(preformPos, target.x, target.y)
        ? "#04ac12ae"
        : "#e70000ae";
      setDotColor(color);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target, data]);

  //*Render
  return (
    <>
      <TableRow
        sx={{
          cursor: "pointer",
          "&:hover": { backgroundColor: `${palette.secondary.main}40` },
          backgroundColor: selected ? "#fcfbbd" : "initial",
        }}
        onClick={(e) => {
          updateFocusedPrefRank(e, rank);
        }}
      >
        <TableCell align="center">
          <HtmlTooltip title={getTooltipContent(data)}>
            <Typography
              sx={dotStyle}
              variant="body1"
              color="initial"
            >
              {rank}
            </Typography>
          </HtmlTooltip>
        </TableCell>
        <TableCell align="left">
          <Button
            size="small"
            sx={{ textTransform: "capitalize", textAlign: "left" }}
          >
            <Link
              href={`/preform/infos/${data.preformID}`}
              target="_blank"
              rel="noreferrer"
              underline="none"
            >
              {data.preformName}
            </Link>
          </Button>
        </TableCell>
        <TableCell align="center">
          <Typography
            sx={{ userSelect: "none" }}
            variant="body1"
            color="initial"
          >
            {data.preformMass ? data.preformMass : "Ø"}
          </Typography>
        </TableCell>
        <TableCell align="right">
          {data.preformProcesses.length ? (
            <Button
              size="small"
              sx={{ textTransform: "capitalize", textAlign: "right" }}
              onClick={() => {
                setPreformProcessName(data.preformName);
                setProcesses(data.preformProcesses);
                setOpenProcessDialog(true);
              }}
            >
              see process
            </Button>
          ) : (
            <Typography variant="caption" color={palette.grey[500]}>
              No process data
            </Typography>
          )}
        </TableCell>
      </TableRow>
      <DialogProcess
        open={openProcessDialog}
        preform={preformProcessName}
        processes={processes}
        onClose={() => setOpenProcessDialog(false)}
      />
    </>
  );
};

//&----------<< SubComponent >>--------------//

interface DialogProcessProps {
  open?: boolean;
  preform?: string;
  processes?: Array<{ id: number; name: string }>;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
}

const DialogProcess = (props: DialogProcessProps) => {
  const { open = false, preform, processes, onClose } = props;
  return (
    <Dialog open={open} aria-labelledby="addFiles-dialog">
      <DialogTitle id="preformProcesses-dialog">{`Processes of ${preform}`}</DialogTitle>
      <DialogContent>
        <DialogContentText component="div">
          <TableContainer component={Box}>
            <Table aria-label="processes list">
              <TableBody>
                {processes &&
                  processes.map((process, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <Link
                          href={`/process/infos/${process.id}`}
                          target="_blank"
                          rel="noreferrer"
                          underline="none"
                        >
                          <Typography variant="subtitle1">
                            {process.name}
                          </Typography>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

//&-----------styledToolTip---------------
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} disableInteractive classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fcfbbd",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));
