import { Box, Button, Link, Typography, useTheme } from "@mui/material";
import SSOImg from "shared/images/SSOBG/SSOBG_img.png";
import DanoneLogoImg from "shared/images/SSOBG/danoneResearchLogo_img.png";
import { useContext } from "react";
import { AccessContext } from "RootApp";
import { Routes, Route, Navigate } from "react-router-dom";
const Login = () => {
  const { toggleAccess } = useContext(AccessContext);

  const { palette } = useTheme();

  return (
    <Routes>
      <Route
        path={"/login"}
        element={
          <Box
            width="100vw"
            height="100vh"
            sx={{
              backgroundImage: `url(${SSOImg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            display="grid"
            gridTemplateRows="3fr 2fr 2fr 2fr"
            justifyItems="center"
          >
            <img
              src={DanoneLogoImg}
              alt="danone research logo"
              style={{
                opacity: "0.6",
                height: "60%",
                mixBlendMode: "multiply",
                alignSelf: "center",
              }}
            />
            
            
            <Button
              variant="contained"
              size="large"
              sx={{ alignSelf: "start", borderRadius: "50px", margin: "5%" }}
              onClick={toggleAccess}
            >
              <Link
                href={`/accounts/login/`}
                rel="noreferrer"
                sx={{ color: "white" }}
              >
                Single Sign - On
              </Link>
            </Button>
            <Box
              display="grid"
              gridTemplateRows="auto auto"
              gap={2}
              sx={{
                padding: "20px"
              }}
            >
              <Typography
                width="40vw"
                justifySelf="center"
                variant="h5"
                align="center"
                color={palette.common.black}
                fontWeight={'bold'}
              >
                Welcome into Optibottle platform
              </Typography>
              <Typography
                width="40vw"
                justifySelf="center"
                variant="h6"
                align="center"
                color={palette.common.black}
              >
                Optibottle is a technical database for preforms, bottles, and their
                manufacturing data helping you track the best preform features
                (supplier, dimensions, raw material, ...)
              </Typography>
            </Box>
          </Box>
        }
      />
      <Route path="*" element={<Navigate to={"/login"} />} />
    </Routes>
  );
};

export default Login;
