import { Navigate, Route, Routes } from "react-router-dom";
import objectRoutes from "screens/App/route";

const Process = () => {
  const processRoutes = Object.values(objectRoutes.ProcessRoute.routes);
  return (
    <Routes>
      {processRoutes.map((processRoute, i) => (
        <Route
          key={i}
          path={processRoute.path}
          element={<processRoute.component />}
        ></Route>
      ))}
      <Route
        path="*"
        element={
          <Navigate to={objectRoutes.ProcessRoute.routes.researchRoute.path} />
        }
      />
    </Routes>
  );
};

export default Process;
