import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { FileEye } from "mdi-material-ui";
import { updateUrlPrefix } from "shared/Utils/utils";

interface FilesListInfosProps {
  filesList: { Name: string; file: string }[];
}

/**
 * JSX.Element element represents the dynamic layout to switch between twoColumns <-> oneColumn
 * @prop { { Name: string; file: string }[]} filesList - List of files (displayed in the dialog)
 * @example <FilesListInfos filesList={infos && infos["Additional files"]} />
 */

const FilesListInfos = (props: FilesListInfosProps) => {
  const { filesList } = props;
  return (
    <TableContainer component={Box}>
      <Table aria-label="files list">
        <TableBody>
          {filesList.map((fileInfo, i) => (
            <TableRow key={i}>
              <TableCell>
                <Typography variant="subtitle1">{fileInfo.Name}</Typography>
              </TableCell>
              <TableCell>
                <a
                  href={updateUrlPrefix(
                    fileInfo.file,
                    process.env.REACT_APP_MEDIA_BASE_URL as string
                  )}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Tooltip title="open" placement="top" arrow>
                    <IconButton
                      size="small"
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <FileEye />
                    </IconButton>
                  </Tooltip>
                </a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FilesListInfos;
