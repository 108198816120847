import { Box, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  Filter,
  SearchLayout,
  AddObjectButton,
  ResultTable,
} from "shared/components";
import {
  generateURL,
  getIsReset,
  isEmptyObject,
  SearchDataProps,
  SearchDataResultsProps,
} from "shared/Utils/utils";

import BottleForm from "screens/App/views/Bottle/components/BottleForm";
import { bottleFiltersConfig } from "shared/constants/filtersConfig";
import { FormikHelpers, useFormik } from "formik";
import { dispatchFetch } from "shared/components/fetchers";
import { useLocation } from "react-router-dom";
import { CashContext } from "RootApp";

const Research = () => {
  const [results, setResult] = useState<SearchDataProps>();
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const [isFromFBP, setIsFromFBP] = useState(false);
  const { updateCash, cashData } = useContext(CashContext);
  const [filterHistory] = useState(cashData.filterHistory.bottles);
  //* Mounted GET Init
  useEffect(() => {
    !isEmptyObject(filterHistory) && formi.handleSubmit();
    dispatchFetch("GET")(`/bottles/`).then((data: any) => {
      if (location.hash === "#FindBestPreform") {
        const { results } = data;
        let newResults: Object[] = [];
        results.forEach((result: SearchDataResultsProps) => {
          result = { ...result };
          newResults = [...newResults, result];
        });
        data = { ...data, results: newResults };
      }
      setResult(data);
    });

    setIsFromFBP(location.hash === "#FindBestPreform");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash]);

  //*Handler
  const handleChangePage = (pageURL: string) => {
    dispatchFetch("GET")(pageURL)
      .then((data: SearchDataProps) => {
        setResult(data);
      })
      .catch((error: any) => {});
  };

  const handleApplyFilter = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    const filterCash = {
      filterHistory: { ...cashData.filterHistory, bottles: values },
    };
    updateCash(filterCash);
    let url = generateURL(values, "bottles");
    setLoading(true);
    try {
      await dispatchFetch("GET")(url).then((data: any) => {
        setResult(data);
      });
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    formi.setValues({});
    formi.handleSubmit();
  };
  //*Formik
  const formi = useFormik({
    enableReinitialize: true,
    initialValues: filterHistory,
    onSubmit: handleApplyFilter,
  });

  //*Render
  return (
    <SearchLayout
      onEnter={formi.handleSubmit}
      filterArea={
        <Filter
          onApplyFilte={() => formi.handleSubmit()}
          onReset={handleReset}
          isLoading={loading}
          isFilterEmpty={getIsReset(formi.values)}
        >
          <Box minWidth="230px">
            <BottleForm formConfig={bottleFiltersConfig} formik={formi} />
          </Box>
        </Filter>
      }
      topResultArea={
        <Box
          display="grid"
          gridTemplateColumns={isFromFBP ? "6fr 1fr" : "1fr"}
          columnGap={2}
          alignItems="center"
        >
          {isFromFBP && (
            <Box
              display="grid"
              gridTemplateColumns="5fr 1fr"
              alignItems="center"
            >
              <Typography variant="h6" textAlign="left" color="white">
                Select in the list below the bottle for which you search a
                preform. Use the filter for quick results. If no bottle found,
                please create a new one.
              </Typography>
              <AddObjectButton
                variant="outlined"
                objectName="Project"
                path="project/add"
                pathDeep={1}
              />
            </Box>
          )}
          <AddObjectButton variant="contained" objectName="Bottle" />
        </Box>
      }
      resultArea={
        <ResultTable
          title="Bottles"
          data={results}
          onChangePage={handleChangePage}
        />
      }
    />
  );
};

export default Research;
