import useSWRImmutable from "swr/immutable";
import { GenericForm } from "shared/components";
import {
  FieldConfigTemplate,
  FieldConfig,
  AutoOption,
} from "shared/Utils/utils";
import LoadingSpinner from "shared/components/LoadingSpinner";
import useSWR from "swr";

export type PreformFormConfigType = {
  supplier: FieldConfigTemplate<"select">;
  resin: FieldConfigTemplate<"select">;
  neck: FieldConfigTemplate<"select">;
  bottomTypeShape: FieldConfigTemplate<"select">;
  technicalDrawing: FieldConfigTemplate<"fileField">;
  [key: string]: FieldConfig;
};

type PreformFilterConfigType = {
  supplier: FieldConfigTemplate<"select">;
  resin: FieldConfigTemplate<"select">;
  neck: FieldConfigTemplate<"select">;
  bottomTypeShape: FieldConfigTemplate<"select">;
  [key: string]: FieldConfig;
};

type PreformFormType = {
  formConfig: PreformFormConfigType | PreformFilterConfigType;
  formik: any;
  validatioSchema?: any;
};

const PreformForm = ({
  formConfig,
  formik,
  validatioSchema,
}: PreformFormType) => {
  const { data: suppliers } = useSWRImmutable(
    "/preforms/suppliers?pageSize=10000"
  );
  const { data: resins } = useSWRImmutable("/preforms/resins?pageSize=10000");
  const { data: necks } = useSWRImmutable(
    "/necks/?pageSize=10000&order_by=name"
  );
  const { data: bottomTypeShapes } = useSWRImmutable(
    "/preforms/bottomTypeShapes?pageSize=10000"
  );
  const { data: preforms } = useSWR(`/preforms/?pageSize=10000&order_by=name`);
  let preformsName: Array<AutoOption> = [];
  preforms?.results.map(
    (result: { name: string }) =>
      (preformsName = [...preformsName, { label: result.name }])
  );

  if (!suppliers || !resins || !necks || !bottomTypeShapes)
    return <LoadingSpinner />;

  const { supplier, resin, neck, bottomTypeShape, name__icontains } =
    formConfig;

  supplier.parameters.options = suppliers?.results;
  resin.parameters.options = resins?.results;
  neck.parameters.options = necks?.results;
  bottomTypeShape.parameters.options = bottomTypeShapes?.results;

  if (name__icontains)
    (
      name__icontains as FieldConfigTemplate<"textField">
    ).parameters.autoOptions = preformsName;

  return (
    <GenericForm
      formConfig={formConfig}
      formik={formik}
      validatioSchema={validatioSchema}
    />
  );
};

export default PreformForm;
