import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
  FormConfig,
  FieldConfigTemplate,
  isFieldRequired,
  replaceIdByName,
} from "shared/Utils/utils";
import FileField from "./FileField";
import {
  Autocomplete,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import {
  CheckboxMultipleMarked,
  CheckboxMultipleBlankOutline,
} from "mdi-material-ui";

/** JSX.Element to generate all the forms in the app
 * @prop {FormConfig} formConfig -
 * @prop {any} formik -
 * @prop {any} validatioSchema -
 * @example
 */

type GenericFormProps = {
  formConfig: FormConfig;
  formik: any;
  validatioSchema?: any;
};

const GenericForm = ({
  formConfig,
  formik,
  validatioSchema,
}: GenericFormProps) => {
  const { palette } = useTheme();

  const renderTextField = (key: string) => {
    const fieldConfig = formConfig[key] as FieldConfigTemplate<"textField">;
    const { label, gridxs, parameters } = fieldConfig;
    return (
      <Grid key={key} item xs={gridxs || 12}>
        {parameters.type === "number" ? (
          <TextFieldCustom params={{}} keyName={key} label={label} formik={formik} parameters={parameters} formConfig={formConfig} validatioSchema={validatioSchema}/>
        ) : (
          <Autocomplete
            value={formik.values[key]?.toString() || ""}
            disableClearable
            options={
              parameters.autoOptions
                ? parameters.autoOptions.map((autoOption) => autoOption.label)
                : []
            }
            freeSolo
            renderInput={(params) => (
              <TextFieldCustom params={params} keyName={key} label={label} formik={formik} parameters={parameters} formConfig={formConfig} validatioSchema={validatioSchema}/>
            )}
          />
        )} 
      </Grid>
    );
  };

  const renderSelect = (key: string) => {
    const fieldConfig = formConfig[key] as FieldConfigTemplate<"select">;
    const { label, gridxs, parameters } = fieldConfig;

    // get NamedObject
    const option =
      parameters.options &&
      parameters.options.find((elt) => elt.id === formik.values[key]);

    //*Handler
    const handleAutocompleteChange = (
      event: React.SyntheticEvent<Element, Event>,
      value: any | null
    ) => {
      event.stopPropagation();
      const newValue = value ? value : "";
      formik.setFieldValue(key, newValue.id);
    };
    //*Rendrer
    return (
      <Grid key={key} item xs={gridxs || 12}>
        <Autocomplete
          value={option || null}
          blurOnSelect
          onChange={handleAutocompleteChange}
          options={parameters.options ? parameters.options : []}
          getOptionLabel={(option) => (option.name ? option.name : "")}
          renderInput={(params) => (
            <TextField
              {...params}
              id={key}
              name={key}
              label={label}
              required={isFieldRequired(key, validatioSchema)}
              fullWidth
              variant="outlined"
              onChange={formik.handleChange}
              error={formik.touched[key] && Boolean(formik.errors[key])}
            />
          )}
        />
        <FormHelperText>
          {formik.touched[key] && formik.errors[key]}
        </FormHelperText>
      </Grid>
    );
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    variant: "menu" as "menu",
  };

  const RenderMultiSelect = (key: string) => {
    const fieldConfig = formConfig[key] as FieldConfigTemplate<"multiSelect">;
    const { label, gridxs, parameters } = fieldConfig;
    const { options } = parameters;

    const labelId = key + "Label";
    //*Handler
    const handleChange = (event: any) => {
      const {
        target: { value },
      } = event;

      value[value.length - 1] === "all"
        ? handleSelectAll()
        : formik.setFieldValue(key, value);
    };

    const handleSelectAll = () => {
      let optionsIds: number[] = [];

      if (options?.length !== formik.values[key].length) {
        optionsIds = options ? options.map((item) => item.id) : [];
      }
      formik.setFieldValue(key, optionsIds);
    };

    const renderSelecteditems = (selected: any) => {
      const selectedNames = replaceIdByName(selected, options);
      return selectedNames.join(", ");
    };

    return (
      <Grid key={key} item xs={gridxs || 12}>
        <FormControl
          variant="outlined"
          required={isFieldRequired(key, validatioSchema)}
          fullWidth
          error={formik.touched[key] && Boolean(formik.errors[key])}
        >
          <InputLabel id={labelId}>{label}</InputLabel>
          <Select
            label={label}
            labelId={labelId}
            id={key}
            multiple
            name={key}
            value={formik.values[key] || [""]}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched[key] && Boolean(formik.errors[key])}
            renderValue={renderSelecteditems}
            MenuProps={MenuProps}
          >
            <MenuItem divider disableGutters key={-1} value="all">
              <Checkbox
                sx={{ color: palette.primary.main }}
                checked={options?.length === formik.values[key].length}
                icon={<CheckboxMultipleBlankOutline />}
                checkedIcon={<CheckboxMultipleMarked />}
              />
              <ListItemText
                primary={"Select all"}
                sx={{ color: palette.primary.main }}
              />
            </MenuItem>
            {options &&
              (options.length > 0 ? (
                options.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    <Checkbox
                      checked={formik.values[key].indexOf(option.id) > -1}
                    />
                    <ListItemText primary={option.name} />
                  </MenuItem>
                ))
              ) : (
                <MenuItem key={-2} disabled>
                  No item
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>
            {formik.touched[key] && formik.errors[key]}
          </FormHelperText>
        </FormControl>
      </Grid>
    );
  };

  const renderFileField = (key: string) => {
    const fieldConfig = formConfig[key] as FieldConfigTemplate<"fileField">;
    const { label, gridxs, parameters } = fieldConfig;

    return (
      <Grid key={key} item xs={gridxs || 12}>
        <FileField
          id={key}
          name={key}
          label={label}
          required={isFieldRequired(key, validatioSchema)}
          onChange={(e) => {
            formik.setFieldValue(
              key,
              e?.currentTarget.files ? e.currentTarget.files[0] : null
            );
          }}
          onDelete={() => {
            formik.setFieldValue(key, null);
          }}
          onBlur={formik.handleBlur}
          error={formik.touched[key] && Boolean(formik.errors[key])}
          helperText={formik.touched[key] && formik.errors[key]}
          {...parameters}
        />
      </Grid>
    );
  };

  const renderCheckbox = (key: string) => {
    const fieldConfig = formConfig[key] as FieldConfigTemplate<"checkbox">;
    const { label, gridxs } = fieldConfig;
    return (
      <Grid key={key} item xs={gridxs || 12}>
        <FormControlLabel
          value={key}
          control={
            <Checkbox
              required={isFieldRequired(key, validatioSchema)}
              id={key}
              name={key}
              checked={Boolean(formik.values[key])}
              value={formik.values[key] !== undefined || false}
              onChange={formik.handleChange}
            />
          }
          label={label}
          labelPlacement="end"
        />
      </Grid>
    );
  };

  const dispatchRenderField = (key: string) => {
    const { type } = formConfig[key];
    switch (type) {
      case "textField":
        return renderTextField(key);
      case "select":
        return renderSelect(key);
      case "multiSelect":
        return RenderMultiSelect(key);
      case "fileField":
        return renderFileField(key);
      case "checkbox":
        return renderCheckbox(key);
      default:
        return null;
    }
  };

  return (
    <div>
      <form>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-end"
          spacing={2}
          p={1}
        >
          {Object.keys(formConfig).map((key) => dispatchRenderField(key))}
        </Grid>
      </form>
    </div>
  );
};

interface TextFieldNumberCustomProps extends GenericFormProps {
  params: any;
  keyName: string;
  label: string;
  parameters: any;
}

const TextFieldCustom = (props: TextFieldNumberCustomProps) => {
  const {
    params,
    keyName,
    label,
    formik,
    parameters,
    formConfig,
    validatioSchema,
  } = props;
  return (
    <TextField
      {...params}
      fullWidth
      required={isFieldRequired(keyName, validatioSchema)}
      variant="outlined"
      id={keyName}
      name={keyName}
      label={label}
      value={formik.values[keyName] || ""}
      multiline={Number.isInteger(parameters.rows)}
      type={parameters.type}
      rows={parameters.rows}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      onSelect={formik.handleChange}
      error={formik.touched[keyName] && Boolean(formik.errors[keyName])}
      helperText={
        formik.touched[keyName] && formik.errors[keyName]
          ? formik.touched[keyName] && formik.errors[keyName]
          : (formConfig[keyName].parameters as { helperText: string })
              .helperText
      }
    />
  );
};

export default GenericForm;
