import { Box, Button, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { PlusCircleOutline, CloseCircleOutline } from "mdi-material-ui";
import { dispatchFetch } from "./fetchers";
import FileInput from "./FileInput";

interface AddFilesProps {
  initAddFiles?: any;
  onUpdate?: (files: any[], deleteFiles: string[]) => void;
}

/**
 * JSX.Element element represents the add multiple additional files for process
 * @prop {any} [initAddFiles = []] - State of fields.
 * @prop {(files: any[] | undefined, deleteFiles?: string[]) => void} [onUpdate] - trigged when the user update field.
 * @see this use {@link FileField} for the fields to add files
 * @example <AddFiles open={addFilesDialogOpen} title="Add files" onClose={hanldeCloseDialog}/>
 */

const AddFiles = (props: AddFilesProps) => {
  const { initAddFiles = undefined, onUpdate } = props;

  const [files, setFiles] = useState<
    Array<{ name: string; [key: string]: any }>
  >([{ name: "" }]);

  const [filesToDeletID, setFilesToDeletID] = useState<Array<string>>([]);
  const [extension, setExtension] = useState<string>("");

  //*Mounted Init
  useEffect(() => {
    dispatchFetch("GET")("/fileExtensions").then((data: any) => {
      const supportedExtension = data
        .map((ext: string) => `.${ext}`)
        .join(", ");
      setExtension(supportedExtension);
    });
  }, []);

  //*watcher
  useEffect(() => {
    initAddFiles && setFiles(initAddFiles);
  }, [initAddFiles]);

  useEffect(() => {
    handleFilesChange(files, filesToDeletID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const handleFilesChange = (filesList: any, filesDeletID: string[]) => {
    const filtredFiles = filesList.filter((file: any) => {
      return file.name !== "" && file.id === undefined;
    });
    onUpdate && onUpdate(filtredFiles, filesDeletID);
  };

  const handleAddFileField = () => {
    let newFiles = files.slice();
    newFiles.push({ name: "" });
    setFiles(newFiles);
  };

  const handleDeleteFileField = (file: any, index: number) => {
    //~save id of file to delete
    file.id && setFilesToDeletID([...filesToDeletID, file.id]);

    //~delete file field
    let newFiles = files.slice();
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  //*Rendrer
  return (
    <>
      <Box display="grid" gridAutoRows="auto" gap={2} padding={1}>
        {files.map((file, i) => {
          return (
            <Box key={i} display="flex" alignItems="center" gap={1}>
              <FileInput
                id={file.name}
                accept={extension}
                name={`additionalFiles[${i}]`}
                label=""
                initialValue={file.name}
                onChange={(e) => {
                  const newFiels = files.slice();
                  const file = e?.currentTarget.files
                    ? e.currentTarget.files[0]
                    : { name: "" };
                  newFiels.splice(i, 1, file);
                  setFiles(newFiels);
                }}
              />
              <IconButton
                aria-label="delete file filed"
                onClick={() => handleDeleteFileField(file, i)}
                color="error"
              >
                <CloseCircleOutline />
              </IconButton>
            </Box>
          );
        })}
      </Box>
      <Button
        aria-label="add file filed"
        onClick={handleAddFileField}
        color="primary"
        startIcon={<PlusCircleOutline />}
        variant="outlined"
        sx={{ marginLeft: 1 }}
      >
        Add file
      </Button>
    </>
  );
};

export default AddFiles;
