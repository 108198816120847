import { Box, CircularProgress } from "@mui/material";

const LoadingSpinner = () => {
  return (
    <Box display="grid" paddingTop={2} justifyContent="center" width="100%">
      <CircularProgress />
    </Box>
  );
};

export default LoadingSpinner;
