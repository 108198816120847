import appRouteConstants from "shared/constants/appRoutes";
import Welcome from ".";

const route = {
  path: appRouteConstants.WELCOME.path,
  component: Welcome,
  routes: {},
};

export default route;
