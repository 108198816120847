import ring from "../images/splitedBottle/ring.png";
import subring from "../images/splitedBottle/subring.png";
import shoulder from "../images/splitedBottle/shoulder.png";
import labelArea from "../images/splitedBottle/labelArea.png";
import body from "../images/splitedBottle/body.png";
import bottom from "../images/splitedBottle/bottom.png";

export const contentType = {
  formData: {
    headers: { "Content-Type": "multipart/form-data" },
  },
  json: {
    headers: { "Content-Type": "application/json" },
  },
};
export const cashDataInit = {
  filterHistory: {
    projects: {},
    preforms: {},
    bottles: {},
    processes: {},
  },
};

export const massTableInitValues = {
  areaDistribution: {
    bottom: 0,
    body: 0,
    labelArea: 0,
    shoulder: 0,
    subring: 0,
    ring: 0,
  },

  massRepartitionFinal: {
    bottom: 0,
    body: 0,
    labelArea: 0,
    shoulder: 0,
    subring: 0,
    ring: 0,
  },
  massRepartitionTarget: {
    bottom: 0,
    body: 0,
    labelArea: 0,
    shoulder: 0,
    subring: 0,
    ring: 0,
  },
};

export const massArea = [
  { id: "ring", name: "Ring", img: ring },
  { id: "subring", name: "Subring", img: subring },
  { id: "shoulder", name: "Shoulder", img: shoulder },
  { id: "labelArea", name: "Label", img: labelArea },
  { id: "body", name: "Body", img: body },
  { id: "bottom", name: "Bottom", img: bottom },
];

export const objectInfosNames = {
  project: {
    createdOn: "Created On",
    modifiedOn: "Modified On",
    name: "Name",
    owner: "Owner",
    leader: "Leader",
    iProview: "iProview",
    objective: "Objective",
    brand: "Brand",
    startYear: "Start year",
    endYear: "End Year",
    brief: "Brief",
  },
  preform: {
    createdOn: "Created on",
    modifiedOn: "Modified on",
    name: "Name",
    owner: "Owner",
    supplier: "Supplier",
    refNumber: "Reference number",
    resin: "Resin",
    neck: "Neck",
    thickness: "Thickness (mm)",
    halfLength: "Half length (mm)",
    height: "Height (mm)",
    bodyDiameter: "Body diameter (mm)",
    mass: "Mass (g)",
    bottomTypeShape: "Injection Machine",
    bottomTypeB: "Cycle duration (ms)",
    technicalDrawing: "Technical Drawing",
  },
  bottle: {
    massInfos: "Mass distribution",
    createdOn: "Created on",
    modifiedOn: "Modified on",
    name: "Name",
    owner: "Owner",
    shapeNumber: "Shape number",
    format: "Format (cL)",
    project: "Project",
    designAgency: "Design Agency",
    halfLength: "Half length (mm)",
    isTheoretical: "Tick the box if unknown",
    height: "Height (mm)",
    equivalentDiameter: "Equivalent diameter (mm)",
    bottomTypeShape: "Bottom type shape",
    bottomArrow: "Bottom Arrow (mm)",
    sectionType: "Section type",
    ovenTempTarget: "Oven temperature target (°C)",
    p1Target: "P1 target",
    p2Target: "P2 target",
    technicalDrawing: "Technical Drawing",
    brimfullVolume: "Brimfull volume (cl)",
    wettedSurface: "Wetted surface (cm2)",
  },
  process: {
    additionalFiles: "Additional files",
    massInfos: "Mass distribution",
    createdOn: "Created on",
    modifiedOn: "Modified on",
    name: "Name",
    owner: "Owner",
    operator: "Operator",
    machineName: "Machine name",
    ovenTempFinal: "Oven temperature final (°C)",
    p1Final: "P1 final (bar)",
    p2Final: "P2 final (bar)",
    bottleThickness: "Bottle thickness (µm)",
    cycleDuration: "Cycle duration (ms)",
    comments: "Comments",
    blowingMark: "Blowing Mark",
    preform: "Preform",
    bottle: "Bottle",
    bambouMarguerite: "Bambou/Marguerite",
    isThreeD: "3D",
    radialSR: "Radial SR",
    longiSR: "Longi SR",
  },
};

interface InfosNamesPathInterface {
  [key: string]: string;
}
export const InfosNamesPath: InfosNamesPathInterface = {
  Project: "/project/infos",
  Preform: "/preform/infos",
  Bottle: "/bottle/infos",
};
