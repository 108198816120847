import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, Divider } from "@mui/material";
import { Magnify } from "mdi-material-ui";
// &---------------------------------<< Component >>----*

interface FilterProps {
  children: React.ReactNode;
  onApplyFilte?: React.MouseEventHandler<HTMLButtonElement>;
  onReset?: React.MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  isFilterEmpty?: boolean;
}
/**
 * JSX.Element element represent a filter component for research that host forms as children
 * @prop {React.ReactNode} children - Tag's children that must be forms wrapped with MUI Box
 * @prop {React.MouseEventHandler<HTMLButtonElement>} [onApplyFilte] - onClick for the Apply filter button
 * @prop {React.MouseEventHandler<HTMLButtonElement>} [onReset] - onClick for the reset button
 * @prop {boolean>} [isLoading] - change the state of applyFilter button to loading if true
 * @prop {boolean>} [isFilterEmpty] - change the state of applyFilter button to loading if true
 * @example <Filter> <Box> <TextField/> </Box> </Filter>
 */

const Filter = (props: FilterProps) => {
  const {
    children,
    onApplyFilte,
    onReset,
    isLoading = false,
    isFilterEmpty = true,
  } = props;
  return (
    <Card>
      <Box display="flex" flexDirection="column" gap={2} m={2}>
        <Box display="grid" gridTemplateColumns="1fr auto" gap={2}>
          <LoadingButton
            disableElevation
            variant="contained"
            size="large"
            endIcon={<Magnify />}
            onClick={onApplyFilte}
            loading={isLoading}
          >
            Apply filter
          </LoadingButton>
          <Button
            variant="outlined"
            size="small"
            onClick={onReset}
            color="info"
            disabled={isFilterEmpty}
          >
            Reset
          </Button>
        </Box>

        <Divider />
        <Box height="calc(100vh - 206px)" sx={{ overflowY: "auto" }}>
          {children}
        </Box>
      </Box>
    </Card>
  );
};

export default Filter;
