import appRouteConstants from "shared/constants/appRoutes";

// &------------------------------<< MUI component >>----*
import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { Theme, CSSObject, styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import PreformIcon from "shared/theme/PreformIcon";
// &---------------------------------------<< icon >>----*
import {
  BottleSodaClassic,
  AccountGroup,
  RobotIndustrial,
  PowerStandby,
} from "mdi-material-ui";
import { AccessContext } from "RootApp";
import { dispatchFetch } from "shared/components/fetchers";

// &-------------------------------<< Menu Objects >>----*
const OBJECTS = [
  {
    icon: <AccountGroup />,
    name: appRouteConstants.PROJECT.name,
    path: appRouteConstants.PROJECT.path,
  },
  {
    icon: (
      <PreformIcon
        sx={{ width: "24px", height: "24px" }}
        inheritViewBox={true}
      />
    ),
    name: appRouteConstants.PREFORM.name,
    path: appRouteConstants.PREFORM.path,
  },
  {
    icon: <BottleSodaClassic />,
    name: appRouteConstants.BOTTLE.name,
    path: appRouteConstants.BOTTLE.path,
  },
  {
    icon: <RobotIndustrial />,
    name: appRouteConstants.PROCESS.name,
    path: appRouteConstants.PROCESS.path,
  },
];
const OBJECTS_NAMES = OBJECTS.map((obj) => obj.name.toLowerCase());

// &----------------------<< Drawer CSS Animation >>----*
const drawerWidthMd = 220;
const drawerWidthSm = 220;

const openedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: "#39b1e3",
  borderColor: "#fff",
  width: drawerWidthMd,
  [theme.breakpoints.down("md")]: {
    width: drawerWidthSm,
  },
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  backgroundColor: "#39b1e3",
  borderColor: "#fff",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidthMd,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// &---------------------------------<< Component >>----*
interface DrawerProps {
  userId: string;
  isOpen: boolean;
}

/**
 * JSX.Element element represents the left drawer of the system
 * @prop {string} userId - The user ID visible in the logout button
 * @prop {boolean} isOpen - Drawer state, in close state only icons visible
 * @example <ObjectsDrawer userId="DPS2022" isOpen={true}/>
 */

const ObjectsDrawer = (props: DrawerProps) => {
  const { userId, isOpen } = props;
  //* Context
  const { toggleAccess } = useContext(AccessContext);
  //* States
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [logoutOpen, setLogoutOpen] = useState(false);
  const { palette } = useTheme();

  //* Mounted
  const location = useLocation();
  useEffect(() => {
    const index = OBJECTS_NAMES.indexOf(
      `${location.pathname.split("/")[1]} database`
    );
    setSelectedIndex(index);
  }, [location.pathname]);

  //* Handler
  const handleLogoutDialogClose = () => {
    setLogoutOpen(false);
  };
  const handleLogoutDialogOpen = () => {
    setLogoutOpen(true);
  };
  const navigate = useNavigate();
  const handleObjectsClick = useCallback(
    (path: string) => {
      // ~ The index is update in the useEffect to manage page refresh
      navigate(path);
    },
    [navigate]
  );
  const handleLogout = () => {
    dispatchFetch("GETRes")("/logout/").then((response: any) => {
      if (response.status === 200) {
        toggleAccess();
      }
    });
  };

  //* Render
  return (
    <Drawer variant="permanent" open={isOpen}>
      <Toolbar />
      <Box display="grid" gridTemplateRows="auto 1fr" height="100%">
        <Box>
          <List>
            {OBJECTS.map((object, i) => (
              <ListItemButton
                sx={{ height: "63px", borderColor: "#fff", color: "white" }}
                divider={true}
                selected={selectedIndex === i}
                key={i}
                onClick={() => handleObjectsClick(object.path)}
              >
                <ListItemIcon
                  sx={{
                    color: selectedIndex === i ? palette.primary.main : "#fff",
                  }}
                >
                  {object.icon}
                </ListItemIcon>
                <ListItemText primary={object.name} />
              </ListItemButton>
            ))}
          </List>
        </Box>
        <Box alignSelf="end">
          <Typography align="left" padding={1} color="white">
            v{process.env.REACT_APP_VERSION}
          </Typography>
          <Divider color="#fff" />
          <ListItemButton
            onClick={handleLogoutDialogOpen}
            divider={true}
            key={"logout"}
          >
            <ListItemIcon>
              <PowerStandby
                color="error"
                sx={{
                  strokeWidth: "1",
                  stroke: palette.error.main,
                  transform: "scale(1.5)",
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Logout"
              primaryTypographyProps={{ color: "error", fontWeight: "bold" }}
              secondary={userId}
              secondaryTypographyProps={{ color: "white" }}
            />
          </ListItemButton>
        </Box>
      </Box>
      <Dialog
        open={logoutOpen}
        onClose={handleLogoutDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle color="error">Logout</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleLogout}>
            Yes
          </Button>
          <Button onClick={handleLogoutDialogClose}>No</Button>
        </DialogActions>
      </Dialog>
    </Drawer>
  );
};

export default ObjectsDrawer;
