import { useNavigate, useParams } from "react-router-dom";
import { DataLayout, TwoColumnsLayout } from "shared/components";
import { InfoTable } from "shared/components";
import { useEffect, useState } from "react";
import { dispatchFetch } from "shared/components/fetchers";
import {
  FindBestPrefomFormStep1,
  FindBestPrefomFormStep2,
  FindBestPrefomFormSubStep,
} from "screens/App/views/Bottle/components/FindBestPrefomForm";
import { FormikHelpers, useFormik } from "formik";
import { useNavigateSubRoute } from "shared/hooks";
import {
  NamedObject,
  generateURL,
  getErrorMessage,
  infoDataTreatment,
  objectType,
} from "shared/Utils/utils";
import {
  CloseCircleOutline,
  DatabaseSearchOutline,
  Magnify,
} from "mdi-material-ui";
import { useSnackbar } from "notistack";
import {
  findBestPreformFilterStep1Config,
  findBestPreformFilterStep2Config,
  findBestPreformFilterSubStepConfig,
} from "shared/constants/filtersConfig";
import { Box, Step, StepLabel, Stepper, StepperProps } from "@mui/material";
import { contentType } from "shared/constants/constants";
import { LoadingButton } from "@mui/lab";
import { ChevronDoubleDown } from "mdi-material-ui";

//*CONST
const stepsLabel = ["step01", "step02"];

const MainInfos = () => {
  const [infos, setInfos] = useState<objectType>();
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [disableApply, setDisableApply] = useState<boolean>(true);
  const [activeStep, setActiveStep] = useState(0);
  const [validLoading, setValidLoading] = useState<boolean>(false);
  const [applyLoading, setApplyLoading] = useState<boolean>(false);
  const [step2Data, setStep2Data] = useState({});
  const [subStepData, setSubStepData] = useState({ preforms: [] });
  const param = useParams();
  const navigate = useNavigate();
  const navigateToSubRoute = useNavigateSubRoute();
  const { enqueueSnackbar } = useSnackbar();

  //*Get init
  useEffect(() => {
    dispatchFetch("GET")(`/bottles/${param.id}`)
      .then((data: any) => {
        setIsEditable(data.isEditable);
        delete data.isEditable;
        let factData = {
          massInfos: {
            areaDistribution: { ...data.areaDistribution },
            massRepartitionTarget: { ...data.massRepartitionTarget },
          },
          ...data,
          designAgency: data.designAgency?.name,
          sectionType: data.sectionType.name,
          bottomTypeShape: data.bottomTypeShape?.name,
          owner: data.owner?.username,
        };
        let { areaDistribution, massRepartitionTarget, ...rest } = factData;
        setInfos(infoDataTreatment(rest, "bottle"));
      })
      .catch(function (error: any) {});
  }, [param.id]);

  useEffect(() => {
    handleApplyBtn({ neck: "", resin: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //*Function
  const getvalidationDisableState = () => {
    const { preforms } = formiSubStep.values;
    const { projects } = formiStep2.values;
    switch (activeStep) {
      case 0:
        return preforms.length === 0 || !disableApply;
      case 1:
        return projects.length === 0;
      default:
        return false;
    }
  };

  //*Handler
  const handleEdit = () => {
    navigateToSubRoute(`edit/${param.id}`, 1);
  };

  const handleFindBtn = async (values: any) => {
    const { bottleFormatMax, bottleFormatMin, projects } = values;

    let FBPStep02Data = {};
    const url = `/preforms/optirange/`;
    const optirangeData = {
      bottleFormat: { min: bottleFormatMin, max: bottleFormatMax },
      projects,
    };

    setValidLoading(true);
    try {
      await dispatchFetch("POST")(
        url,
        JSON.stringify(optirangeData),
        contentType.json
      )
        .then((data: any) => {
          const { optirange } = data;

          FBPStep02Data = {
            preforms: formiSubStep.values.preforms,
            optirange,
          };
          localStorage.setItem("FBPStep02Data", JSON.stringify(FBPStep02Data));
          navigateToSubRoute(`${param.id}/best`);
        })
        .catch((error: any) => {
          let errorMessage = getErrorMessage(error);
          enqueueSnackbar(errorMessage, {
            variant: "error",
          });
        });
    } finally {
      setValidLoading(false);
    }
  };

  const handleApplyBtn = async (values: any) => {
    const preformValues = { ...values, neck: values.neck, resin: values.resin };

    let urlPreforms =
      generateURL(preformValues, "preforms") + "&pageSize=10000&order_by=name";

    setApplyLoading(true);
    let preformsData = { preforms: [] };
    try {
      await dispatchFetch("GET")(urlPreforms).then((preforms: any) => {
        preformsData = { preforms: preforms.results };
      });
    } finally {
      setSubStepData(preformsData);
      //~set Default values
      formiSubStep.setFieldValue(
        "preforms",
        preformsData.preforms.map((pref: NamedObject) => pref.id)
      );
      setApplyLoading(false);
      setDisableApply(true);

      if (preformsData.preforms.length === 0) {
        const errorMessage =
          "message : No preforms matches the filters parameters";
        enqueueSnackbar(errorMessage, {
          variant: "error",
        });
      }
    }
  };

  const handleNextBtn = async (values: any) => {
    const projectValues = {
      preform__mass__gte: values.mass__gte ? values.mass__gte : "", //~ToBe ignored fir querParamUrl
      preform__mass__lte: values.mass__lte ? values.mass__lte : "", //~ToBe ignored fir querParamUrl
      preform__resin: values.resin,
    };

    let urlProjects =
      generateURL(projectValues, "projects") + "&pageSize=10000&order_by=name";
    setValidLoading(true);

    let nextStepData = { projects: [] };
    try {
      await dispatchFetch("GET")(urlProjects).then((projects: any) => {
        nextStepData = { projects: projects.results };
      });
    } finally {
      setStep2Data(nextStepData);
      setValidLoading(false);
      if (nextStepData.projects.length === 0) {
        const errorMessage =
          "message : No projects matches the filters parameters";
        enqueueSnackbar(errorMessage, {
          variant: "error",
        });
        return;
      }

      //~set Default values
      const bottleFormat = infos && infos["Format (cL)"];
      formiStep2.setFieldValue("bottleFormatMin", bottleFormat - 25);
      formiStep2.setFieldValue("bottleFormatMax", bottleFormat + 25);
      formiStep2.setFieldValue(
        "projects",
        nextStepData.projects.map((prj: NamedObject) => prj.id)
      );

      setActiveStep((currentVal) => currentVal + 1);
    }
  };

  const handleDelete = async () => {
    await dispatchFetch("DELETE")(`/bottles/${param.id}`)
      .then((data: any) => {
        if (data.status >= 200 && data.status < 300) {
          enqueueSnackbar(`The bottle deleted with success`, {
            variant: "success",
          });
          navigate("/bottle/research");
        }
      })
      .catch((error: any) => {
        let errorMessage = getErrorMessage(error);
        enqueueSnackbar(errorMessage, {
          variant: "error",
        });
      });
  };

  const handleCopy = () => {
    navigateToSubRoute(`add#${param.id}`, 1);
  };

  const handleMainBtn = () => {
    activeStep === stepsLabel.length - 1
      ? formiStep2.handleSubmit()
      : formiStep1.handleSubmit();
  };

  const handleSecondBtn = () => {
    activeStep > 0 && setActiveStep((currentVal) => currentVal - 1);
  };

  //*Formik
  const formiStep1 = useFormik({
    enableReinitialize: true,
    initialValues: { mass__gte: "", mass__lte: "", neck: [], resin: [] },
    onSubmit: handleNextBtn,
  });
  const formiSubStep = useFormik({
    enableReinitialize: true,
    initialValues: { preforms: [] },
    onSubmit: handleNextBtn,
  });
  const formiStep2 = useFormik({
    enableReinitialize: true,
    initialValues: {
      preforms: [],
      projects: [],
    },
    onSubmit: handleFindBtn,
  });

  //*Watcher
  useEffect(() => {
    setDisableApply(false);
  }, [formiStep1.values]);
  //*Render
  return (
    <TwoColumnsLayout
      hideCancelBtn
      title={`Bottle : ${infos ? infos.Name : ""}`}
      btnCollapseTxt="Find the best preform"
      btnCollapseIcon={{
        iconCollapseRight: <CloseCircleOutline />,
        iconCollapseLeft: <Magnify />,
      }}
      initSplit={false}
      hasRights={isEditable}
      disableValidation={!isEditable}
      onEdit={handleEdit}
      onCopy={handleCopy}
      onDelete={handleDelete}
      deleteMsg={
        <div>
          <span>{`⚠️ All processes associated to this bottle will be deleted`}</span>
          <br />
          <span>Are you sure to continue ?</span>
        </div>
      }
      variant="delete"
      leftArea={<InfoTable infos={infos} />}
      rightArea={
        <DataLayout
          hideCancelBtn
          variant="edit"
          title={
            activeStep === 0
              ? "Preform characteristics"
              : "Bottle sizes & projects to consider in the process windows definition"
          }
          validationBtnTxt={
            activeStep === stepsLabel.length - 1 ? "Find" : "Next"
          }
          secondBtnTxt={activeStep > 0 ? "Back" : undefined}
          validationBtnIcon={
            activeStep === stepsLabel.length - 1 ? (
              <DatabaseSearchOutline />
            ) : null
          }
          onValidation={handleMainBtn}
          disableValidation={getvalidationDisableState()}
          onEnter={handleMainBtn}
          onSecondBtn={handleSecondBtn}
          isLoading={validLoading}
        >
          <Box display="grid" justifyItems="center" p={4}>
            <FilterStepper
              steps={stepsLabel}
              currentStep={activeStep}
              sx={{ width: "80%" }}
            />
          </Box>
          {activeStep === 0 ? (
            <Box display="flex" flexDirection="column">
              <FindBestPrefomFormStep1
                formConfig={findBestPreformFilterStep1Config}
                formik={formiStep1}
              />
              <LoadingButton
                sx={{ alignSelf: "center" }}
                disableElevation
                variant="contained"
                startIcon={<ChevronDoubleDown />}
                endIcon={<ChevronDoubleDown />}
                onClick={() => handleApplyBtn(formiStep1.values)}
                loading={applyLoading}
                disabled={disableApply}
              >
                Apply
              </LoadingButton>
              <FindBestPrefomFormSubStep
                formConfig={findBestPreformFilterSubStepConfig}
                formik={formiSubStep}
                data={subStepData}
              />
            </Box>
          ) : (
            <FindBestPrefomFormStep2
              formConfig={findBestPreformFilterStep2Config}
              formik={formiStep2}
              data={step2Data}
            />
          )}
        </DataLayout>
      }
    />
  );
};

export default MainInfos;

//&-------------FormStepper--------------------///

interface FilterStepperProps extends StepperProps {
  currentStep: number | undefined;
  steps: Array<string>;
}

const FilterStepper = (props: FilterStepperProps) => {
  const { steps, currentStep, ...stepperProps } = props;
  //*Rendrer
  return (
    <Stepper {...stepperProps} activeStep={currentStep}>
      {steps.map((label, index) => {
        const stepProps: { completed?: boolean } = {};
        const labelProps: {
          optional?: React.ReactNode;
        } = {};

        return (
          <Step key={label} {...stepProps}>
            <StepLabel {...labelProps}></StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};
