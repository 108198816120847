import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { FileUpload } from "mdi-material-ui";

type FileFieldProps = {
  id: string;
  name: string;
  label: string;
  initialValue?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement> | undefined) => void;
  onBlur?: any;
  error?: boolean;
  helperText?: any;
  accept?: string;
  required?: boolean;
};

/**
 * JSX.Element element represents a form field to upload documents
 * @prop {string} id - field ID
 * @prop {string} name - field name
 * @prop {string} label - field label
 * @prop {string} [initialValue] - intial value of the field
 * @prop {any} [onBlur] - onBlur action
 * @prop {boolean} [error] - error is true if the input not valid
 * @prop {any} [helperText] - helper text for the field
 * @prop {string} [label] - field label
 * @prop {Callback} accept - accept
 * @prop {boolean} required - if the field is required or not
 * @example <FileInput id={key} name={key} label={label} required={true} onChange={(e) => {}} onDelete={() => {}} onBlur={formik.handleBlur} error={formik.touched[key]} helperText={"explain"} {...parameters} />
 */

export const FileInput = ({
  id,
  name,
  label,
  initialValue,
  onChange,
  onBlur,
  error,
  helperText,
  accept,
  required,
}: FileFieldProps) => {
  const [hasFile, setHasFile] = useState(Boolean(initialValue));

  const { action } = useTheme().palette;

  //*Watcher
  useEffect(() => {
    setHasFile(Boolean(initialValue));
  }, [initialValue]);

  return (
    <FormControl id={id} required={required} fullWidth error={error}>
      <InputLabel htmlFor="fileInput" shrink disableAnimation>
        {label}
      </InputLabel>
      {initialValue && hasFile ? (
        <Box
          display="grid"
          gridTemplateColumns="auto 24px"
          alignItems="center"
          border={`1px solid ${action.disabled}`}
          borderRadius={1}
        >
          <Typography variant="body2" padding={2}>
            File name : {initialValue?.split("/").reverse()[0]}
          </Typography>
        </Box>
      ) : (
        <OutlinedInput
          fullWidth
          id="fileInput"
          name={name}
          onBlur={onBlur}
          label={label}
          notched
          aria-hidden
          startAdornment={
            <InputAdornment position="start">
              <IconButton color="primary" component="label" size="small">
                <FileUpload />
                <input
                  hidden
                  name={name}
                  type="file"
                  accept={accept}
                  onChange={onChange}
                />
              </IconButton>
              <Typography variant="body2" color={"default"}>
                No selected file
              </Typography>
            </InputAdornment>
          }
        />
      )}
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default FileInput;
