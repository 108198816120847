import { DataLayout, DataLoading } from "shared/components";
import { useNavigate, useParams } from "react-router-dom";
import { getFormConfig } from "shared/constants/formConfigs";
import PreformForm from "../../../components/PreformForm";
import { FormikHelpers, useFormik } from "formik";
import { dispatchFetch } from "shared/components/fetchers";
import {
  createFormData,
  getErrorMessage,
  updateObjectValue,
} from "shared/Utils/utils";
import { contentType } from "shared/constants/constants";
import { preformValidationSchema } from "shared/constants/validationSchemas";
import { useNavigateSubRoute } from "shared/hooks";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { ContentSaveSettingsOutline } from "mdi-material-ui";
import LoadingSpinner from "shared/components/LoadingSpinner";

const Edit = () => {
  const param = useParams();
  const navigate = useNavigate();
  const navigateSubRoute = useNavigateSubRoute();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [preformFormConfig, setPreformFormConfig] = useState();

  //*Watcher
  useEffect(() => {
    dispatchFetch("GET")(`/preforms/${param.id}`).then((data: any) => {
      updateObjectValue(data, null, "");
      setData(data);
    });
  }, [param.id]);

  useEffect(() => {
    if (data) {
      getFormConfig().then((config: any) => {
        config.preformForm.technicalDrawing.parameters.initialValue =
          data?.technicalDrawing;
        setPreformFormConfig(config.preformForm);
      });
    }
  }, [data]);
  //*Handler
  const handleEditObject = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    let formData = createFormData(values, preformFormConfig);
    let url = `/preforms/${param.id}`;

    setLoading(true);
    try {
      await dispatchFetch("PUT")(url, formData, contentType.formData)
        .then((data: any) => {
          if (data) {
            enqueueSnackbar(`The preform was edited with success`, {
              variant: "success",
            });
            navigateSubRoute(`infos/${data.id}`, 1);
          }
        })
        .catch((error: any) => {
          let errorMessage = getErrorMessage(error);
          enqueueSnackbar(errorMessage, {
            variant: "error",
          });
        });
    } finally {
      setLoading(false);
    }
  };
  //*Formik
  const formi = useFormik({
    enableReinitialize: true,
    initialValues: data
      ? {
          ...data,
          neck: data.neck.id,
          bottomTypeShape: data.bottomTypeShape?.id,
          supplier: data.supplier.id,
          resin: data.resin.id,
        }
      : {},
    validationSchema: preformValidationSchema,
    onSubmit: handleEditObject,
  });
  //*Render

  return (
    <DataLayout
      onValidation={() => {
        formi.handleSubmit();
      }}
      onEnter={formi.handleSubmit}
      validationBtnIcon={<ContentSaveSettingsOutline />}
      disableValidation={!formi.isValid || !formi.dirty}
      onCancel={() => navigate(-1)}
      title={`Edit preform`}
      variant="edit"
      isLoading={loading}
    >
      <DataLoading data={data}>
        {preformFormConfig ? (
          <PreformForm
            formConfig={preformFormConfig}
            formik={formi}
            validatioSchema={preformValidationSchema}
          />
        ) : (
          <LoadingSpinner />
        )}
      </DataLoading>
    </DataLayout>
  );
};

export default Edit;
